import { useContext } from "react";
import { Context } from "../Context";
import Loading from "../components/Loading";

const Terms = () => {
    const { loading } = useContext(Context);

    if (loading) return <Loading />;

    return (
        <div className='page-container' style={{ padding: '0px' }} data-testid='general-1'>
            <div className='page-title' style={{ width: '80%' }}>
                <h1>Terms and Conditions & GDPR</h1>
                <p>Welcome to our web application. By accessing and using this web application, you agree to comply with and be bound by the following terms and conditions of use. Please read these terms carefully before using our web application. These terms and conditions, together with our privacy policy, govern our relationship with you in relation to this web application. If you disagree with any part of these terms and conditions, please do not use our web application. Please also review our <a href="/privacy">Privacy Policy</a> to understand how we collect, use, and protect your information. <strong>
                    You will find our GDPR statement below  </strong></p>
            </div>
            <div className='terms-container'>
                <h5>1. User Responsibilities</h5>
                <p>When using our web application, you agree to:</p>
                <ul>
                    <li>Provide accurate and up-to-date information</li>
                    <li>Keep your login credentials secure and confidential</li>
                    <li>Comply with all applicable laws and regulations</li>
                    <li>Use the web application for lawful purposes only</li>
                </ul>

                <h5>2. Prohibited Activities</h5>
                <p>When using our web application, you must not:</p>
                <ul>
                    <li>Engage in any illegal, fraudulent, or unauthorized activities</li>
                    <li>Interfere with or disrupt the functionality of the web application</li>
                    <li>Attempt to gain unauthorized access to any part of the web application</li>
                    <li>Upload or transmit any malicious code or viruses</li>
                    <li>Violate the intellectual property rights of others</li>
                </ul>

                <h5>3. Intellectual Property</h5>
                <p>All content and materials available on our web application, including but not limited to text, graphics, logos, button icons, images, audio clips, and software, are the property of our company and are protected by applicable intellectual property laws.</p>

                <h5>4. Limitation of Liability</h5>
                <p>We shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use or inability to use our web application.</p>

                <h5>5. Modifications</h5>
                <p>We reserve the right to modify or update these terms and conditions at any time without prior notice. It is your responsibility to review these terms periodically for any changes.</p>

                <h5>6. Governing Law</h5>
                <p>These terms and conditions shall be governed by and construed in accordance with the laws of Israel.</p>

                <h5>7. Contact Us</h5>
                <p>If you have any questions or concerns about these terms and conditions, please contact us at info@mdtportaal.nl.</p>

                <h5>GDPR Compliance Statement</h5>
                <p>At MDT Portal (developed by HUMINT Solutions LDT.), we are committed to protecting the privacy and data rights of our users in accordance with the General Data Protection Regulation (GDPR) and other relevant data protection laws.</p>
                <p>We collect and process personal data only for specified, explicit, and legitimate purposes. This data is used to provide and improve our services, personalize user experiences, and communicate important updates and promotions.</p>
                <p>We do not sell, rent, or share your personal information with third parties without your explicit consent, except as required by law or as necessary to fulfill our contractual obligations. Our data processing activities are conducted in a transparent manner, and we take appropriate technical and organizational measures to ensure the security and confidentiality of user data.</p>
                <p>By using our web application, you consent to the collection and processing of your personal data as described in our <a href="/privacy">Privacy Policy</a>. If you have any questions or concerns about your data privacy or our GDPR compliance practices, please don't hesitate to contact us at <a href="mailto:info@mdtportaal.nl">info@mdtportaal.nl</a>.</p>


            </div>
        </div>
    );
};

export default Terms;

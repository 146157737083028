import { useContext } from "react";
import { Context } from "../Context";
import Loading from "../components/Loading";

const PrivacyPolicy = () => {
    const { loading } = useContext(Context);

    if (loading) {
        return <Loading />;
    }
    return (
        <div className='page-container' style={{ padding: '00px' }} data-testid='general-1'>
            <div className='page-title' style={{ width: '80%' }}>
                <h3 >Privacy Policy</h3>
                <h5>Learn how we collect, use, and protect your information</h5>
                <p>This privacy policy outlines how our web application collects, uses, and protects the information provided by users.</p>
                <p>For more information about our data processing practices, as well as our GDPR statement, please review our <a href="/terms">Terms and Conditions</a>.</p>
            </div>
            <div className='privacy-policy-container'>
                <h5>Information Collection</h5>
                <p>We collect basic user information, such as name and email address, when you register or log in to our web application. Additionally, we may collect information automatically through cookies or other tracking technologies. </p>
                <h5>Information Usage</h5>
                <p>
                    The information we collect is used to provide and improve our services, personalize your experience, and communicate with you about updates and promotions. We do not sell, rent, or share your personal information with third parties without your explicit consent, except as required by law.
                </p>
                <h5>Data Sharing</h5>
                <p>
                    We may share your information with third-party service providers for specific purposes, such as payment processing or analytics. These providers are contractually obligated to safeguard your data and are prohibited from using it for any other purpose.
                </p>
                <h5>Cookie Policy</h5>
                <p>
                    Cookies are small text files stored on your device that help us improve your browsing experience. You can disable cookies in your browser settings, but this may affect the functionality of our web application.
                </p>
                <h5>Data Security</h5>
                <p>
                    We take data security seriously and employ industry-standard measures to protect your information from unauthorized access, disclosure, alteration, or destruction. This includes encryption of sensitive data and regular security assessments of our systems.
                </p>
                <h5>Third-Party Services</h5>
                <p>
                    Our web application may use third-party services, such as Google Analytics, to analyze user interactions and improve our services. These services may collect information about your use of our website, but they are governed by their own privacy policies and do not have access to your personal information unless explicitly provided by you.
                </p>
                <h5>Changes to the Privacy Policy</h5>
                <p>
                    We reserve the right to update or modify this privacy policy at any time. We will notify you of any material changes through the web application or via email. By continuing to use our services after the changes are made, you agree to the updated policy.
                </p>
                <h5>Contact Us</h5>
                <p>
                    If you have any questions or concerns about our privacy policy, please <a href="mailto:info@mdtportaal.nl">contact us.</a>
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;

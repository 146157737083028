import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../Context";
import Loading from "../components/Loading";
import { Select, MenuItem } from '@mui/material';
import useInView from "../hooks/useInView";
import ScrollTriggeredLottie from "../components/ScrollTriggeredLottie";
import {
    Atom,
    Check,
    LayoutDashboard, // Admin Panel
    UserPlus, // Registration
    BookOpen, // E-learning
    Users, // Teacher tools
    Palette, // Customization
    Rocket, // Impact
    Building2 // New Building2 icon
} from 'lucide-react';

// images + animations
import arrowAnimation from '../animations/arrowAnimation.json';
import dashboard from '../images/dashboard.png';
import adminpanel from '../images/adminpanel.png';
import onboarding from '../images/onboarding.jpeg';
import attendance from '../images/attendance.png';
import elearning from '../images/elearning.png';
import brand from '../images/brand.png';
import mockup from '../images/hadigitalit-mockup.png';

function LandingPage() {
    const { loading } = useContext(Context);
    const [language, setLanguage] = useState('nl');  // 'en' for English, 'nl' for Dutch
    const navigate = useNavigate();
    const [scroll, setScroll] = useState(0);
    const [isMobile, setIsMobile] = useState(false);    // check if the device is mobile
    const [isTablet, setIsTablet] = useState(false);    // check if the device is tablet

    // page scroll
    useEffect(() => {
        const onScroll = () => {
            setScroll(window.scrollY);
        }
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    // Scroll animation
    useEffect(() => {
        const elements = document.querySelectorAll('.animated');
        elements.forEach((element) => {
            const rect = element.getBoundingClientRect();
            const isShown = rect.top <= window.innerHeight && rect.bottom >= 0;
            if (isShown) {
                element.classList.remove('hidden');
                element.classList.add('fadeInUp');
            } else {
                element.classList.remove('fadeInUp');
                element.classList.add('hidden');
            }
        });
    }, [scroll]);

    // check mobile
    useEffect(() => {
        const checkMobile = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            if (window.innerWidth <= 1024) {
                setIsTablet(true);
            } else {
                setIsTablet(false);
            }
        }
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => {
            window.removeEventListener('resize', checkMobile);
        }
    }, []);

    // check if the element is in view
    const [animationRef, isAnimationInView] = useInView({
        threshold: 0.5, // Trigger when 50% of the element is visible
    });

    // translate content based on language
    const translations = {
        en: {
            title: "Digitise Your MDT Program",
            description: `Automate registration, enhance engagement, offer interactive content, and impress your clients with the only software designed specifically for MDT program management`,
            stats: {
                stats: [
                    // { number: "3", label: "National MDT Programs" },
                    { number: "5000+", label: "Students" },
                    { number: "100+", label: "Teachers" },
                    { number: "30+", label: "Programs Managers" },
                    { number: "95%", label: "Satisfaction rate" }
                ]
            },
            div1: {
                title: "Manage Your MDT Program From One Central Place",
                subTitle: "Admin Panel",
                description: `Take full control with MDT Portal's powerful Admin Panel—your central hub for effortless program management.`,
                bulletPoints: [
                    "Add and manage employees, teachers, and participants",
                    "Upload videos, quizzes, and program materials",
                    "Create and manage groups schedules",
                    "Track real-time analytics on engagement, attendance, and progress",
                    "Export participant data with a click for seamless reporting"
                ],
                image: adminpanel
            },
            div2: {
                title: "Automate Participants & Teachers registration",
                subTitle: "Automated Registration",
                description: `No more sending links and emails one by one. Participants and teachers can complete onboarding in minutes with your own customizable onboarding process.`,
                bulletPoints: [
                    "Program organizers collect student details effortlessly.",
                    "Teachers can upload and share student lists in seconds."
                ],
                image: onboarding
            },
            div3: {
                title: "Enhance engagement with an E-learning Platform",
                subTitle: "E-learning Platform",
                description: `Offer your participants a digital experience with our e-learning platform.`,
                bulletPoints: [
                    "Videos",
                    "Quizzes",
                    "Questionnaires"
                ],
                image: elearning
            },
            div4: {
                title: "Tools built for teachers",
                subTitle: "Teacher Dashboard",
                description: `Teachers get access to their group dashboard and tools so they can:`,
                bulletPoints: [
                    "Mark attendance digitally—no more paper lists.",
                    "Monitor student progress—see who's underperforming.",
                    "Print or email attendance records—all in just a few clicks."
                ],
                image: attendance
            },
            div5: {
                title: "Match your brand",
                subTitle: "Customizable",
                description: `Customize your platform with your logo and brand colors to create a cohesive experience for participants and staff.`,
                image: brand
            },
            div6: {
                title: "Give Your Program a Competitive Edge",
                subTitle: "Impress your clients",
                description: `With MDT Portal, your program stands out. Offer a seamless digital experience instead of outdated spreadsheets and emails.
                Show real-time data and engagement tracking to stakeholders.
                Give confidence in your ability to deliver an effective, well-managed program.`,
            },
            footer: {
                company: "Company",
                login: "Login",
                contact: "Book a demo"
            },
            testimonial: {
                quote: "The portal is a real game-changer! The intuitive design, smooth navigation, and efficient functionality make it extremely user-friendly. I'm very happy with it. It saves time and makes monitoring participating youth easier.",
                author: "Florien Sjoerdsma",
                position: "Program Manager TINO112MDT and TINO PolitieMDT"
            },
            company: {
                title: "Built and designed by pros",
                subTitle: "About Us",
                description: "MDT Portal software was developed by Hadigitalit specifically for the Dutch market to help MDT program managers digitize their work. Hadigitalit is an international web development company specializing in creating modern websites, web applications, and native applications that help businesses thrive in the digital age.",
                image: mockup
            },
            menu: {
                features: "Features",
                about: "About",
                contact: "Contact"
            }
        },
        nl: {
            title: "Digitaliseer en automatiseer jouw MDT-programma",
            description: "Bespaar tijd, verhoog betrokkenheid en bied een professionele digitale ervaring met software speciaal ontworpen voor MDT-programmabeheer",
            stats: {
                stats: [
                    // { number: "3", label: "Nationale MDT Programma's" },
                    { number: "5000+", label: "MDT-jongeren gebruiken het" },
                    { number: "100+", label: "Begeleiders & docenten beheren eenvoudig hun groepen" },
                    { number: "30+", label: "Programma-managers besparen tijd en werken efficiënter" },
                    { number: "95%", label: "Tevredenheid onder gebruikers" }
                ]
            },
            div1: {
                title: "Beheer jouw MDT-programma vanuit één centrale plek",
                subTitle: "Beheerpaneel",
                description: "Neem de volledige controle over je programma met ons krachtige beheerpaneel:",
                bulletPoints: [
                    "Voeg medewerkers, docenten en deelnemers eenvoudig toe en beheer ze",
                    "Upload video's, quizzen en programmamateriaal",
                    "Maak en beheer groepsroosters in een paar klikken",
                    "Monitor realtime statistieken over aanwezigheid en voortgang",
                    "Exporteer deelnemersgegevens snel en eenvoudig voor rapportages"
                ],
                image: adminpanel
            },
            div2: {
                title: "Automatiseer de registratie van deelnemers en docenten",
                subTitle: "Geautomatiseerde registratie",
                description: "Geen losse e-mails of handmatige inschrijvingen meer. Maak onboarding eenvoudig en efficiënt:",
                bulletPoints: [
                    "Programma-organisatoren verzamelen moeiteloos alle benodigde gegevens",
                    "Docenten kunnen deelnemerslijsten binnen seconden uploaden en delen",
                    "Deelnemers melden zich eenvoudig aan via een gebruiksvriendelijke workflow"
                ],
                image: onboarding
            },
            div3: {
                title: "Maak leren interactief en boeiend",
                subTitle: "E-learning Platform",
                description: "Bied deelnemers een digitale leerervaring binnen MDT Portaal:",
                bulletPoints: [
                    "Video's - voeg lesmateriaal toe voor online leren",
                    "Quizzen - test kennis en houd deelnemers betrokken",
                    "Vragenlijsten - verzamel feedback en inzichten"
                ],
                image: elearning
            },
            div4: {
                title: "Tools gebouwd voor docenten",
                subTitle: "Docenten Dashboard",
                description: "Alles wat docenten nodig hebben op één plek:",
                bulletPoints: [
                    "Digitaal aanwezigheid registreren - geen papieren lijsten meer",
                    "Voortgang van deelnemers volgen - houd overzicht per groep",
                    "Aanwezigheidsrapporten genereren - print of mail met één klik"
                ],
                image: attendance
            },
            div5: {
                title: "Geef jouw MDT-programma een professionele uitstraling",
                subTitle: "Aanpasbaar aan jouw merk",
                description: "Pas jouw platform aan met jouw logo en merkkleuren voor een samenhangende ervaring voor deelnemers en medewerkers.",
                bulletPoints: [
                    "Personaliseer met jouw logo en merkkleuren",
                    "Zorg voor een herkenbare en vertrouwde digitale omgeving",
                    "Maak indruk op stakeholders met een modern en goed beheerd platform"
                ],
                image: brand
            },
            div6: {
                title: "Breng jouw MDT-programma naar een hoger niveau",
                subTitle: "Maak meer impact met MDT Portaal",
                description: "Vervang Excel, losse e-mails en verouderde systemen door een modern platform dat jouw programma structureert en schaalbaar maakt. Nieuwsgierig? Plan een demo en ontdek hoe MDT Portaal jouw werk vergemakkelijkt.",
            },
            footer: {
                company: "Bedrijf",
                login: "Inloggen",
                contact: "Vraag een demo aan"
            },
            testimonial: {
                quote: "De portal is een echte game-changer! Het intuïtieve ontwerp, de soepele navigatie en de efficiënte functionaliteit maken het ontzettend gebruiksvriendelijk. Ik ben er erg blij mee. Het bespaart tijd en maakt het monitoren van deelnemende jongeren makkelijker.",
                author: "Florien Sjoerdsma",
                position: "Programmaleider TINO112MDT en TINO PolitieMDT"
            },
            company: {
                title: "Gebouwd en ontworpen door professionals",
                subTitle: "Over Ons",
                description: "MDT Portaal software is ontwikkeld door Hadigitalit specifiek voor de Nederlandse markt om MDT-programmamanagers te helpen hun werk te digitaliseren. Hadigitalit is een internationaal webontwikkelingsbedrijf gespecialiseerd in het creëren van moderne websites, webapplicaties en native applicaties die bedrijven helpen succesvol te zijn in het digitale tijdperk.",
                image: mockup
            },
            menu: {
                features: "Functies",
                about: "Over ons",
                contact: "Contact"
            }
        },
    };

    if (loading) return <Loading />

    return (
        <div className="landingPage">
            <div className="landing-header">
                <div className="landing-logo">
                    <a href="/landing">
                        <Atom size={isMobile ? 25 : 30} />
                        <h1>MDT {language === 'en' ? 'Portal' : 'Portaal'}</h1>
                    </a>
                </div>
                {!isMobile && (
                    <div className="landing-nav">
                        <a href="#features">{translations[language].menu.features}</a>
                        <a href="#about">{translations[language].menu.about}</a>
                        <a href="#contact">{translations[language].menu.contact}</a>
                    </div>
                )}

                <div>
                    <Select
                        className="landing-language-select"
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        sx={{
                            fontSize: '0.95rem !important',
                            "&:hover .MuiSvgIcon-root": {
                                color: 'var(--global-black)',
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: 'none'
                            }

                        }}
                    >
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="nl">Dutch</MenuItem>
                    </Select>
                    <button onClick={(e) => (navigate("/login"))}
                        className="loginLPButton">{translations[language].footer.login}
                    </button>
                </div>
            </div>

            <div className="landing-main-container">
                <div className="hero-section">
                    <div className="hero-left-container">
                        <h1 className="animated hidden">{translations[language].title}</h1>
                        <h2>{translations[language].subTitle}</h2>
                        <p>{translations[language].description}</p>
                        <button className="yellow-btn">
                            <a style={{ color: 'var(--global-black)' }} href={`mailto:info@mdtportaal.nl`}>
                                {translations[language].footer.contact}
                            </a>
                        </button>
                    </div>
                    <div className="hero-right-container">
                        <img className="animated hidden" src={dashboard} alt="mdt-homepage" />
                    </div>
                    <div className="stats-section">
                        <div className="stats-container">
                            {translations[language].stats.stats.map((stat, index) => (
                                <div key={index} className="stat-item animated hidden">
                                    <h3>{stat.number}</h3>
                                    <p>{stat.label}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* First arrow animation - starts on the right */}
                {!isMobile && (
                    <div className="feature-section-title right">
                        <ScrollTriggeredLottie animationData={arrowAnimation}
                            style={{
                                width: '24%',
                                marginTop: '90px'
                            }} />
                    </div>
                )}

                {[translations[language].div1, translations[language].div2, translations[language].div3, translations[language].div4, translations[language].div5].map((div, index) => (
                    <>
                        <div id={index === 0 ? 'features' : `section-${index}`} className={`featureSection ${index % 2 === 0 ? 'reverse' : ''}`} key={`section-${index}`}>
                            <div className="featureSection-image">
                                <img src={div.image} alt="feature" />
                            </div>
                            <div className="featureSection-text">
                                <h5>
                                    {index === 0 && <LayoutDashboard size={24} className="feature-icon" />}
                                    {index === 1 && <UserPlus size={24} className="feature-icon" />}
                                    {index === 2 && <BookOpen size={24} className="feature-icon" />}
                                    {index === 3 && <Users size={24} className="feature-icon" />}
                                    {index === 4 && <Palette size={24} className="feature-icon" />}
                                    {' '}{div.subTitle || ''}
                                </h5>
                                <h2>{div.title || ''}</h2>
                                <p>{div.description || ''}</p>
                                <ul>
                                    {div.bulletPoints && div.bulletPoints.map((point, idx) => (
                                        <li key={idx}>
                                            <Check size={20} strokeWidth={2.5} />
                                            {point}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        {/* Add alternating arrow animations between sections except after the last one */}
                        {index < 4 && !isMobile && (
                            <div className={`feature-section-title ${index % 2 === 0 ? 'left' : 'right'}`}>
                                <ScrollTriggeredLottie animationData={arrowAnimation}
                                    style={{
                                        width: '24%',
                                        marginTop: '50px'
                                    }} />
                            </div>
                        )}
                    </>
                ))}
                <div className="testimonial-section">
                    <div className="testimonial-container animated hidden">
                        <div className="quote-mark">"</div>
                        <p className="testimonial-quote">{translations[language].testimonial.quote}</p>
                        <div className="testimonial-author">
                            <p className="author-name">{translations[language].testimonial.author}</p>
                            <p className="author-position">{translations[language].testimonial.position}</p>
                        </div>
                    </div>
                </div>
                {!isMobile && (
                    <div className="feature-section-title right">
                        <ScrollTriggeredLottie animationData={arrowAnimation}
                            style={{
                                width: '24%',
                                margin: '140px 0px 90px 0px',
                                transform: 'rotate(30deg)',
                            }} />
                    </div>
                )}

                {/* Company section */}
                <div id="about" className="featureSection company-section">
                    <div className="featureSection-text">
                        <h5>
                            <Building2 size={24} className="feature-icon" />
                            {translations[language].company.subTitle}
                        </h5>
                        <h2>{translations[language].company.title}</h2>
                        <p>{translations[language].company.description}</p>
                    </div>
                    <div className="featureSection-image">
                        <img src={translations[language].company.image} alt="mockup" />
                    </div>
                </div>
                {/* For the final arrow before div6 */}
                {!isMobile && (
                    <div className="feature-section-title right">
                        <ScrollTriggeredLottie animationData={arrowAnimation}
                            style={{
                                width: '24%',
                                margin: '140px 0px 90px 0px',
                                transform: 'rotate(90deg)',
                            }} />
                    </div>
                )}
                {/* Final section */}
                <div id="contact" className="featureSection div6">
                    <h5>
                        <Rocket size={18} className="feature-icon" />
                        {' '}{translations[language].div6.subTitle}
                    </h5>
                    <h2>{translations[language].div6.title}</h2>
                    <p>{translations[language].div6.description}</p>
                    <button className="yellow-btn" style={{ marginTop: '30px', backgroundColor: 'var(--global-yellow)' }}>
                        <a style={{ color: 'var(--global-black)' }} href={`mailto:info@mdtportaal.nl`}>{translations[language].footer.contact}</a>
                    </button>
                </div>
            </div>

            <div className="landing-footer" style={{ backgroundColor: "var(--global-black)", padding: isMobile ? "50px 15px" : "80px 0" }}>
                <div className="footer-container">
                    {/* Logo Section */}
                    <div className="footer-logo-section">
                        <div className="footer-logo">
                            <Atom size={isMobile ? 30 : 35} color="white" />
                            <h2 style={{ color: "white", marginLeft: isMobile ? "0px" : "10px" }}>MDT {language === 'en' ? 'Portal' : 'Portaal'}</h2>
                        </div>
                        <p style={{ color: "white", marginTop: isMobile ? "0px" : "15px" }}>
                            {language === 'en'
                                ? 'The digital solution for MDT program management'
                                : 'De digitale oplossing voor MDT-programmabeheer'}
                        </p>
                        <p style={{ color: "white" }}><a style={{ color: "white" }} href="mailto:info@mdtportaal.nl">info@mdtportaal.nl</a></p>
                        <p style={{ color: "white" }}>MDT Portal &copy; {new Date().getUTCFullYear()}</p>

                    </div>

                    {/* Links Section */}
                    <div className="footer-links-section">
                        <h3 style={{ color: "white", marginBottom: "20px", marginTop: isMobile ? "30px" : "0px", textAlign: "left" }}>{language === 'en' ? 'Links' : 'Links'}</h3>
                        <div className="footer-links">
                            <a href="/login">{translations[language].footer.login}</a>
                            <a href={`mailto:info@mdtportaal.nl`}>{translations[language].footer.contact}</a>
                            <a href="/privacy">Privacy Policy</a>
                            <a href="/terms">Terms and Conditions</a>
                        </div>
                    </div>
                </div>

                {/* Copyright Section */}

            </div>
        </div>
    );
}

export default LandingPage;
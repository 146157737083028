import { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db, functions } from "../firebase";
import { collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { storage } from "../firebase";
import {
    ref, getDownloadURL, listAll, uploadBytes, deleteObject, getMetadata,
} from "firebase/storage";
import { Context } from "../Context";
import { nanoid } from "nanoid";

// Custom components
import withAuthentication from "../withAuthentication";
import Loading from "../components/Loading";
import Spinner from "../components/Spinner";

// UI components
import { Box, TextField, Modal, MenuItem, Select, FormControl, InputLabel, FormHelperText, Checkbox, ListItemText, Tabs, Tab, Divider } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import dayjs from "dayjs";
import { CloudUpload as CloudUploadIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Tooltip } from "react-tooltip";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Legend, ArcElement, Tooltip as ChartTooltip } from 'chart.js';
import { Users, FileText, ClipboardList, Group, BarChart2, Mail, Check } from 'lucide-react';

// .csv handling
import Papa from "papaparse";

// PDF handling
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// survey(quizzes) creator - surveyJS
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";

// Add ExcelJS import at the top of the file
import ExcelJS from 'exceljs';

function Admin() {
    // --- global state from Context --- //
    const { userState, loading } = useContext(Context);
    const { tenantId, role, uid, program, group, tenantName } = userState;
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    const isCK = tenantName === "CULTUUR & KUNST" || (program && program[0] === 'MDT Cultuur & Kunst')

    const [showSpinner, setShowSpinner] = useState(false);
    const spinner = `fas fa-spinner fa-spin`;

    const navigate = useNavigate();

    // ---  tabs --- //
    const [pageActiveTab, setPageActiveTab] = useState(0);

    const handlePageTabChange = (event, newValue) => {
        setPageActiveTab(newValue);
    };

    // ---  cloud functions https calls--- //
    const createAppUser = httpsCallable(functions, "createAppUser");
    const deleteAppUser = httpsCallable(functions, "deleteAppUser");
    const updateAppUser = httpsCallable(functions, "updateAppUser");
    const createProgram = httpsCallable(functions, "createProgram");
    const deleteProgram = httpsCallable(functions, "deleteProgram");
    const updateProgram = httpsCallable(functions, "updateProgram");
    const createGroup = httpsCallable(functions, "createGroup");
    const deleteGroup = httpsCallable(functions, "deleteGroup");
    const updateGroup = httpsCallable(functions, "updateGroup");

    // Validation functions
    const validateName = (name) => {
        const regex = /^[a-zA-Z0-9\s]{2,50}$/;
        if (!regex.test(name)) {
            return "Name should be 2-50 characters long and not contain the '-' symbol";
        } else if (name.includes('-')) {
            return "Name should not contain the '-' symbol";
        } else if (name.includes('/')) {
            return "Name should not contain the '/' symbol";
        } else {
            return "";
        }
    };

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email) ? "" : "Invalid email address.";
    };

    const validatePhone = (phone) => {
        const regex = /^\+?[0-9]{10,15}$/;
        return regex.test(phone) ? "" : "Please enter a valid phone number (10-15 digits, may start with +).";
    };


    // ---  managing users UI --- //
    const [users, setUsers] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState({
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        programError: "",
        groupError: "",
        roleError: "",
    });
    const [newContact, setNewContact] = useState({
        firstName: "",
        lastName: "",
        email: "",
        program: "",
        group: "",
        role: "",
        tenantName: "",
    });
    const [selectedUser, setSelectedUser] = useState({
        uid: "",
        firstName: "",
        lastName: "",
        email: "",
        program: [],
        group: [],
        role: "",
    });
    const [searchQuery, setSearchQuery] = useState("");
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState("create"); // Initialize with 'create' mode
    const [teachersFiles, setTeachersFiles] = useState([]); // for teachers files upload
    const [teachersFilesDropdown, setTeachersFilesDropdown] = useState([]); // for teachers files upload
    const [selectedTeacherFile, setSelectedTeacherFile] = useState(""); // for teachers files upload
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "ascending",
    }); // for sorting users table
    // Add state for tracking which user's email was just resent
    const [recentlySentEmails, setRecentlySentEmails] = useState({});

    // ---  programs UI --- //
    const [allPrograms, setAllPrograms] = useState([]);
    const [allProgramsDropdown, setAllProgramsDropdown] = useState([]);
    const [programTypes, setProgramTypes] = useState([]);
    const [programError, setProgramError] = useState({
        nameError: "",
        descriptionError: "",
        typeError: "",
        generalPageError: "",
        videosError: "",
        quizzesError: "",
    });
    const [newProgram, setNewProgram] = useState({
        name: "",
        description: "",
        type: "",
        generalPage: {
            title: "",
            subtitle: "",
            content: [],
            questions: [],
        },
        videos: [],
        quizzes: [],
    });
    const [selectedProgram, setSelectedProgram] = useState({
        name: "",
        description: "",
        type: "",
        generalPage: {
            title: "",
            subtitle: "",
            content: [],
            questions: [],
        },
        videos: [],
        quizzes: [],
        questionnaires: [],
    });
    const [files, setFiles] = useState([]); // state for keeping track of files to upload to storage in extra info modal
    const [uploadedFiles, setUploadedFiles] = useState([]); // array of uploaded files from db
    const [severity, setSeverity] = useState("success");
    const [oldProgramName, setOldProgramName] = useState(""); // old program name for updating program in users db

    // --- Program Modals --- //
    const [openProgramModal, setOpenProgramModal] = useState(false);
    const [programModalMode, setProgramModalMode] = useState("create"); // Initialize with 'create' mode
    const [openGeneralPageModal, setOpenGeneralPageModal] = useState(false);
    const [openVideosPageModal, setOpenVideosPageModal] = useState(false);
    const [openQuestionnairesPageModal, setOpenQuestionnairesPageModal] = useState(false);
    const [openQuizzesPageModal, setOpenQuizzesPageModal] = useState(false);
    const [openExtraInfoModal, setOpenExtraInfoModal] = useState(false);
    const [activeTab, setActiveTab] = useState(0); // for tabs in program general page modal
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };
    // --- Quiz Creator --- //
    const [surveyCreator, setSurveyCreator] = useState(null);
    const [selectedQuiz, setSelectedQuiz] = useState("");
    const [shouldUpdateDb, setShouldUpdateDb] = useState(false);

    // --- gorups UI --- //
    const [allGroups, setAllGroups] = useState([]);
    // const [allGroupsDropdown, setAllGroupsDropdown] = useState([]);

    const emptySessions = Array.from({ length: 8 }, () => {
        // Format today's date as a string (e.g., "YYYY-MM-DD")
        const todayFormatted = dayjs().format("DD-MM-YYYY");
        const defaultTime = dayjs().format("HH:mm");

        return {
            sessionNum: "",
            sessionId: nanoid(),
            topic: "",
            date: todayFormatted,
            time: defaultTime,
            endTime: defaultTime,
            location: "",
            notes: "",
            questionnaire: "",
            completed: false,
        };
    });

    const topics = [
        "Mijlpalen, + doel",
        "Balans",
        "Voorbereiding gesprek",
        "Gesprek met hulpdiensten",
        "Strip voorbereiden, oa storyboard, etc.",
        "Strip maken/doen!",
        "Linkerbeen-rechterbeen",
        "Perspectieven",
    ];

    const ckTopics = [
        "Introductie workshop",
        "Gesprek met verteller",
        "Fotografie/schrijf workshop",
        "Afronding deel 1",
        " Start deel 2: Zwarte doek",
        "Brainstorm",
        "Filmopnames",
        "Filmopnames",
    ];

    const [sessions, setSessions] = useState(emptySessions);
    const [groupError, setGroupError] = useState({
        nameError: "",
        programError: "",
        contactNameError: "",
        contactNumError: "",
        schoolContactNameError: "",
        schoolContactNumError: "",
        scheduleError: "",
    });
    const [newGroup, setNewGroup] = useState({
        name: "",
        program: "",
        contactName: "",
        contactNum: "",
        schoolContactName: "",
        schoolContactNum: "",
        schedule: sessions,
    });
    const [selectedGroup, setSelectedGroup] = useState("");
    const [openGroupModal, setOpenGroupModal] = useState(false);
    const [groupModalMode, setGroupModalMode] = useState("");
    const [groupSearchQuery, setGroupSearchQuery] = useState("");
    const [oldGroupName, setOldGroupName] = useState(""); // old group name for updating group in users db
    const [selectedGroupStudents, setSelectedGroupStudents] = useState([]);
    const [selectedGroupSessions, setSelectedGroupSessions] = useState([]);
    const [openAddStudentsModal, setOpenAddStudentsModal] = useState(false);
    const [studentsCSV, setStudentsCSV] = useState(null);


    // user modal styles
    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "600px",
        height: "600px",
        bgcolor: "background.paper",
        border: "none",
        boxShadow: 24,
        p: 4,
        borderRadius: "20px",
        overflow: "auto",
        ".file-upload-label": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "40px",
            width: "50vw",
            border: "1px dashed #ccc",
            borderRadius: "4px",
            cursor: "pointer",
            margin: "10px 0",
            backgroundColor: "#f0f0f0",
        },
        ".file-list": {
            marginTop: "20px",
        },
        ".file-item": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px",
            border: "1px solid #eee",
            borderRadius: "4px",
            marginBottom: "10px",
            backgroundColor: "#fff",
        },
        ".file-size": {
            margin: "10px",
            fontSize: "0.9em",
            color: "#666",
        },
    };
    // program modal styles
    const programModalStyle = {
        ...modalStyle,
        width: "800px",
        height: "600px",
    };
    // group modal styles
    const groupModalStyle = {
        ...modalStyle,
        width: "95vw",
        minHeight: "95vh",
        padding: "40px",
    };

    const largeModalStyle = {
        ...modalStyle,
        width: "90vw",
        minHeight: "90vh",
    };

    const videoModalStyle = {
        ...modalStyle,
        width: "60vw",
        minHeight: "90vh",
    }

    const addStudentsCSVModalStyle = {
        ...modalStyle,
        width: "650px",
        height: "350px",
        overflow: "none",
    };

    // ---  useEffects for all functions --- //
    // check if user is admin and if not redirect
    useEffect(() => {
        if (loading) return;
        if (!role || role !== "admin") {
            console.log("user is not logged in or is not an admin");
            navigate("/");
        } else {
            // resetIdleTimer(); // Reset the idle timer on user activity
        }
    }, [userState, loading, navigate, role]);

    // Fetch all users from Firestore
    useEffect(() => {
        if (loading || !tenantId) return;
        const fetchAllUsers = async () => {
            setMessage(""); // Clear any previous error messages
            try {
                const usersRef = collection(db, "tenants", tenantId, "users");
                const userSnapshot = await getDocs(usersRef);
                const users = userSnapshot.docs.map((doc) => {
                    const userData = doc.data();
                    return {
                        uid: doc.id,
                        ...userData,
                    };
                });
                setUsers(users);
            } catch (err) {
                console.log(err);
                setMessage(err.message);
            }
        };
        fetchAllUsers();
    }, [loading, forceUpdate, tenantId]);

    // fetch programs from firestore
    useEffect(() => {
        if (loading || !tenantId) return;
        const fetchAllPrograms = async () => {
            setMessage("");
            try {
                const programsRef = collection(db, "tenants", tenantId, "programs");
                const programSnapshot = await getDocs(programsRef);
                const programs = programSnapshot.docs.map((doc) => {
                    const programData = doc.data();
                    return {
                        uid: doc.id,
                        ...programData,
                    };
                });
                setAllPrograms(programs);
                // set first progam as selected program
                if (programs && programs.length > 0) {
                    setSelectedProgram(programs[0]);
                }
            } catch (err) {
                console.log(err);
                setMessage(err.message);
            }
        };
        fetchAllPrograms();
    }, [loading, forceUpdate, tenantId]);

    // fetch groups from firestore
    useEffect(() => {
        if (loading || !tenantId) return;
        const fetchAllGroups = async () => {
            setMessage("");
            try {
                const groupsRef = collection(db, "tenants", tenantId, "groups");
                const groupSnapshot = await getDocs(groupsRef);
                const groups = groupSnapshot.docs.map((doc) => {
                    const groupData = doc.data();
                    // console.log('Fetched group:', { id: doc.id, ...groupData });
                    return {
                        uid: doc.id,
                        ...groupData,
                    };
                });
                setAllGroups(groups);
            } catch (err) {
                console.log(err);
                setMessage(err.message);
            }
        };
        fetchAllGroups();
    }, [loading, forceUpdate, tenantId]);

    // Fetch all programs from Firestore
    useEffect(() => {
        if (loading || !tenantId) return;
        const fetchAllPrograms = async () => {
            setMessage("");
            try {
                const programsRef = collection(db, "tenants", tenantId, "programs");
                const programSnapshot = await getDocs(programsRef);
                const programs = programSnapshot.docs.map((doc) => {
                    const programData = doc.data();
                    return {
                        uid: doc.id,
                        ...programData,
                    };
                });
                setAllPrograms(programs);

                // set all program types:
                const programTypes = programs
                    .map((program) => program.type)
                    .filter((type) => type !== undefined);
                setProgramTypes(programTypes);
            } catch (err) {
                console.log(err);
                setMessage(err.message);
            }
        };
        fetchAllPrograms();
    }, [loading, forceUpdate, tenantId]);

    // set all programs to a dropdown:
    useEffect(() => {
        if (loading || !allPrograms) return;
        const allProgramsDropdown = allPrograms.map((program) => {
            return (
                <MenuItem key={program.uid} value={program.name}>
                    {program.name}
                </MenuItem>
            );
        });
        setAllProgramsDropdown(allProgramsDropdown);
    }, [loading, allPrograms]);

    // Fetch all groups from Firestore
    useEffect(() => {
        if (loading || !tenantId) return;
        const fetchAllGroups = async () => {
            setMessage("");
            try {
                const groupsRef = collection(db, "tenants", tenantId, "groups");
                const groupSnapshot = await getDocs(groupsRef);
                const groups = groupSnapshot.docs.map((doc) => {
                    const groupData = doc.data();

                    return {
                        uid: doc.id,
                        ...groupData,
                    };
                });
                setAllGroups(groups);
            } catch (err) {
                console.log(err);
                setMessage(err.message);
            }
        };
        fetchAllGroups();
    }, [loading, forceUpdate, tenantId]);

    // fetch all files uploaded to the student's folder in storage
    useEffect(() => {
        if (loading || !uid || !tenantId) return;

        const fetchAllFiles = async () => {
            setMessage("");
            try {
                // get user claims
                const user = auth.currentUser;
                const token = await user.getIdTokenResult();

                // check if user is admin
                if (token.claims.admin || token.claims.superAdmin) {
                    const listRef = ref(storage, `${tenantId}/studentLists/pending/`);
                    const filesList = await listAll(listRef);
                    const fileNames = filesList.items.map((reference) => reference.name);
                    setTeachersFiles(fileNames);
                }
            } catch (err) {
                console.log(err);
                setMessage(err.message);
            }
        };
        fetchAllFiles();
    }, [loading, forceUpdate, uid, tenantId]);

    // set all teacher files to a dropdown:
    useEffect(() => {
        if (loading || !teachersFiles || teachersFiles.length === 0) return;

        const teachersFilesDropdown = teachersFiles.map((fileName, index) => {
            return (
                <MenuItem key={index} value={fileName}>
                    {fileName}
                </MenuItem>
            );
        });
        setTeachersFilesDropdown(teachersFilesDropdown);
    }, [loading, teachersFiles]);

    // --- Analytics --- //
    // Register chart.js components
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, LineElement, PointElement, Legend, ArcElement, ChartTooltip);

    // CHART 1: Student Distribution by Group
    const studentCountsByGroup = allGroups.map(group => {
        return {
            groupName: group.uid,  // Use group UID or another field for group names
            studentCount: group.schedule ?
                group.schedule.reduce((acc, session) => {
                    if (!session.attendance) return acc;  // Skip sessions without attendance
                    return acc + session.attendance.length;
                }
                    , 0) : 0,
        };
    });

    // Extract the labels (group names) and data (student counts) for the chart
    const groupLabels = studentCountsByGroup.map(group => group.groupName);
    const studentCounts = studentCountsByGroup.map(group => group.studentCount);

    const analyticsColors = [
        'rgba(75, 192, 192, 0.6)',
        'rgba(255, 99, 132, 0.6)',
        'rgba(54, 162, 235, 0.6)',
        'rgba(255, 206, 86, 0.6)',
        'rgba(153, 102, 255, 0.6)',
        'rgba(255, 159, 64, 0.6)',
        'rgba(100, 255, 100, 0.6)',
        'rgba(255, 105, 180, 0.6)',
        'rgba(240, 128, 128, 0.6)',
        'rgba(255, 140, 0, 0.6)',
    ];
    // Assign colors dynamically based on the number of groups
    const backgroundColors = groupLabels.map((label, index) => analyticsColors[index % analyticsColors.length]);  // Reuse analyticsColors if there are more groups than analyticsColors
    const borderColors = groupLabels.map((label, index) => analyticsColors[index % analyticsColors.length].replace('0.6', '1'));  // Set border colors with opacity 1

    const studentDistributionChartData = {
        labels: groupLabels,  // Group names
        datasets: [
            {
                label: 'Number of Students',
                data: studentCounts,  // Number of students in each group
                backgroundColor: backgroundColors,
                borderColor: borderColors,
                borderWidth: 1,
            },
        ],
    };

    // Aggregating attendance data per group
    const attendanceDataPerGroup = allGroups.map(group => {
        let aanwezigCount = 0; // present
        let afwezigCount = 0; // absent
        let zelfstandigCount = 0; // self-study

        // only check groups that has schedule:
        if (!group.schedule) return {
            groupName: group.uid,
            aanwezig: 0,
            afwezig: 0,
            zelfstandig: 0,
        };

        group.schedule.forEach(session => {
            if (!session.attendance) return;  // Skip sessions without attendance
            aanwezigCount += session.attendance.filter(att => att.attendanceStatus === 'aanwezig').length;
            afwezigCount += session.attendance.filter(att => att.attendanceStatus === 'afwezig').length;
            zelfstandigCount += session.attendance.filter(att => att.attendanceStatus === 'zelfstandig').length;
        });

        return {
            groupName: group.uid,
            aanwezig: aanwezigCount,
            afwezig: afwezigCount,
            zelfstandig: zelfstandigCount,
        };
    });

    // attendance chart data
    const attendanceChartData = {
        labels: attendanceDataPerGroup.map(group => group.groupName),  // Labels for each group
        datasets: [
            {
                label: 'Aanwezig',
                data: attendanceDataPerGroup.map(group => group.aanwezig),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
            {
                label: 'Afwezig',
                data: attendanceDataPerGroup.map(group => group.afwezig),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
            },
            {
                label: 'Zelfstandig',
                data: attendanceDataPerGroup.map(group => group.zelfstandig),
                backgroundColor: 'rgba(255, 206, 86, 0.6)',
            },
        ],
    };

    // CHART 3: Group Openings
    // chart showing admins how many groups were opened each month:
    // Get the current date and the date 12 months ago
    const currentDate = new Date();
    const twelveMonthsAgo = new Date();
    twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);  // Set to 11 months ago to include the current month

    // Generate the last 12 months as labels
    const last12MonthsLabels = [];
    for (let i = 0; i < 12; i++) {
        const date = new Date();
        date.setMonth(currentDate.getMonth() - i);  // Subtract i months from the current date
        last12MonthsLabels.unshift(`${date.getMonth() + 1}-${date.getFullYear()}`);  // Format "MM-YYYY"
    }

    // Initialize the group counts for each month to 0
    const groupCountsByMonth = last12MonthsLabels.reduce((acc, label) => {
        acc[label] = 0;
        return acc;
    }, {});

    // Convert startDate and count groups per month
    allGroups.forEach(group => {
        // return if group is empty:
        if (!group.startDate) return;
        const [day, month, year] = group.startDate.split('-');  // Split the "DD-MM-YYYY" format
        const date = new Date(`${year}-${month}-${day}`);  // Create a Date object using "YYYY-MM-DD" format

        // Only process groups opened within the last 12 months
        if (date >= twelveMonthsAgo && date <= currentDate) {
            const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;  // Format as "MM-YYYY"

            // Increment the count for the month
            if (groupCountsByMonth[monthYear] !== undefined) {
                groupCountsByMonth[monthYear]++;
            }
        }
    });

    // Convert object into arrays for chart usage
    const monthLabels = Object.keys(groupCountsByMonth);  // Labels: 'MM-YYYY'
    const groupCounts = Object.values(groupCountsByMonth);  // Values: count of groups opened in each month

    const groupOpenedChartData = {
        labels: monthLabels,  // Labels for each month (e.g., '06-2024')
        datasets: [
            {
                label: 'Number of Groups Opened',
                data: groupCounts,  // Data corresponding to each month
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Chart 4: % of students who watched videos per group
    const videoWatchData = allGroups.map(group => {
        // check if group has a schedule, if not return 0
        if (!group.schedule) return {
            groupName: group.uid,
            percentageWatched: 0,
        };

        //  check if group has assigned videos in its sessions, if not return 0
        const assignedVideos = group.schedule.reduce((acc, session) => {
            if (session.videos) {
                acc.push(...session.videos);
            }
            return acc;
        }, []);

        if (assignedVideos.length === 0) return {
            groupName: group.uid,
            percentageWatched: 0,
        };
        const totalVideos = assignedVideos.length;  // Total assigned videos for the group
        const totalStudents = group.schedule.reduce((acc, session) => {
            if (session.attendance) {
                acc += session.attendance.length;  // Count the number of students in the session
            }
            return acc;
        }, 0);

        // Filter out users who are students and part of this group
        const studentsInGroup = users.filter(user =>
            user.group.includes(group.uid)
        );

        let watchedVideosCount = 0;
        studentsInGroup.forEach(student => {
            if (student.role !== 'user') return;  // Skip non-student users
            if (!student.completedVideos) return;  // Skip students without completed videos
            const watchedVideos = student.completedVideos.length
            if (watchedVideos > 0) {
                watchedVideosCount++;
            }
        }
        );
        // Calculate the percentage of students who watched videos
        const percentageWatched = totalVideos > 0 && totalStudents > 0
            ? (watchedVideosCount / (totalStudents * totalVideos)) * 100
            : 0;

        return {
            groupName: group.uid,  // Group ID or name
            percentageWatched: percentageWatched.toFixed(2),  // Round to 2 decimal places
        };

    });

    // Extract the labels (group names) and data (percentage watched) for the chart
    const groupVideoLabels = videoWatchData.map(data => data.groupName);
    const videoWatchPercentages = videoWatchData.map(data => data.percentageWatched);

    const videoWatchChartData = {
        labels: groupVideoLabels,  // Group names
        datasets: [
            {
                label: '% of Students Who Watched Videos',
                data: videoWatchPercentages,  // Percentage of students who watched the videos
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Chart 5: % of students who completed quizzes per group
    const questionnairesCompletionData = allGroups.map(group => {
        // check if group has a schedule, if not return 0
        if (!group.schedule)
            return {
                groupName: group.uid || group.name,
                percentageCompleted: 0,
            };

        //  check if group has assigned quizzes in its sessions, if not return 0
        const assignedQuestionnaires = group.schedule.reduce((acc, session) => {
            if (session.questionnaire && session.questionnaire !== '') {
                acc.push(session.questionnaire); // Push the questionnaire name if it's not empty
            }
            return acc;
        }, []);

        if (assignedQuestionnaires.length === 0)
            return {
                groupName: group.uid || group.name,
                percentageCompleted: 0,
            };

        const totalQuestionnaires = assignedQuestionnaires.length;  // Total assigned questionnaires for the group

        // Filter out users who are students and part of this group
        const studentsInGroup = users.filter(user =>
            user.group.includes(group.uid)
        );
        const totalStudents = studentsInGroup.length;

        let completedQuestionnairesCount = 0;

        studentsInGroup.forEach(student => {
            if (!student.questionnaires) return;  // Skip students without any questionnaires

            // Check if the student has completed the questionnaires assigned in the group
            assignedQuestionnaires.forEach(assigned => {
                const completed = student.questionnaires.some(q => q.title === assigned && q.complete === true);
                if (completed) {
                    completedQuestionnairesCount++;
                }
            });
        }
        );

        // Calculate the percentage of students who completed quizzes
        const percentageCompleted = totalQuestionnaires > 0 && totalStudents > 0
            ? (completedQuestionnairesCount / (totalStudents * totalQuestionnaires)) * 100
            : 0;

        return {
            groupName: group.uid,  // Group ID or name
            percentageCompleted: percentageCompleted.toFixed(2),  // Round to 2 decimal places
        };

    });

    // Extract the labels (group names) and data (percentage completed) for the chart
    const groupQuestionnairesLabels = questionnairesCompletionData.map(data => data.groupName);
    const questionnairesCompletionPercentages = questionnairesCompletionData.map(data => data.percentageCompleted);

    const questionnairesCompletionDataForChart = {
        labels: groupQuestionnairesLabels,  // Group names
        datasets: [
            {
                label: '% of Students Who Completed Questionniares',
                data: questionnairesCompletionPercentages,  // Percentage of students who completed the quizzes
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };

    //  Chart #6 - Number of students who completed quizzes per group
    const quizCompletionData = allGroups.map(group => {
        // Get the program associated with the group

        const program = allPrograms.find(prog => prog.name === group.program);  // Assuming group is associated with a program

        if (!program || !program.quizzes || program.quizzes.length === 0) {
            return {
                groupName: group.uid,
                percentageCorrect: 0,
            };
        }

        // const totalStudents = group.attendance.length;  // Total students from attendance
        const totalQuizzes = program.quizzes.length;  // Total quizzes from the program

        // Filter out users who are students and part of this group
        const studentsInGroup = users.filter(user => user.group.includes(group.uid));

        let totalCorrectAnswers = 0;
        let totalQuestionsAnswered = 0;
        let quizzesCompleted = 0;

        studentsInGroup.forEach(student => {
            if (!student.completedQuizzes) return;  // Skip students without completed quizzes

            student.completedQuizzes.forEach(completedQuiz => {
                const programQuiz = program.quizzes.find(q => q.title === completedQuiz.quizTitle);
                if (!programQuiz) return;  // Skip quizzes not in the program

                totalCorrectAnswers += completedQuiz.correctAnswersCount;
                totalQuestionsAnswered += completedQuiz.totalQuestionCount;
                quizzesCompleted++;  // Track the number of quizzes completed by students
            });
        });

        // Calculate the percentage of correct answers
        const percentageCorrect = totalQuestionsAnswered > 0
            ? (totalCorrectAnswers / totalQuestionsAnswered) * 100
            : 0;

        // Return group data
        return {
            groupName: group.uid,  // Group ID or name
            percentageCorrect: percentageCorrect.toFixed(2),  // Round to 2 decimal places
            quizzesCompleted,  // Track how many quizzes were completed
            totalQuizzes,  // Total quizzes available
        };

    });

    // Extract the labels (group names) and data (percentage correct answers) for the chart
    const groupQuizLabels = quizCompletionData.map(data => data.groupName);
    const quizCompletionPercentages = quizCompletionData.map(data => data.percentageCorrect);
    const completedQuizzesData = quizCompletionData.map(data => data.quizzesCompleted);
    const totalQuizzesData = quizCompletionData.map(data => data.totalQuizzes);

    // Chart.js configuration for displaying the quiz completion data
    const quizCompletionChartData = {
        labels: groupQuizLabels,  // Group names
        datasets: [
            {
                label: '% of Correct Quiz Answers',
                data: quizCompletionPercentages,  // Percentage of correct answers
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
            {
                label: 'Number of Quizzes Completed',
                data: completedQuizzesData,  // Number of quizzes completed by students
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                type: 'bar',  // Show this as a bar chart
            },
            {
                label: 'Total Quizzes Available',
                data: totalQuizzesData,  // Total quizzes in the program
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                type: 'bar',  // Show this as a bar chart
            },
        ],
    };

    //  --- USERS FUNCTIONS --- //
    const handleClose = () => setOpen(false);

    const openCreateModal = () => {
        setMode("create");
        setSelectedUser(""); // Clear the selected user when opening in create mode
        setOpen(true);
    };

    const openUpdateUserModal = (user) => {
        setMode("update");
        setSelectedUser(user); // Set the selected user when opening in update mode
        setOpen(true);
    };

    const addSingleUser = async (e) => {
        e.preventDefault();
        // check if all fields are filled
        if (!newContact.firstName) {
            setError({ firstNameError: "Please enter a first name" });
            return;
        }
        if (!newContact.lastName) {
            setError({ lastNameError: "Please enter a last name" });
            return;
        }
        if (!newContact.email) {
            setError({ emailError: "Please enter an email" });
            return;
        }
        if (!newContact.program) {
            setError({ programError: "Please enter a program name" });
            return;
        }
        if (!newContact.group) {
            setError({ groupError: "Please enter a group name" });
            return;
        }
        if (!newContact.role) {
            setError({ roleError: "Please select a role" });
            return;
        }

        // if there's an email, check if it's typed correctly
        if (newContact.email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/;
            if (!emailRegex.test(newContact.email)) {
                setError({ emailError: "Please enter a valid email" });
                return;
            }
        }

        // if selected role is admin, make sure this is what the user wants:
        if (newContact.role === "admin") {
            const confirmAdmin = window.confirm(
                "Are you sure you want to make this user an admin? This will give them FULL access to all the data in the app. Only give admin access to trusted users."
            );
            if (!confirmAdmin) return;
        }
        // if selected role is teacher, make sure this is what the user wants:
        if (newContact.role === "teacher") {
            const confirmTeacher = window.confirm(
                "Are you sure you want to make this user a teacher? This will give them access to data of all users in the selected group. Only give teacher access to trusted users."
            );
            if (!confirmTeacher) return;
        }

        setError({
            firstNameError: "",
            lastNameError: "",
            emailError: "",
            programError: "",
            groupError: "",
            roleError: "",
        });

        // check if user already exists
        const usersRef = collection(db, "tenants", tenantId, "users");
        const userQuery = query(usersRef, where("email", "==", newContact.email));

        try {
            setMessage("");
            const querySnapshot = await getDocs(userQuery);
            // Check if any documents match the query
            if (!querySnapshot.empty) {
                setError({ emailError: "User already exists" });
                setMessage("User with this email already exists");
                alert("User with this email already exists");
                return;
            }
        } catch (err) {
            console.log(err.message);
            setMessage('Error adding user:', err.message);
        }

        // check if user has a tenantId by checking custom claims
        const user = auth.currentUser;
        try {
            const token = await user.getIdTokenResult();
            const claims = token.claims;
            if (!claims.tenantId) {
                alert("You do not have the right credentials. Please contact your administrator.");
                return;
            }
            // get tenant name:
            const tenantDocRef = doc(db, "tenants", tenantId);
            const tenantDoc = await getDoc(tenantDocRef);

            if (tenantDoc.exists()) {
                const tenantData = tenantDoc.data();
                const tenantName = tenantData.companyName;
                newContact.tenantName = tenantName;
            } else {
                console.log('no tenant found');
                return
            }

        } catch (err) {
            console.log(err);
        }

        // add user to db via cloud function
        try {
            const messageWithSpinner = <span>Adding new user...<i className={spinner}></i> </span>
            setMessage(messageWithSpinner);

            await createAppUser({
                email: newContact.email,
                firstName: newContact.firstName,
                lastName: newContact.lastName,
                program: newContact.program,
                group: newContact.group,
                role: newContact.role,
                tenantName: newContact.tenantName,
            });

            setMessage("");
            setNewContact({
                firstName: "",
                lastName: "",
                email: "",
                program: "",
                group: "",
                role: "",
            });
            setForceUpdate((prev) => !prev);
            handleClose();
        } catch (err) {
            console.log(err);
            setMessage(err.message);
        }
    };

    // parse.csv student list uploaded by admin
    const handleFileUpload = (event) => {
        setMessage("");
        // if nothing is selected, return:
        const { files } = event.target;
        if (!files || files.length === 0) {
            setMessage("Please select a file and try again");
            alert("Please select a file and try again");
            console.log("no file selected");
            return;
        }
        const file = files[0];

        // Check if the file is a CSV
        if (file && file.type === "text/csv") {
            const reader = new FileReader();
            reader.onload = (e) => {
                const csvString = e.target.result;
                createUsersFromCSV(csvString);
            };
            reader.readAsText(file);
        } else {
            console.log("file type is not csv:", file.type);
            alert("File selected is not a CSV file. Please only upload a CSV file");
        }
    };

    const createUsersFromCSV = async (data, fileName) => {
        setShowSpinner(true);
        try {
            const messageWithSpinner = <span>Uploading...<i className={spinner}></i> </span>
            setMessage(messageWithSpinner);

            const { data: parsedData, errors } = Papa.parse(data,
                {
                    header: true,
                    skipEmptyLines: true,
                    dynamicTyping: true,
                    transformHeader: (header) => header.trim(),
                    transform: (value) => {
                        if (typeof value === "string") {
                            // Trim the value and convert empty strings to null
                            value = value.trim();
                            return value === "" ? null : value;
                        }
                        return value;
                    },
                    delimiter: '',  // Auto-detect delimiter
                    encoding: "UTF-8",
                    newline: '\r\n',
                });

            const skippedItems = [];

            if (errors.length > 0) {
                console.error("Error parsing CSV file:", errors);
                alert("Error uploading the CSV file:", errors);
                setMessage("");
                return;
            }

            // Check if the required headers exist
            const requiredHeaders = [
                "firstName",
                "lastName",
                "email",
                "program",
                "group",
                "role",
            ];
            const csvHeaders = Object.keys(parsedData[0]);
            const missingHeaders = requiredHeaders.filter(
                (header) => !csvHeaders.includes(header)
            );

            if (missingHeaders.length > 0) {
                setMessage("");
                console.error("Missing required headers:", missingHeaders);
                alert(`Missing required headers in the CSV file: ${missingHeaders.join(
                    ", "
                )},
                 please check the file and try again`);
                return;
            }

            // Check if the admin has access to all the programs and groups in the CSV file
            const uniquePrograms = new Set(parsedData.map(item => item.program));
            const uniqueGroups = new Set(parsedData.map(item => item.group));

            if (![...uniquePrograms].every(p => program.includes(p)) || ![...uniqueGroups].every(g => group.includes(g))) {
                console.error("Admin does not have access to all the programs and groups in the CSV file");
                alert("The CSV file contains programs or groups that you do not have access to. Please upload a CSV file that only contains programs and groups that you have access to.");
                setMessage("");
                return;
            }

            for (const item of parsedData) {
                if (
                    !item.email ||
                    !item.firstName ||
                    !item.lastName ||
                    !item.program ||
                    !item.group ||
                    !item.role
                ) {
                    console.warn("Skipping invalid line:", item);
                    skippedItems.push(item);
                    continue; // Skip this iteration and move to the next line
                }
                // get tenant name:
                const tenantDocRef = doc(db, "tenants", tenantId);
                const tenantDoc = await getDoc(tenantDocRef);

                let fetchedTenantName = "";
                if (tenantDoc.exists()) {
                    const tenantData = tenantDoc.data();
                    const tenantName = tenantData.companyName;
                    fetchedTenantName = tenantName;

                } else {
                    console.log('no tenant found');
                    return
                }
                // console.log("fetchedTenantName:", fetchedTenantName);
                // creates users in auth and db and sends invite email via SendGrid
                const newUser = await createAppUser({
                    email: item.email,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    program: [item.program],
                    group: [item.group],
                    role: item.role,
                    tenantName: fetchedTenantName,
                });

                if (newUser.error) {
                    console.error("Error creating user:", newUser.error);
                    continue; // Skip further processing for this user
                }

                setMessage("Users uploaded successfully");
                // alert("Success: users accounts created successfully");

                try {
                    // After successfully creating users, move the file to the "uploaded" folder
                    if (fileName) {
                        const pendingFileRef = ref(
                            storage,
                            `${tenantId}/studentLists/pending/${fileName}`
                        );
                        // Get the download URL of the source file (pending folder)
                        const sourceUrl = await getDownloadURL(pendingFileRef);
                        // Create a reference to the destination file (uploaded folder)
                        const destinationRef = ref(
                            storage,
                            `${tenantId}/studentLists/uploaded/${fileName}`
                        );
                        // copy file by fetching file from the source URL and uploading it to the destination ref

                        try {
                            const response = await fetch(sourceUrl);
                            if (!response.ok) throw new Error("Error downloading file");
                            const blob = await response.blob();
                            if (!blob) throw new Error("Error downloading file");
                            const snapshot = await uploadBytes(destinationRef, blob);
                            if (!snapshot) throw new Error("Error uploading file");

                            // Delete the file from the pending folder
                            if (snapshot) {
                                await deleteObject(pendingFileRef);
                                console.log("File deleted successfully");
                            }
                            // Update the dropdown list of files to upload
                            setTeachersFilesDropdown((prev) => {
                                const updatedDropdown = prev.filter(
                                    (item) => item.key !== fileName
                                );
                                return updatedDropdown;
                            });
                            // Update the list of files to upload
                            setTeachersFiles((prev) => {
                                const updatedFiles = prev.filter((item) => item !== fileName);
                                return updatedFiles;
                            });
                        } catch (err) {
                            console.log("error moving and deleting file:", err);
                        }
                    }
                } catch (err) {
                    console.error("Error sending password reset email:", err);
                    setMessage(err.message);
                }
            }
            if (skippedItems.length > 0) {
                const skippedItemsList = skippedItems
                    .map((item) => {
                        return `- Email: ${item.email}, First Name: ${item.firstName}, Last Name: ${item.lastName}`;
                    })
                    .join("\n");
                alert(
                    `Skipped ${skippedItems.length} invalid line(s) in CSV file. Please check the following items:\n\n${skippedItemsList}`
                );
            }
            setForceUpdate((prev) => !prev); // Trigger re-render to fetch latest user data
        } catch (err) {
            console.error("Error in createUsersFromCSV:", err);
            setMessage(err.message);
            alert(err.message);
        } finally {
            setMessage("");
            setShowSpinner(false);
        }
    };

    const deleteUserFromAuthAndDB = async (
        userId,
        userFirstName,
        userLastName
    ) => {
        const confirmDelete = window.confirm(
            `Are you sure you want to delete ${userFirstName + userLastName
            }? This will remove the user from all records. This action cannot be undone.`
        );
        if (!confirmDelete) return;
        setShowSpinner(true);

        if (!userId) {
            setMessage(
                "Error: cannot delete user: please select a user and try again"
            );
            return;
        }

        // check if user has a tenantId by checking custom claims
        const user = auth.currentUser;
        try {
            const token = await user.getIdTokenResult();
            const claims = token.claims;
            if (!claims.tenantId) {
                setMessage(
                    "You do not have the right credentials. Please contact your administrator."
                );
                return;
            }
        } catch (err) {
            console.log(err);
        }

        try {
            const messageWithSpinner = <span>Deleting...<i className={spinner}></i> </span>
            setMessage(messageWithSpinner);
            // Delete user from db + Authentication
            await deleteAppUser({ uid: userId });

            setForceUpdate((prev) => !prev);
            setMessage(
                `The user ${userFirstName + userLastName} was deleted successfully.`
            );
        } catch (error) {
            console.error("Error deleting user:", error);
            setMessage(
                `Error: cannot delete user ${userFirstName + userLastName}:` +
                error.message
            );
        } finally {
            setTimeout(() => {
                setMessage("");
            }, 2500);
            setShowSpinner(false);
        }
    };

    async function updateUser(e) {
        e.preventDefault();

        // check if all fields are filled
        if (!selectedUser.firstName) {
            setError({ firstNameError: "Please enter a first name" });
            return;
        }
        if (!selectedUser.lastName) {
            setError({ lastNameError: "Please enter a last name" });
            return;
        }
        if (!selectedUser.email) {
            setError({ emailError: "Please enter an email" });
            return;
        }
        if (!selectedUser.program) {
            setError({ programError: "Please enter a program" });
            return;
        }
        if (!selectedUser.group) {
            setError({ groupError: "Please enter a group" });
            return;
        }
        if (!selectedUser.role) {
            setError({ roleError: "Please enter a role" });
            return;
        }
        // if there's an email, check if it's typed correctly:
        if (selectedUser.email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/;
            if (!emailRegex.test(selectedUser.email)) {
                setError({ emailError: "Please enter a valid email" });
                return;
            }
        }
        // if selected role is admin, make sure this is what the user wants:
        if (selectedUser.role === "admin") {
            const confirmAdmin = window.confirm(
                "Are you sure you want to make this user an admin? This will give them FULL access to all the data in the app. Only give admin access to trusted users."
            );
            if (!confirmAdmin) return;
        }
        // if selected role is teacher, make sure this is what the user wants:
        if (selectedUser.role === "teacher") {
            const confirmTeacher = window.confirm(
                "Are you sure you want to make this user a teacher? This will give them access to data of all users in the selected group. Only give teacher access to trusted users."
            );
            if (!confirmTeacher) return;
        }

        setError({
            firstNameError: "",
            lastNameError: "",
            emailError: "",
            programError: "",
            groupError: "",
            roleError: "",
        });

        // check if user has a tenantId by checking custom claims
        const user = auth.currentUser;
        try {
            const token = await user.getIdTokenResult();
            const claims = token.claims;
            if (!claims.tenantId) {
                setMessage(
                    "You do not have the right credentials. Please contact your administrator."
                );
                return;
            }
        } catch (err) {
            console.log("error getting claims token or user has no tenantId", err);
        }

        setShowSpinner(true);
        try {
            const messageWithSpinner = <span>Updating user... <i className={spinner}></i> </span>
            setMessage(messageWithSpinner);
            // Update user in Firebase Authentication
            await updateAppUser({
                uid: selectedUser.uid,
                email: selectedUser.email,
                firstName: selectedUser.firstName,
                lastName: selectedUser.lastName,
                program: selectedUser.program,
                group: selectedUser.group,
                role: selectedUser.role,
            });

            setMessage(
                `User ${selectedUser.firstName + selectedUser.lastName} updated successfully.`
            );
            setForceUpdate((prev) => !prev);
            handleClose();
        } catch (error) {
            console.error("Error updating user:", error);
            setMessage(
                `Error: cannot update user ${selectedUser.firstName + selectedUser.lastName
                }:` + error.message
            );
        } finally {
            setMessage("");
            setShowSpinner(false);
        }
    }

    const handleResendInvite = async (user) => {
        try {
            const messageWithSpinner = <span>Resending invite...<i className={spinner}></i></span>;
            setMessage(messageWithSpinner);

            const resendUserInvite = httpsCallable(functions, 'resendUserInvite');
            await resendUserInvite({
                email: user.email,
                firstName: user.firstName,
                uid: user.uid
            });

            // Show success checkmark
            setRecentlySentEmails(prev => ({
                ...prev,
                [user.uid]: true
            }));

            setMessage("Invite resent successfully");
            // Reset icon after 3 seconds
            setTimeout(() => {
                setMessage("");
                setRecentlySentEmails(prev => ({
                    ...prev,
                    [user.uid]: false
                }));
            }, 3000);

        } catch (err) {
            console.error("Error resending invite:", err);
            setMessage(err.message);
        }
    };


    // show users according to admin search query
    const filteredUsers = useMemo(() => {
        return users.filter((user) => {
            if (!searchQuery) {
                return true; // Show all users if no search query is entered
            }
            const searchTermLowerCase = searchQuery.toLowerCase();

            return (
                (user.firstName &&
                    user.firstName.toLowerCase().includes(searchTermLowerCase)) ||
                (user.lastName &&
                    user.lastName.toLowerCase().includes(searchTermLowerCase)) ||
                (user.email &&
                    user.email.toLowerCase().includes(searchTermLowerCase)) ||
                (user.program &&
                    user.program.some((program) =>
                        program.toLowerCase().includes(searchTermLowerCase)
                    )) ||
                (user.group &&
                    user.group.some((group) =>
                        group.toLowerCase().includes(searchTermLowerCase)
                    ))
            );
        });
    }, [users, searchQuery]);

    // sort users functions
    const requestSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key, direction });
    };

    const sortedAndFilteredUsers = useMemo(() => {
        let sortableUsers = [...filteredUsers];
        if (sortConfig.key !== null) {
            sortableUsers.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableUsers;
    }, [filteredUsers, sortConfig]);

    // ---  pagination for users table --- //
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const indexOfLastUser = currentPage * itemsPerPage;
    const indexOfFirstUser = indexOfLastUser - itemsPerPage;
    const currentUsers = sortedAndFilteredUsers.slice(indexOfFirstUser, indexOfLastUser);
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // handle search query change
    const handleChange = (e) => {
        const { value } = e.target;
        setSearchQuery(value);
    };

    const handleTeacherFileSelect = (e) => {
        const { value } = e.target;
        setSelectedTeacherFile(value);
    };

    const handleTeacherFileUpload = async (e) => {
        e.preventDefault();
        if (!selectedTeacherFile) {
            return alert("Please select a file and try again");
        }
        const fileRef = ref(
            storage,
            `${tenantId}/studentLists/pending/${selectedTeacherFile}`
        );
        setShowSpinner(true);

        try {
            const url = await getDownloadURL(fileRef);
            const response = await fetch(url);

            if (!response.ok) throw new Error("Error downloading file");
            const fileContent = await response.blob();
            const file = new File([fileContent], selectedTeacherFile, {
                type: "text/csv",
            });

            // Check if the file is a CSV
            if (file && file.type === "text/csv") {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const csvString = e.target.result;
                    createUsersFromCSV(csvString, selectedTeacherFile);
                };
                reader.readAsText(file);
            } else {
                console.log("file type is not .csv", file.type);
                alert("Please upload a CSV file");
            }
        } catch (err) {
            console.log(err);
        } finally {
            setShowSpinner(false);
        }
    };

    //  --- PROGRAMS FUNCTIONS --- //

    // open and close states for program modals
    const handleProgramClose = () => setOpenProgramModal(false);
    const handleGeneralPageClose = () => setOpenGeneralPageModal(false);
    const handleVideosPageClose = () => setOpenVideosPageModal(false);
    const handleQuestionnairesPageClose = () =>
        setOpenQuestionnairesPageModal(false);
    const handleQuizzesPageClose = () => setOpenQuizzesPageModal(false);
    const handleExtraInfoClose = () => {
        setOpenExtraInfoModal(false);
        setSelectedProgram("");
    }

    // Modals for creating and updating programs
    const openCreateProgramModal = () => {
        setProgramModalMode("create");
        setSelectedProgram(""); // Clear the selected program when opening in create mode
        setOpenProgramModal(true);
        setOldProgramName("");
    };
    const openUpdateProgramModal = (program) => {
        setProgramModalMode("update");
        setSelectedProgram(program); // Set the selected program when opening in update mode
        setOldProgramName(program.name);
        setOpenProgramModal(true);
    };
    const openUpdateGeneralPageModal = (program) => {
        setSelectedProgram(program);
        setOpenGeneralPageModal(true);
        setOldProgramName(program.name);
    };
    const openUpdateVideosPageModal = (program) => {
        setSelectedProgram(program);
        setOpenVideosPageModal(true);
        setOldProgramName(program.name);
    };
    const openUpdateQuestionnairesPageModal = (program) => {
        setSelectedProgram(program);
        setOpenQuestionnairesPageModal(true);
        setOldProgramName(program.name);
    };
    const handleCreateNewQuiz = () => {
        const newQuiz = {
            id: generateQuizId(), // Assign a new unique ID
            title: "",
            pages: [],
            // other properties
        };
        setSelectedQuiz(newQuiz);
        // add new quiz to quizzes array
        const updatedQuizzes = [...selectedProgram.quizzes, newQuiz];
        setSelectedProgram((prev) => ({ ...prev, quizzes: updatedQuizzes }));
        // open modal
        setOpenQuizzesPageModal(true);
        setOldProgramName(selectedProgram.name);
    };
    const openUpdateQuizzesPageModal = (quiz) => {
        setSelectedQuiz(quiz);
        setOpenQuizzesPageModal(true);
    };
    const openUpdateExtraInfoModal = (program) => {
        setSelectedProgram(program);
        setSelectedGroup('');
        setOpenExtraInfoModal(true);
        setOldProgramName(program.name);
    };

    // create, update and delete programs in firestore
    const createProgramInDb = async (e) => {
        e.preventDefault();

        // check if all fields are filled
        if (!newProgram.name) {
            setProgramError({ nameError: "Please enter a program name" });
            return;
        }
        if (!newProgram.description) {
            setProgramError({
                descriptionError: "Please enter a program description",
            });
            return;
        }
        setShowSpinner(true);

        // check if user has tenanId
        const user = auth.currentUser;
        try {
            const token = await user.getIdTokenResult();
            const claims = token.claims;
            if (!claims.tenantId) {
                setMessage(
                    "You do not have the right credentials. Please contact your administrator."
                );
                return;
            }
        } catch (err) {
            console.log(err);
        }

        try {

            const messageWithSpinner = <span>Creating program... <i className={spinner}></i> </span>
            setMessage(messageWithSpinner);
            const newProgramFromDB = await createProgram({
                name: newProgram.name,
                description: newProgram.description,
                type: newProgram.type,
            });

            // console.log('newProgramFromDB, Function response:', newProgramFromDB)
            setMessage(`Program ${newProgramFromDB.name} created successfully.`);
            setForceUpdate((prev) => !prev);
            handleProgramClose();
        } catch (error) {
            console.error(
                "Error creating program:",
                error.code,
                error.message,
                error.details
            );
            setMessage(
                `Error: cannot create program ${newProgram.name}:` + error.message
            );
            alert(`Error: cannot create program ${newProgram.name}:` + error.message);
        } finally {
            setMessage("");
            setShowSpinner(false);
        }
    };

    const updateProgramInDb = async (e) => {
        if (e) {
            e.preventDefault();
        }
        // check if all fields are filled
        if (!selectedProgram.name) {
            setError({ nameError: "Please enter a program name" });
            return;
        }
        if (!selectedProgram.description) {
            setError({ descriptionError: "Please enter a program description" });
            return;
        }
        // if (!selectedProgram.type) {
        //     setError({ typeError: 'Please enter a program type' });
        //     return;
        // }
        // check if user has tenanId
        const user = auth.currentUser;
        try {
            const token = await user.getIdTokenResult();
            const claims = token.claims;
            if (!claims.tenantId) {
                setMessage(
                    "You do not have the right credentials. Please contact your administrator."
                );
                return;
            }
        } catch (err) {
            console.log("error getting claims token or user has no tenantId", err);
        }

        // Update program in firestore
        setShowSpinner(true);
        try {
            const messageWithSpinner = <span>Updating program... <i className={spinner}></i> </span>
            setMessage(messageWithSpinner);

            await updateProgram({
                uid: selectedProgram.uid,
                oldName: oldProgramName,
                name: selectedProgram.name,
                description: selectedProgram.description,
                type: selectedProgram.type,
                generalPage: selectedProgram.generalPage || {},
                videos: selectedProgram.videos || [],
                quizzes: selectedProgram.quizzes || [],
                questionnaires: selectedProgram.questionnaires || [],
            });

            setMessage(`Program ${selectedProgram.name} updated successfully.`);
            setForceUpdate((prev) => !prev);
            setSelectedQuiz("");

            if (selectedProgram.generalPage) {
                // check if open and if open close it:
                if (openGeneralPageModal) {
                    handleGeneralPageClose();
                }
            }
            // check if other modal was open and close it:
            if (openProgramModal) {
                handleProgramClose();
            }
            if (openVideosPageModal) {
                handleVideosPageClose();
            }
            if (openQuestionnairesPageModal) {
                handleQuestionnairesPageClose();
            }
        } catch (error) {
            console.error("Error updating program:", error);
            setMessage(
                `Error: cannot update program ${selectedProgram.name}:` + error.message
            );
        } finally {
            setMessage("");
            setShowSpinner(false);
        }
    };

    const deleteProgramInDb = async (e, program) => {
        e.preventDefault();

        if (!program || !program.name || !program.uid) {
            setError({ nameError: "Please choose a program to delete" });
            return;
        }
        // warn user before deleting
        const confirmDelete = window.confirm(
            `Are you sure you want to delete program "${program.name}"? This will erase ALL data related to this program! This action cannot be undone.`
        );
        if (!confirmDelete) return;

        setShowSpinner(true);
        const messageWithSpinner = <span>Deleting program... <i className={spinner}></i> </span>
        setMessage(messageWithSpinner);

        // Delete program from Firebase firestore
        try {
            await deleteProgram({
                uid: program.uid,
                name: program.name,
            });

            setMessage(`Program ${program.name} deleted successfully.`);
            handleProgramClose();
            setForceUpdate((prev) => !prev);
        } catch (error) {
            console.error("Error deleting program:", error);
            setMessage(
                `Error: cannot delete program ${program.name}:` + error.message
            );
        } finally {
            setMessage("");
            setShowSpinner(false);
        }
    };

    //  --- VIDEOS PAGE FUNCTIONS---  //

    // Function to handle adding a new video
    const addVideoBlock = (e) => {
        e.preventDefault();
        const newVideoObject = {
            id: nanoid(),
            title: "",
            url: ""
        };

        setSelectedProgram((prevSelectedProgram) => {
            // Check if the 'videos' property exists and is an array, if not initialize as an empty array
            const updatedVideos = Array.isArray(prevSelectedProgram.videos)
                ? [...prevSelectedProgram.videos, newVideoObject]
                : [newVideoObject];

            // Now return the updated selectedProgram object
            return {
                ...prevSelectedProgram,
                videos: updatedVideos,
            };
        });
    };

    // Function to update an existing video block
    const updateVideoBlock = (index, field, value) => {
        setSelectedProgram((prevSelectedProgram) => {
            // Make a shallow copy of the videos array
            const updatedVideos = [...prevSelectedProgram.videos];
            // Update the specific block's field with the new value
            updatedVideos[index] = { ...updatedVideos[index], [field]: value };

            // Now return the updated selectedProgram object with the updated videos array
            return {
                ...prevSelectedProgram,
                videos: updatedVideos,
            };
        });
    };

    // Function to delete a video block
    const deleteVideoBlock = (index) => {
        // confirm deletion
        const confirmDelete = window.confirm("Are you sure you want to delete this video? This action cannot be undone.");
        if (!confirmDelete) return;

        setSelectedProgram((prevSelectedProgram) => {
            // shallow copy of the videos array
            const updatedVideos = [...prevSelectedProgram.videos];
            // remove the block at the specified index
            updatedVideos.splice(index, 1);

            // return the updated selectedProgram object
            return {
                ...prevSelectedProgram,
                videos: updatedVideos,
            };
        });
    };

    //  --- QUESTIONAIRES PAGE ---  //
    // Function to handle adding a new questionnaire
    const addQuestionnaireBlock = (e) => {
        e.preventDefault();
        const newQuestionnaire = {
            id: nanoid(),
            title: "",
            url: "",
        };

        setSelectedProgram((prevSelectedProgram) => {
            // Check if the 'questionnaires' property exists and is an array, if not initialize as an empty array
            const updatedQuestionnaires = Array.isArray(
                prevSelectedProgram.questionnaires
            )
                ? [...prevSelectedProgram.questionnaires, newQuestionnaire]
                : [newQuestionnaire];

            // Now return the updated selectedProgram object
            return {
                ...prevSelectedProgram,
                questionnaires: updatedQuestionnaires,
            };
        });
    };
    // Function to update an existing questionnaire block
    const updateQuestionnaireBlock = (index, field, value) => {
        setSelectedProgram((prevSelectedProgram) => {
            // Make a shallow copy of the questionnaires array
            const updatedQuestionnaires = [...prevSelectedProgram.questionnaires];
            // Update the specific block's field with the new value
            updatedQuestionnaires[index] = {
                ...updatedQuestionnaires[index],
                [field]: value,
            };

            // Now return the updated selectedProgram object with the updated questionnaires array
            return {
                ...prevSelectedProgram,
                questionnaires: updatedQuestionnaires,
            };
        });
    };
    // Function to delete a questionnaire block
    const deleteQuestionnaireBlock = (index) => {
        setSelectedProgram((prevSelectedProgram) => {
            // Make a shallow copy of the questionnaires array
            const updatedQuestionnaires = [...prevSelectedProgram.questionnaires];
            // Remove the block at the specified index
            updatedQuestionnaires.splice(index, 1);

            // Now return the updated selectedProgram object
            return {
                ...prevSelectedProgram,
                questionnaires: updatedQuestionnaires,
            };
        });
    };

    // --- QUIZZES --- //
    // This effect runs when a quiz is saved and updates the database
    useEffect(() => {
        if (!selectedProgram || loading || !shouldUpdateDb) return;
        if (shouldUpdateDb) {
            updateProgramInDb();
            setShouldUpdateDb(false);
        }
    }, [shouldUpdateDb, selectedProgram, loading]);

    // Survey creator
    const saveMySurvey = useCallback(
        (surveyJson) => {
            if (surveyCreator && selectedQuiz && selectedQuiz.id) {
                // connect the surveyJson to the selected quiz make it into 1 object so it has the id property:
                const updatedQuiz = {
                    ...selectedQuiz, // Spread the existing selectedQuiz properties
                    ...surveyJson, // Overwrite with new values from surveyJson
                };

                // Update the state
                setSelectedProgram((prevSelectedProgram) => {
                    const updatedQuizzes = prevSelectedProgram.quizzes.map((quiz) =>
                        quiz.id === updatedQuiz.id ? updatedQuiz : quiz
                    );
                    return { ...prevSelectedProgram, quizzes: updatedQuizzes };
                });

                // close modal if it's open:
                if (openQuizzesPageModal) {
                    handleQuizzesPageClose();
                }
                setShouldUpdateDb(true); // Trigger the effect to update the database
            }
        },
        [selectedQuiz, surveyCreator, openQuizzesPageModal]
    );

    // Creates a Survey Creator Component Instance when the component is mounted/updated
    useEffect(() => {
        // Survey creator options
        const creatorOptions = {
            showLogicTab: true,
            isAutoSave: false,
            showEmbededSurveyTab: true,
            showJSONEditorTab: true,
            showTestSurveyTab: false,
        };

        // create a new survey creator instance
        const creator = new SurveyCreator(creatorOptions);
        if (selectedQuiz) {
            creator.JSON = selectedQuiz;
        }
        setSurveyCreator(creator);

        // sends the JSON to the to the saving function
        const handleSave = (json) => {
            saveMySurvey(json);
        };

        // set the saving function
        creator.saveSurveyFunc = (saveNo, callback) => {
            if (
                Object.keys(creator.JSON).length === 0 ||
                creator.JSON.pages?.length === 0
            ) {
                callback(saveNo, false); // or use some other means to indicate an error
                return;
            }

            handleSave(creator.JSON);
            // Call the callback to notify the creator that saving is completed
            callback(saveNo, true);
        };
    }, [selectedProgram, selectedQuiz]);

    // sets the program for the creation of quizzes
    function handleProgramSelect(e) {
        e.preventDefault();
        if (e.target.value === "") {
            setSelectedProgram("");
            setSelectedQuiz("");
            return;
        }
        // locate program using program name
        const chosenProgramName = e.target.value;
        const program = allPrograms.find(
            (program) => program.name === chosenProgramName
        );
        // if prorgram doesn't have quizzes, create an empty array and add it to the program
        if (!program.quizzes) {
            program.quizzes = [];
        }
        setSelectedProgram(program);
    }

    const deleteQuizFromDB = async (quiz) => {
        // warn user before deleting
        const confirmDelete = window.confirm(
            `Are you sure you want to delete quiz "${quiz.title}"? This will erase ALL data related to this quiz! This action cannot be undone.`
        );
        if (!confirmDelete) return;

        // delete quiz by only returning the quizzes that don't match the quizId
        const quizId = quiz.id;
        if (!quizId) {
            alert("Error: cannot delete quiz: please select a quiz and try again");
            return;
        }
        const updatedQuizzes = selectedProgram.quizzes.filter(
            (quiz) => quiz.id !== quizId
        );
        setSelectedProgram((prevSelectedProgram) => {
            return {
                ...prevSelectedProgram,
                quizzes: updatedQuizzes,
            };
        });
        // Update program in Firebase firestore
        setShowSpinner(true);

        try {
            setMessage(<i className={spinner}></i>);
            await updateProgram({
                uid: selectedProgram.uid,
                name: selectedProgram.name,
                description: selectedProgram.description,
                type: selectedProgram.type,
                generalPage: selectedProgram.generalPage,
                videos: selectedProgram.videos,
                quizzes: updatedQuizzes,
                questionnaires: selectedProgram.questionnaires,
            });
            setForceUpdate((prev) => !prev);
            handleQuizzesPageClose();
        } catch (error) {
            alert(`Error: cannot delete quiz ${quiz.title}:` + error.message);
        } finally {
            setMessage("");
            setShowSpinner(false);
        }
    };

    // generate a unique ID (simple version)
    const generateQuizId = () => {
        return "_" + Math.random().toString(36).substring(2, 11);
    };

    // --- GENERAL PAGE --- //
    // handle adding a new content block
    const addContentBlock = (e) => {
        e.preventDefault();
        const newBlock = {
            id: nanoid(),
            title: "",
            text: "",
        };
        setSelectedProgram((prevSelectedProgram) => {
            // Check if the 'content' property exists and is an array, if not initialize as an empty array
            const updatedContent = Array.isArray(
                prevSelectedProgram.generalPage?.content
            )
                ? [...prevSelectedProgram.generalPage.content, newBlock]
                : [newBlock];

            // Now return the updated selectedProgram object
            return {
                ...prevSelectedProgram,
                generalPage: {
                    ...prevSelectedProgram.generalPage,
                    content: updatedContent,
                },
            };
        });
    };
    // update an existing content block
    const updateContentBlock = (index, field, value) => {
        setSelectedProgram((prevSelectedProgram) => {
            // Make a shallow copy of the content array
            const updatedContent = [...prevSelectedProgram.generalPage.content];
            // Update the specific block's field with the new value
            updatedContent[index] = { ...updatedContent[index], [field]: value };

            // Now return the updated selectedProgram object with the updated content array
            return {
                ...prevSelectedProgram,
                generalPage: {
                    ...prevSelectedProgram.generalPage,
                    content: updatedContent,
                },
            };
        });
    };
    // delete a content block
    const deleteContentBlock = (index) => {
        setSelectedProgram((prevSelectedProgram) => {
            // Make a shallow copy of the content array
            const updatedContent = [...prevSelectedProgram.generalPage.content];
            // Remove the block at the specified index
            updatedContent.splice(index, 1);

            // Now return the updated selectedProgram object
            return {
                ...prevSelectedProgram,
                generalPage: {
                    ...prevSelectedProgram.generalPage,
                    content: updatedContent,
                },
            };
        });
    };

    // --- QA --- //
    // add a new Q&A block
    const addQA = (e) => {
        e.preventDefault();
        const newQuestion = { question: "", answer: "", id: nanoid() };
        setSelectedProgram((prevSelectedProgram) => {
            const updatedQuestions = Array.isArray(
                prevSelectedProgram.generalPage?.questions
            )
                ? [...prevSelectedProgram.generalPage.questions, newQuestion]
                : [newQuestion];

            return {
                ...prevSelectedProgram,
                generalPage: {
                    ...prevSelectedProgram.generalPage,
                    questions: updatedQuestions,
                },
            };
        });
    };
    // update an existing Q&A block
    const updateQA = (index, field, value) => {
        setSelectedProgram((prevSelectedProgram) => {
            const updatedQuestions = [...prevSelectedProgram.generalPage.questions];
            const updatedQuestion = { ...updatedQuestions[index], [field]: value };
            updatedQuestions[index] = updatedQuestion;

            return {
                ...prevSelectedProgram,
                generalPage: {
                    ...prevSelectedProgram.generalPage,
                    questions: updatedQuestions,
                },
            };
        });
    };
    // delete an existing Q&A block
    const deleteQA = (index) => {
        setSelectedProgram((prevSelectedProgram) => {
            const updatedQuestions = [...prevSelectedProgram.generalPage.questions];
            updatedQuestions.splice(index, 1);

            return {
                ...prevSelectedProgram,
                generalPage: {
                    ...prevSelectedProgram.generalPage,
                    questions: updatedQuestions,
                },
            };
        });
    };

    // -- video blocks -- //
    // add a new video block
    const addGeneralVideoBlock = (e) => {
        e.preventDefault();
        const newVideo = { title: "", url: "", id: nanoid() };
        setSelectedProgram((prevSelectedProgram) => {
            const updatedVideos = Array.isArray(
                prevSelectedProgram.generalPage?.videos
            )
                ? [...prevSelectedProgram.generalPage.videos, newVideo]
                : [newVideo];

            return {
                ...prevSelectedProgram,
                generalPage: {
                    ...prevSelectedProgram.generalPage,
                    videos: updatedVideos,
                },
            };
        });
    };
    // update an existing video block
    const updateGeneralVideoBlock = (index, field, value) => {
        setSelectedProgram((prevSelectedProgram) => {
            const updatedVideos = [...prevSelectedProgram.generalPage.videos];
            const updatedVideo = { ...updatedVideos[index], [field]: value };
            updatedVideos[index] = updatedVideo;

            return {
                ...prevSelectedProgram,
                generalPage: {
                    ...prevSelectedProgram.generalPage,
                    videos: updatedVideos,
                },
            };
        });
    };
    // delete an existing video block
    const deleteGeneralVideoBlock = (index) => {
        setSelectedProgram((prevSelectedProgram) => {
            const updatedVideos = [...prevSelectedProgram.generalPage.videos];
            updatedVideos.splice(index, 1);

            return {
                ...prevSelectedProgram,
                generalPage: {
                    ...prevSelectedProgram.generalPage,
                    videos: updatedVideos,
                },
            };
        });
    };

    // --- EXTRA INFO PAGE --- //

    useEffect(() => {
        if (loading || !tenantId || !selectedProgram) return;
        fetchUploadedFilesFromStorage();
    }, [loading, tenantId, selectedProgram, selectedGroup]);

    // load the existing PDFs files for the selected program from storage when the component mounts + when the selected program changes

    // get the reference to the folder in storage
    const fetchUploadedFilesFromStorage = async () => {
        if (loading || !tenantId || !selectedProgram || !selectedGroup) return;
        const programName = selectedProgram.name;
        const folderRef = ref(storage, `${tenantId}/extra/${programName}/${selectedGroup}`);

        if (!folderRef) {
            console.error("Error fetching files from storage: folder reference is null");
            return;
        }
        // if folder is empty
        if (folderRef === null) {
            console.log("folder is empty");
            return;
        }
        setShowSpinner(true);

        try {
            const files = await listAll(folderRef);
            if (!files) {
                throw new Error("Error fetching files from storage");
            }
            // Create a promise for each file to get its metadata
            const metadataPromises = files.items.map((fileRef) => getMetadata(fileRef));

            // Resolve all promises to get an array of metadata objects
            const metadataArray = await Promise.all(metadataPromises);

            // Transform the metadata to the desired structure for state, including a thumbnail
            const uploadedFilesData = await Promise.all(metadataArray.map(async (metadata) => {
                const url = await getDownloadURL(ref(storage, metadata.fullPath));
                console.log("PDF URL:", url);
                // Create thumbnail using react-pdf Document and Page components
                const thumbnail = (
                    <Document file={url}>
                        <Page pageNumber={1} height={300} />
                    </Document>
                );

                return {
                    name: metadata.name,
                    size: metadata.size, // The size might need formatting
                    fullPath: metadata.fullPath, // Useful for downloading or referencing
                    contentType: metadata.contentType,
                    timeCreated: metadata.timeCreated,
                    url: url,
                    thumbnail: thumbnail, // Add thumbnail preview
                    id: nanoid() // temporary id for mapping
                };
            }));

            // Update the state with the file data
            setUploadedFiles(uploadedFilesData);
        } catch (error) {
            console.error(
                "Error fetching files from storage:",
                error.message || error
            );
        } finally {
            setShowSpinner(false);
        }
    };

    // pdf file upload logic
    const handlePDFSelection = (event) => {
        const input = event.target;
        const selectedFiles = Array.from(input.files);
        const newPDFs = selectedFiles.filter((file) => file.type === "application/pdf");

        if (selectedFiles.length !== newPDFs.length) {
            alert("Some files were not PDFs and have not been added.");
        }
        //  add id to each file using nanoid: file.id
        const newPDFsWithId = newPDFs.map((file) => {
            return { file, id: nanoid() };
        });

        // Update the state with the new files showing the user the files they have selected
        setFiles((currentFiles) => [...currentFiles, ...newPDFsWithId]);

        handlePDFFileUpload(newPDFsWithId);
        // Clear the input value to allow the same file to be uploaded again
        input.value = null;
    };

    const handlePDFFileUpload = async (newPDFsWithId) => {
        if (
            loading ||
            !tenantId ||
            !files ||
            !group ||
            !program ||
            !selectedGroup ||
            !selectedProgram
        ) {
            alert("You must select a file");
            return;
        }

        setShowSpinner(true);
        setMessage("Uploading files...");

        // Create an array of promises for each file upload
        const uploadPromises = newPDFsWithId.map(async ({ file, id }) => {
            const programName = selectedProgram.name;
            const newFileName = `${file.name}`;
            const storageRef = ref(
                storage,
                `${tenantId}/extra/${programName}/${selectedGroup}/${newFileName}`
            );

            try {
                const snapshot = await uploadBytes(storageRef, file);
                if (!snapshot) {
                    throw new Error("Error uploading file");
                }
                return {
                    name: newFileName,
                    size: file.size,
                    fullPath: snapshot.ref.fullPath,
                    contentType: file.type,
                    timeCreated: snapshot.metadata.timeCreated,
                    id,
                };

            } catch (error) {
                console.error("Error during file upload:", error.message || error);
                return null;
            }
        });
        try {
            const uploadedFileNames = await Promise.all(uploadPromises);
            const successfulUploads = uploadedFileNames.filter(Boolean); // Remove nulls from the array

            if (successfulUploads.length === files.length) {
                setMessage("All files uploaded successfully");
                setSeverity("success");
            } else {
                setMessage(
                    `${successfulUploads.length} out of ${files.length} files uploaded successfully`
                );
                setSeverity("warning");
            }
            // Update the state with the uploaded files
            // Refetch the uploaded files to update the state
            await fetchUploadedFilesFromStorage();

        } catch {
            setMessage("An error occurred during the upload. Please try again.");
            setSeverity("error");
        } finally {
            setFiles([]); // Clear the files array
            setShowSpinner(false);

            // Hide the message after a delay
            setTimeout(() => {
                setMessage("");
            }, 5000);

        }
    };

    const handleRemovePDFFromState = (index) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };

    const handleEraseUploadedPDF = async (uploadedFile) => {
        if (!uploadedFile) {
            alert("Please select a file and try again");
            return;
        }
        // erase the file from storage
        const confirmDelete = window.confirm(
            `Are you sure you want to delete file "${uploadedFile}"? This action cannot be undone.`
        );
        if (!confirmDelete) return;

        setShowSpinner(true);
        setMessage("Deleting file...");
        const programName = selectedProgram.name;
        const storageRef = ref(storage, `${tenantId}/extra/${programName}/${selectedGroup}/${uploadedFile}`);

        try {
            // delete the file from storage
            await deleteObject(storageRef);
            // delete the file from the uploadedFiles state
            const updatedFiles = uploadedFiles.filter((file) => file.name !== uploadedFile);
            setUploadedFiles(updatedFiles);
        } catch (error) {
            console.error("Error deleting file:", error.message || error);
            setMessage(
                `Error deleting file "${uploadedFile}": ${error.message || error}`
            );
            setSeverity("error");
        } finally {
            setShowSpinner(false);
            setTimeout(() => {
                setMessage("");
            }, 5000);
        }
    };

    // --- GROUPS --- //

    // add state for group sorting
    const [sortGroupConfig, setSortGroupConfig] = useState({
        key: null,
        direction: 'ascending'
    });

    const calculateNumberOfStudents = (group) => {
        if (!group || !group.name) return 0;
        const students = users.filter((user) => user.group[0] === group.name);
        return students.length;
    };

    const filteredGroups = allGroups.filter((group) => {
        if (!groupSearchQuery) {
            return true; // Show all groups if no search query is entered
        }

        const searchTermLowerCase = groupSearchQuery.toLowerCase();

        // First ensure the group exists
        if (!group) return false;

        return (
            (group.name && group.name.toLowerCase().includes(searchTermLowerCase)) ||
            (group.program && group.program.toLowerCase().includes(searchTermLowerCase)) ||
            (group.contactName && group.contactName.toLowerCase().includes(searchTermLowerCase)) ||
            (group.contactNum && group.contactNum.toLowerCase().includes(searchTermLowerCase)) ||
            (group.schoolContactName && group.schoolContactName.toLowerCase().includes(searchTermLowerCase)) ||
            (calculateNumberOfStudents(group) > 0 &&
                calculateNumberOfStudents(group).toString().includes(searchTermLowerCase))
        );
    });

    const handleGroupSearchChange = (e) => {
        const { value } = e.target;
        setGroupSearchQuery(value);
    };

    // --- GROUP MODALS --- //
    const handleGroupClose = () => setOpenGroupModal(false);
    const handleAddStudentsClose = () => setOpenAddStudentsModal(false);

    // set group schedule to state
    useEffect(() => {
        if (loading) return;

        if (groupModalMode === "update" && selectedGroup) {
            if (selectedGroup.schedule) {
                setSessions(selectedGroup.schedule);
            }
        } else if (groupModalMode === "create") {
            // add topic to each session by mapping over the topics array and adding it to each sessions by the index
            const updatedSessions = sessions.map((session, index) => {
                return { ...session, topic: isCK ? ckTopics[index] : topics[index] };
            });
            setSessions(updatedSessions);
        }
    }, [openGroupModal, selectedGroup, loading]);

    // add row to schedule table
    const addRow = (e) => {
        e.preventDefault();
        const newRow = {
            sessionId: nanoid(),
            sessionNum: "",
            topic: "",
            date: "",
            time: "",
            endTime: "",
            location: "",
            notes: "",
            questionnaire: "",
            completed: false,
        };
        setSessions([...sessions, newRow]);
    };

    // delete row in schedule table
    const deleteRow = (e, index) => {
        e.preventDefault();
        const updatedSessions = [...sessions];
        updatedSessions.splice(index, 1);
        setSessions(updatedSessions);
    };

    const openCreateGroupModal = () => {
        setGroupModalMode("create");
        setSelectedGroup(""); // Clear the selected group when opening in create mode
        setSessions(emptySessions);
        const todayFormatted = dayjs().format("DD-MM-YYYY");
        setNewGroup({
            name: "",
            startDate: todayFormatted,
            endDate: "",
            program: "",
            contactName: "",
            contactNum: "",
            schoolContactName: "",
            schoolContactNum: "",
            schedule: emptySessions,
        });
        setOpenGroupModal(true);
    };

    // based on selectedgroup, set the selected program + open the modal + set the old group name
    const openUpdateGroupModal = (group) => {
        console.log("group in update group modal", group);
        const program = allPrograms.find(
            (program) => program.name === group.program
        );
        setSelectedProgram(program);
        setGroupModalMode("update");
        setSelectedGroup(group); // Set the selected group when opening in update mode
        setOpenGroupModal(true);
        setOldGroupName(group.name);
    };

    const handleAddStudentsModal = (group) => {
        setSelectedGroup(group);
        setStudentsCSV(null);
        setOpenAddStudentsModal(true);
        setMessage("");
        // console.log("selectedGroup.name", selectedGroup.name);
        // console.log("selectedGroup.program", selectedGroup.program);
    };

    const handleStudentsCSVSelection = (event) => {
        const { files } = event.target;
        if (!files || files.length === 0) {
            setMessage("Please select a file and try again");
            alert("Please select a file and try again");
            return;
        }
        const file = files[0];
        if (!file) {
            alert("Please select a file");
            setStudentsCSV(null);
            return;
        }
        // Check if the file is a CSV
        if (file && file.type === "text/csv") {
            const reader = new FileReader();
            reader.onload = (e) => {
                const csvString = e.target.result;
                setStudentsCSV(csvString);
            };
            reader.readAsText(file);
        } else {
            alert("File selected is not a CSV file. Please only upload a CSV file");
        }

        setMessage(`Selected file: ${file.name}`);
    };

    const addStudentsToGroup = async (e) => {
        e.preventDefault();
        if (!studentsCSV) {
            alert("Please select a file");
            return;
        }

        // console.log("studentsCSV", studentsCSV);
        // console.log("selectedGroup.name", selectedGroup.name);
        // console.log("selectedGroup.program", selectedGroup.program);

        // parsing the csv file
        const { data: parsedData, errors } = Papa.parse(studentsCSV, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
            transformHeader: (header) => header.trim(),
            transform: (value) => {
                if (typeof value === "string") {
                    // Trim the value and convert empty strings to null
                    value = value.trim();
                    return value === "" ? null : value;
                }
                return value;
            },
            delimiter: '',  // Auto-detect delimiter
            encoding: "UTF-8",
            newline: '\r\n',
        });
        const skippedItems = [];

        if (errors.length > 0) {
            alert("Error uploading the CSV file");
            setMessage("");
            return;
        }
        // Check if the required headers exist
        const requiredHeaders = ["firstName", "lastName"];
        const csvHeaders = Object.keys(parsedData[0]);
        const missingHeaders = requiredHeaders.filter((header) => !csvHeaders.includes(header));

        if (missingHeaders.length > 0) {
            setMessage("");
            alert(`Missing required headers in the CSV file: ${missingHeaders.join(", ")}, please check the file and try again`);
            return;
        }

        if (!selectedGroup) {
            alert("Please select a group to add students to");
            setMessage("");
            return;
        }
        if (!selectedGroup.program) {
            alert("Please select a program for the group");
            setMessage("");
            return;
        }
        if (!selectedGroup.name) {
            alert("Please select a group name");
            setMessage("");
            return;
        }
        if (!parsedData || parsedData.length === 0) {
            alert("No data found in the CSV file");
            setMessage("");
            return;
        }

        const messageWithSpinner = <span>Uploading students...<i className={spinner}></i> </span>
        setMessage(messageWithSpinner);

        try {
            for (const item of parsedData) {
                if (!item.firstName || !item.lastName) {
                    skippedItems.push(item);
                    continue; // Skip this iteration and move to the next line
                }
                let chosenProgramName = selectedGroup.program;
                let chosenGroupName = selectedGroup.name;

                if (!Array.isArray(chosenProgramName)) chosenProgramName = [chosenProgramName]
                if (!Array.isArray(chosenGroupName)) chosenGroupName = [chosenGroupName];

                // take groupname and remove spaces and trim
                const sanitizedGroupName = chosenGroupName[0].toLowerCase().trim().replace(/\s+/g, '');
                // Replace spaces in the first name and last name with a period or remove them
                const sanitizedFirstName = item.firstName.toLowerCase().trim().replace(/\s+/g, '');
                const sanitizedLastName = item.lastName.toLowerCase().trim().replace(/\s+/g, '');

                // Generate a fake email using first name, last name, and a domain
                const fakeEmail = `${sanitizedFirstName}.${sanitizedLastName}.${sanitizedGroupName}@disabled.example.com`;

                // creates users in auth and db
                const newUser = await createAppUser({
                    email: fakeEmail,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    program: chosenProgramName,
                    group: chosenGroupName,
                    role: "user",
                    shouldSendEmail: false,
                    tenantName: tenantName,
                });

                if (newUser.error) continue; // Skip further processing for this user

                if (skippedItems.length > 0) {
                    const skippedItemsList = skippedItems.map((item) => {
                        return `- First Name: ${item.firstName}, Last Name: ${item.lastName}`;
                    }).join("\n");
                    alert(
                        `Skipped ${skippedItems.length} invalid line(s) in CSV file. Please check the following items:\n\n${skippedItemsList}`
                    );
                }
            }

            alert("Success: students added to the group successfully");
            // Trigger re-render to fetch latest user data
            setForceUpdate((prev) => !prev);

            // Clear message and close modal after a delay
            setMessage("");
            handleAddStudentsClose();

        } catch (error) {
            console.error("Error uploading students:", error);
            alert(`Error uploading students: ${error.message || error}`);
        }

    };

    // adding group to db ("create group" button)
    const createGroupInDB = async (e) => {
        e.preventDefault();
        // check if all fields are filled
        if (!newGroup.name) {
            setGroupError({ nameError: "Please enter a group name" });
            return;
        }
        if (!newGroup.startDate) {
            setGroupError({ startDateError: "Please enter a start date" });
            return;
        }
        if (!newGroup.endDate) {
            setGroupError({ endDateError: "Please enter an end date" });
            return;
        }
        if (!newGroup.program) {
            setGroupError({ programError: "Please enter a program" });
            return;
        }
        if (!newGroup.contactName) {
            setGroupError({ contactNameError: "Please enter a contact name" });
            return;
        }
        if (!newGroup.contactNum) {
            setGroupError({ contactNumError: "Please enter a contact number" });
            return;
        }
        if (!newGroup.schoolContactName) {
            setGroupError({
                schoolContactNameError: "Please enter a school contact name",
            });
            return;
        }
        if (!newGroup.schoolContactNum) {
            setGroupError({
                schoolContactNumError: "Please enter a school contact number",
            });
            return;
        }
        if (!sessions) {
            setGroupError({ sessionsError: "Please enter a schedule" });
            return;
        }
        // check if user has tenanId
        const user = auth.currentUser;
        try {
            const token = await user.getIdTokenResult();
            const claims = token.claims;
            if (!claims.tenantId) {
                setMessage(
                    "You do not have the right credentials. Please contact your administrator."
                );
                return;
            }
        } catch (err) {
            console.log("error getting claims token or user has no tenantId", err);
        }

        const updatedSchedule = sessions.filter((session) => session.topic !== "");
        setSelectedGroup({ ...selectedGroup, schedule: updatedSchedule });
        setShowSpinner(true);

        // trim spaces from group name:
        newGroup.name = newGroup.name.trim();

        try {
            const messageWithSpinner = <span>Creating group {newGroup.name}... <i className={spinner}></i> </span>
            setMessage(messageWithSpinner);

            // Create group in Firebase firestore
            await createGroup({
                // uid: newGroup.name, removed uid as it is auto generated by firebase
                name: newGroup.name,
                startDate: newGroup.startDate,
                endDate: newGroup.endDate,
                program: newGroup.program,
                contactName: newGroup.contactName,
                contactNum: newGroup.contactNum,
                schoolContactName: newGroup.schoolContactName,
                schoolContactNum: newGroup.schoolContactNum,
                schedule: updatedSchedule,
            });
            // console.log("newGroup created in db:", newGroup);

            handleGroupClose();
            setForceUpdate((prev) => !prev);
        } catch (error) {
            console.error("Error creating group:", error, "Code:", error.code, "Message:", error.message);
            setMessage(`Error: cannot create group ${newGroup.name}:` + error.message);
            alert(`Error: cannot create group ${newGroup.name}:` + error.message);
        } finally {
            setMessage("");
            handleGroupClose();
            setShowSpinner(false);
        }
    };

    const updateGroupInDB = async (e) => {
        e.preventDefault();
        // check if all fields are filled
        if (!selectedGroup.uid || !selectedGroup.name) {
            setGroupError({ nameError: "Please select group" });
            return;
        }
        if (!selectedGroup.startDate) {
            setGroupError({ startDateError: "Please enter a start date" });
            return;
        }
        if (!selectedGroup.endDate) {
            setGroupError({ endDateError: "Please enter an end date" });
            return;
        }
        if (!selectedGroup.program) {
            setGroupError({ programError: "Please enter a program" });
            return;
        }
        if (!selectedGroup.contactName) {
            setGroupError({ contactNameError: "Please enter a contact name" });
            return;
        }
        if (!selectedGroup.contactNum) {
            setGroupError({ contactNumError: "Please enter a contact number" });
            return;
        }
        if (!selectedGroup.schoolContactName) {
            setGroupError({
                schoolContactNameError: "Please enter a school contact name",
            });
            return;
        }
        if (!selectedGroup.schoolContactNum) {
            setGroupError({
                schoolContactNumError: "Please enter a school contact number",
            });
            return;
        }
        if (!sessions) {
            setGroupError({ sessionsError: "Please enter a schedule" });
            return;
        }
        // check if user has tenanId
        const user = auth.currentUser;
        try {
            const token = await user.getIdTokenResult();
            const claims = token.claims;
            if (!claims.tenantId) {
                setMessage(
                    "You do not have the right credentials. Please contact your administrator."
                );
                return;
            }
        } catch (err) {
            console.log("error getting claims token or user has no tenantId", err);
        }

        const updatedSchedule = sessions.filter((session) => session.topic !== "");
        setSelectedGroup({ ...selectedGroup, schedule: updatedSchedule });
        setShowSpinner(true);

        // trim spaces from group name:
        selectedGroup.name = selectedGroup.name.trim();

        try {
            const messageWithSpinner = <span>Updating group... <i className={spinner}></i> </span>
            setMessage(messageWithSpinner);

            const updatedGroup = await updateGroup({
                uid: selectedGroup.uid,
                name: selectedGroup.name,
                oldName: oldGroupName,
                program: selectedGroup.program,
                startDate: selectedGroup.startDate,
                endDate: selectedGroup.endDate,
                contactName: selectedGroup.contactName,
                contactNum: selectedGroup.contactNum,
                schoolContactName: selectedGroup.schoolContactName,
                schoolContactNum: selectedGroup.schoolContactNum,
                schedule: updatedSchedule,
            });
            console.log("updatedGroup", updatedGroup);
            setForceUpdate((prev) => !prev);
            handleGroupClose();
        } catch (error) {
            console.error("Error updating group:", error);
            setMessage(error);
            handleGroupClose();
        } finally {
            setMessage("");
            setShowSpinner(false);
        }
    };

    const deleteGroupInDB = async (e, group) => {
        e.preventDefault();

        if (!group.uid) {
            setError({ nameError: "Please choose a group to delete" });
            return;
        }
        // warn user before deleting
        const confirmDelete = window.confirm(
            `Are you sure you want to delete ${group.name}? All of the group information, including the students data will be lost. This action cannot be undone. We recommend exporting the group data before deleting.`
        );
        if (!confirmDelete) return;

        const groupId = group.uid;
        const groupName = group.name;

        setShowSpinner(true);
        try {
            const messageWithSpinner = <span>Deleting group... <i className={spinner}></i> </span>
            setMessage(messageWithSpinner);
            // Delete group from Firebase firestore
            await deleteGroup({
                uid: groupId,
                name: groupName,
            });

            setMessage(`Group ${group.name} deleted successfully.`);
            handleGroupClose();
            setForceUpdate((prev) => !prev);
        } catch (error) {
            console.error("Error deleting group:", error);
            setMessage(`Error: cannot delete group ${group.name}:` + error.message);
        } finally {
            setMessage("");
            setShowSpinner(false);
        }
    };

    // --- CSV GROUP GENERATION --- //
    function sortStudentsByName(students) {
        return students.sort((a, b) => a.firstName.localeCompare(b.firstName));
    }

    function sortSessionsByNumber(sessions) {
        return sessions.sort((a, b) => a.sessionNum - b.sessionNum);
    }

    // fetch students in the group for the CSV
    const fetchStudents = async (group) => {
        const selectedGroup = group;
        try {
            const usersRef = collection(db, "tenants", tenantId, "users");
            const groupQuery = query(
                usersRef,
                where("group", "array-contains", selectedGroup)
            );
            const querySnapshot = await getDocs(groupQuery);

            const students = querySnapshot.docs.map((doc) => doc.data());

            // filter users who are teachers or admins:
            const studentsInGroupFiltered = students.filter(
                (student) => student.role === "user"
            );
            setSelectedGroupStudents(studentsInGroupFiltered);
            return studentsInGroupFiltered;
        } catch (error) {
            console.log("error", error);
        }
    };
    // fetch sessions in the group for the CSV
    const fetchSessions = async (group) => {
        try {
            const groupScheduleRef = collection(db, "tenants", tenantId, "groups");
            const groupQuery = query(groupScheduleRef, where("name", "==", group));
            const groupSnapshot = await getDocs(groupQuery);
            const groupsData = groupSnapshot.docs.map((doc) => doc.data());
            const groupSessions = groupsData[0].schedule;

            setSelectedGroupSessions(groupSessions);
            return groupSessions;
        } catch (error) {
            console.log("error", error);
        }
    };
    // generate CSV file
    async function generateGroupCSV(e, group) {
        e.preventDefault();

        // get list of students:
        try {
            const studentsInGroupFiltered = await fetchStudents(group.name);

            //prepare array of just the students name using SelectedGroupStudents.displayName:
            const students = studentsInGroupFiltered.map((student) => {
                return {
                    firstName: student.firstName,
                    lastName: student.lastName,
                };
            });

            // sort students by name:
            const sortedStudents = sortStudentsByName(students);
            // get list of sessions:
            const groupSessions = await fetchSessions(group.name);

            // sort sessions by session number:
            const sortedSessions = sortSessionsByNumber(groupSessions);

            // first row of headers - group info:
            let csvContent = `Group Name, Start Date, End Date, Program, Contact Name, Contact Number, School Contact Name, School Contact Number\n`;
            // add group info under headers:
            csvContent += `${group.name}, ${group.startDate}, ${group.endDate}, ${group.program}, ${group.contactName}, ${group.contactNum}, ${group.schoolContactName}, ${group.schoolContactNum}\n`;
            // add empty row between group info and session headers with the title of Attendance:
            csvContent += "\nAttendance\n";
            // Add session headers
            csvContent +=
                "Student Name," +
                sortedSessions
                    .map((session) => `Session ${session.sessionNum} (${session.date})`)
                    .join(",") +
                "\n";
            // Add student attendance. in each session there's an attendance array with the students who attended the session.
            // each item in the array is an object with the student name and the type of attendance (present/absent) for attendance.

            sortedStudents.forEach((student) => {
                csvContent +=
                    `${student.firstName} ${student.lastName},` +
                    sortedSessions
                        .map((session) => {
                            if (!session.attendance) return "";
                            const attendance = session.attendance.find(
                                (attendance) =>
                                    attendance.studentName ===
                                    `${student.firstName} ${student.lastName}`
                            );
                            return attendance ? attendance.attendanceStatus : "";
                        })
                        .join(",") +
                    "\n";
            });

            //  Convert CSV string to Blob

            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

            // Create a link to download it
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", `${group.name}.csv`);

            // Append link to the DOM and trigger download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.log("error", error);
            alert("Error generating CSV file. Please try again.");
        }
    }

    // Add new function for Excel export
    async function generateGroupExcel(e, group) {
        e.preventDefault();

        try {
            const studentsInGroupFiltered = await fetchStudents(group.name);
            const students = studentsInGroupFiltered.map((student) => ({
                firstName: student.firstName,
                lastName: student.lastName,
            }));

            const sortedStudents = sortStudentsByName(students);
            const groupSessions = await fetchSessions(group.name);
            const sortedSessions = sortSessionsByNumber(groupSessions);

            // Create workbook and worksheet
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Group Data');

            // Add group info
            worksheet.addRow(['Group Information']);
            worksheet.addRow(['Group Name', group.name]);
            worksheet.addRow(['Start Date', group.startDate]);
            worksheet.addRow(['End Date', group.endDate]);
            worksheet.addRow(['Program', group.program]);
            worksheet.addRow(['Contact Name', group.contactName]);
            worksheet.addRow(['Contact Number', group.contactNum]);
            worksheet.addRow(['School Contact Name', group.schoolContactName]);
            worksheet.addRow(['School Contact Number', group.schoolContactNum]);

            // Add empty row
            worksheet.addRow([]);

            // Add attendance section
            worksheet.addRow(['Attendance']);

            // Add headers
            const headers = ['Student Name', ...sortedSessions.map(session => `Session ${session.sessionNum} (${session.date})`)];
            worksheet.addRow(headers);

            // Add student data
            sortedStudents.forEach((student) => {
                const row = worksheet.addRow([
                    `${student.firstName} ${student.lastName}`,
                    ...sortedSessions.map((session) => {
                        if (!session.attendance) return '';
                        const attendance = session.attendance.find(
                            (att) => att.studentName === `${student.firstName} ${student.lastName}`
                        );
                        return attendance ? attendance.attendanceStatus : '';
                    })
                ]);

                // Color the attendance cells
                for (let i = 2; i <= headers.length; i++) {
                    const cell = row.getCell(i);
                    switch (cell.value) {
                        case 'aanwezig':
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FF92D050' } // Green
                            };
                            break;
                        case 'afwezig':
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFF0000' } // Red
                            };
                            break;
                        case 'zelfstandig':
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFC000' } // Orange
                            };
                            break;
                    }
                }
            });

            // Style headers
            worksheet.getRow(1).font = { bold: true, size: 14 };
            worksheet.getRow(11).font = { bold: true, size: 14 };
            worksheet.getRow(12).font = { bold: true };

            // Auto-size columns
            worksheet.columns.forEach(column => {
                column.width = 20;
            });

            // Generate and download file
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${group.name}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.log("error", error);
            alert("Error generating Excel file. Please try again.");
        }
    }

    // Add this handler function (after calculateNumberOfStudents)
    const requestGroupSort = (key) => {
        let direction = 'ascending';
        if (sortGroupConfig.key === key && sortGroupConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortGroupConfig({ key, direction });
    };

    // Add this sort function
    const sortGroups = (groups, sortGroupConfig) => {
        if (!sortGroupConfig.key || !groups) return groups;

        return [...groups].sort((a, b) => {
            // Check if either group is missing the sort key
            if (!a[sortGroupConfig.key] || !b[sortGroupConfig.key]) {
                // Put items with missing values at the end
                if (!a[sortGroupConfig.key]) return 1;
                if (!b[sortGroupConfig.key]) return -1;
                return 0;
            }

            try {
                // Handle string comparison for name
                if (sortGroupConfig.key === 'name') {
                    if (a[sortGroupConfig.key].toLowerCase() < b[sortGroupConfig.key].toLowerCase()) {
                        return sortGroupConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortGroupConfig.key].toLowerCase() > b[sortGroupConfig.key].toLowerCase()) {
                        return sortGroupConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                }

                // Existing date comparison code
                const dateA = a[sortGroupConfig.key].split('-').reverse().join('-');
                const dateB = b[sortGroupConfig.key].split('-').reverse().join('-');

                if (dateA < dateB) {
                    return sortGroupConfig.direction === 'ascending' ? -1 : 1;
                }
                if (dateA > dateB) {
                    return sortGroupConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            } catch (error) {
                console.log(`Error sorting dates for groups: ${error}`);
                return 0; // Keep original order if there's an error
            }
        });
    };

    if (loading) return <Loading />

    return (
        <div className="page-container" style={{ scrollBehavior: "smooth" }}>
            <div className="page-title">
                <h4>Admin Panel</h4>
                <h6>Manage user accounts and update content</h6>
                {showSpinner && <Spinner />}
                <div className=" successMessage">
                    {message && <p>{message}</p>}
                </div>
            </div>
            <div className="admin-page-container">
                <Tabs
                    value={pageActiveTab}
                    onChange={handlePageTabChange}
                    aria-label="admin tabs"
                    TabIndicatorProps={{ style: { backgroundColor: "#5ABAA2", height: "3px", }, }}
                    sx={{
                        '& .MuiTabs-root': {
                        }, '& .MuiTab-root': {
                            backgroundColor: 'inherit',
                            fontSize: '16px',
                            textTransform: 'capitalize !important', // Remove uppercase
                            '&:hover': {
                                backgroundColor: 'transparent !important', // Disable hover background
                            },
                            '&.Mui-selected': {
                                color: '#333', // color of selected tab
                            },
                        },
                    }}
                >

                    <Tab sx={{ margin: '0px 10px' }} label={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span>Users</span> <Users size={20} style={{ marginLeft: '8px' }} />
                        </Box>
                    } />
                    <Tab sx={{ margin: '0px 10px' }} label={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span>Programs</span> <FileText size={20} style={{ marginLeft: '8px' }} />
                        </Box>
                    } />
                    <Tab sx={{ margin: '0px 10px' }} label={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span>Quizzes</span> <ClipboardList size={20} style={{ marginLeft: '8px' }} />
                        </Box>
                    } />
                    <Tab sx={{ margin: '0px 10px' }} label={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span>Groups</span> <Group size={20} style={{ marginLeft: '8px' }} />
                        </Box>
                    } />
                    <Tab sx={{ margin: '0px 10px' }} label={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span>Analytics</span> <BarChart2 size={20} style={{ marginLeft: '8px' }} />
                        </Box>
                    } />
                </Tabs>

                <Box sx={{ p: 3 }}>
                    {pageActiveTab === 0 && (
                        <div className="admin-container users" id="usersSection">
                            {/* <div className="element-title-yellow users">
                                <h6>Manage Users</h6>
                            </div> */}
                            <div className="admin-content element-body users">
                                <h6 style={{ marginLeft: "10px" }}>
                                    Add a single user or upload a .csv file from your computer. If you
                                    need a .csv file template, you can
                                    <a
                                        style={{ marginLeft: "5px", textDecoration: "underline" }}
                                        href="/templates/adminTemplate.csv"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        download it here
                                    </a>
                                    <i
                                        style={{ marginLeft: "10px" }}
                                        data-tooltip-id="csvTooltip1"
                                        data-tooltip-variant="info"
                                        data-tooltip-content="Do not edit the headers of the file. Please make sure your CSV file has the exact following headers: firstName, lastName, email, program, group, role. Under 'role', please enter 'user' for students and 'teacher' for teachers."
                                        className="fa fa-info-circle"
                                    >
                                        <Tooltip className="tooltip" id="csvTooltip1" place="bottom" />
                                    </i>
                                </h6>
                                <div className="admin-btns">
                                    <div
                                        className="admin-btns"
                                        style={{ justifyContent: "flex-start" }}
                                    >
                                        <button onClick={openCreateModal}>Add single user
                                            <i className="fa fa-user-plus" style={{ marginLeft: "10px" }}></i>
                                        </button>
                                        <label className="uploadFileBtn" htmlFor="csvFileUpload">
                                            Upload file from computer
                                            <i className="fa fa-upload" style={{ color: "white" }}></i>
                                        </label>
                                        <input
                                            id="csvFileUpload"
                                            type="file"
                                            accept=".csv"
                                            className="file-input"
                                            onChange={handleFileUpload}
                                            style={{ display: "none" }}
                                        />
                                    </div>

                                    <div className="admin-search-container">
                                        <TextField
                                            value={searchQuery || ""}
                                            onChange={handleChange}
                                            placeholder="Search users"
                                            variant="outlined"
                                            size="small"
                                            name="searchQuery"
                                            sx={{
                                                backgroundColor: "#fff", padding: "0px", zIndex: "0",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ margin: "10px" }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <h6>Add users from a teacher .csv file</h6>
                                        <i
                                            className="fas fa-info-circle"
                                            data-tooltip-id="csvTeacherTip"
                                            data-tooltip-variant="info"
                                            data-tooltip-content="If a teacher sent you a .csv file with students, it will appear here.">
                                            <Tooltip className="tooltip" id="csvTeacherTip" place="right" />
                                        </i>
                                    </div>
                                    <Select
                                        value={selectedTeacherFile || ""}
                                        onChange={handleTeacherFileSelect}
                                        displayEmpty
                                        disabled={teachersFiles.length === 0}
                                        style={{ width: "300px" }}
                                        renderValue={(selected) => {
                                            if (!selected) {
                                                return teachersFiles.length === 0
                                                    ? "No files to upload"
                                                    : "Select file";
                                            }
                                            return selected;
                                        }}
                                    >
                                        {teachersFilesDropdown}
                                    </Select>
                                    <button
                                        disabled={teachersFiles.length === 0}
                                        onClick={handleTeacherFileUpload}
                                    >
                                        Add users
                                    </button>
                                </div>
                                <div className="admin-table-container users">
                                    <table className="admin-table">
                                        <thead>
                                            <tr>
                                                <th> #</th>
                                                <th onClick={() => requestSort("firstName")}>
                                                    First Name{" "}
                                                    {sortConfig.key === "firstName" ? (
                                                        sortConfig.direction === "ascending" ? (
                                                            <i className="fa-solid fa-sort-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-sort-down"></i>
                                                        )
                                                    ) : (
                                                        <i className="fa-solid fa-sort"></i>
                                                    )}
                                                </th>
                                                <th onClick={() => requestSort("lastName")}>
                                                    Last Name{" "}
                                                    {sortConfig.key === "lastName" ? (
                                                        sortConfig.direction === "ascending" ? (
                                                            <i className="fa-solid fa-sort-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-sort-down"></i>
                                                        )
                                                    ) : (
                                                        <i className="fa-solid fa-sort"></i>
                                                    )}
                                                </th>
                                                <th onClick={() => requestSort("email")}>
                                                    Email{" "}
                                                    {sortConfig.key === "email" ? (
                                                        sortConfig.direction === "ascending" ? (
                                                            <i className="fa-solid fa-sort-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-sort-down"></i>
                                                        )
                                                    ) : (
                                                        <i className="fa-solid fa-sort"></i>
                                                    )}
                                                </th>
                                                <th onClick={() => requestSort("program")}>
                                                    Program{" "}
                                                    {sortConfig.key === "program" ? (
                                                        sortConfig.direction === "ascending" ? (
                                                            <i className="fa-solid fa-sort-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-sort-down"></i>
                                                        )
                                                    ) : (
                                                        <i className="fa-solid fa-sort"></i>
                                                    )}
                                                </th>
                                                <th onClick={() => requestSort("group")}>
                                                    Group{" "}
                                                    {sortConfig.key === "group" ? (
                                                        sortConfig.direction === "ascending" ? (
                                                            <i className="fa-solid fa-sort-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-sort-down"></i>
                                                        )
                                                    ) : (
                                                        <i className="fa-solid fa-sort"></i>
                                                    )}
                                                </th>
                                                <th onClick={() => requestSort("role")}>
                                                    {" "}
                                                    Role{" "}
                                                    {sortConfig.key === "role" ? (
                                                        sortConfig.direction === "ascending" ? (
                                                            <i className="fa-solid fa-sort-up"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-sort-down"></i>
                                                        )
                                                    ) : (
                                                        <i className="fa-solid fa-sort"></i>
                                                    )}
                                                </th>
                                                <th>Resend Invite</th>
                                                <th>Update</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentUsers.map((user, index) => (
                                                <tr key={user.email}>
                                                    <td>{indexOfFirstUser + index + 1}</td>
                                                    <td>{user.firstName}</td>
                                                    <td>{user.lastName}</td>
                                                    <td>{user.email}</td>
                                                    <td>
                                                        {Array.isArray(user.program)
                                                            ? user.program.join(", ")
                                                            : user.program}
                                                    </td>
                                                    <td >
                                                        {Array.isArray(user.group)
                                                            ? user.group.join(", ")
                                                            : user.group}
                                                    </td>
                                                    <td>
                                                        {user.role && user.role === "user"
                                                            ? "Student"
                                                            : user.role && user.role === "admin"
                                                                ? "Admin"
                                                                : "Teacher"}
                                                    </td>
                                                    <td >
                                                        <button
                                                            className="transparentBtn"
                                                            onClick={() => handleResendInvite(user)}
                                                        // disabled={recentlySentEmails[user.uid]}
                                                        >
                                                            {recentlySentEmails[user.uid] ? (
                                                                <Check size={18} color="#4CAF50" />
                                                            ) : (
                                                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                                            )}
                                                        </button>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <button
                                                            className="transparentBtn"
                                                            onClick={() => openUpdateUserModal(user)}
                                                        >
                                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <button
                                                            className="transparentBtn"
                                                            onClick={() =>
                                                                deleteUserFromAuthAndDB(
                                                                    user.uid,
                                                                    user.firstName,
                                                                    user.lastName
                                                                )
                                                            }
                                                        >
                                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="pagination-container">
                                        <Pagination
                                            count={Math.ceil(sortedAndFilteredUsers.length / itemsPerPage)}  // total pages
                                            page={currentPage}  // current page
                                            onChange={handlePageChange}  // handle page change
                                            color="primary"
                                            sx={{ mt: 2 }}  // Add some top margin
                                        />
                                    </div>

                                </div>
                                <div className="admin-modal-container">
                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="user-modal"
                                        aria-describedby="user-modal"
                                    >
                                        <Box sx={modalStyle}>
                                            {mode === "create" && (
                                                <>
                                                    <h4>Add new user</h4>
                                                    <p>Please fill in all of the following fields:</p>
                                                </>
                                            )}
                                            {mode === "update" && selectedUser && (
                                                <>
                                                    <h4>
                                                        Update user: {selectedUser.firstName}{" "}
                                                        {selectedUser.lastName}
                                                    </h4>
                                                    <p>Update any of the following fields:</p>
                                                </>
                                            )}
                                            <form
                                                onSubmit={mode === "create" ? addSingleUser : updateUser}
                                            >
                                                <div style={{ display: "flex", gap: "20px" }}>
                                                    <TextField
                                                        label="First Name"
                                                        variant="outlined"
                                                        sx={{
                                                            width: "50%", mt: 2,
                                                            '& .MuiInputBase-input.MuiOutlinedInput-input': {
                                                                padding: '16px !important'
                                                            }
                                                        }}
                                                        value={
                                                            mode === "create"
                                                                ? newContact.firstName || ""
                                                                : selectedUser.firstName || ""
                                                        }
                                                        onChange={(e) => {
                                                            if (mode === "create") {
                                                                setNewContact({
                                                                    ...newContact,
                                                                    firstName: e.target.value,
                                                                });
                                                                setError({ firstNameError: "" });
                                                            } else {
                                                                setSelectedUser({
                                                                    ...selectedUser,
                                                                    firstName: e.target.value,
                                                                });
                                                                setError({ ...error, firstNameError: "" });
                                                            }
                                                        }}
                                                        error={Boolean(error.firstNameError)}
                                                    />
                                                    <TextField
                                                        label="Last Name"
                                                        variant="outlined"
                                                        sx={{
                                                            width: "50%", mt: 2,
                                                            '& .MuiInputBase-input.MuiOutlinedInput-input': {
                                                                padding: '16px !important'
                                                            }
                                                        }}
                                                        value={
                                                            mode === "create"
                                                                ? newContact.lastName || ""
                                                                : selectedUser.lastName || ""
                                                        }
                                                        onChange={(e) => {
                                                            if (mode === "create") {
                                                                setNewContact({
                                                                    ...newContact,
                                                                    lastName: e.target.value,
                                                                });
                                                                setError({ lastNameError: "" });
                                                            } else {
                                                                setSelectedUser({
                                                                    ...selectedUser,
                                                                    lastName: e.target.value,
                                                                });
                                                                setError({ ...error, lastNameError: "" });
                                                            }
                                                        }}
                                                        error={Boolean(error.lastNameError)}
                                                    />
                                                </div>

                                                <TextField
                                                    label="Email"
                                                    variant="outlined"
                                                    sx={{
                                                        width: "100%", mt: 2,
                                                        '& .MuiInputBase-input.MuiOutlinedInput-input': {
                                                            padding: '16px !important'
                                                        }
                                                    }}
                                                    value={
                                                        mode === "create"
                                                            ? newContact.email || ""
                                                            : selectedUser.email || ""
                                                    }
                                                    onChange={(e) => {
                                                        if (mode === "create") {
                                                            setNewContact({
                                                                ...newContact,
                                                                email: e.target.value,
                                                            });
                                                            setError({ emailError: "" });
                                                        } else {
                                                            setSelectedUser({
                                                                ...selectedUser,
                                                                email: e.target.value,
                                                            });
                                                            setError({ ...error, emailError: "" });
                                                        }
                                                    }}
                                                    error={Boolean(error.emailError)}
                                                />
                                                <FormControl
                                                    sx={{ width: "100%", mt: 2, textAlign: "left" }}
                                                >
                                                    <InputLabel id="programName">Program Name</InputLabel>
                                                    <Select
                                                        value={
                                                            mode === "create"
                                                                ? newContact.program || []
                                                                : Array.isArray(selectedUser.program)
                                                                    ? selectedUser.program
                                                                    : [selectedUser.program]
                                                        }
                                                        style={{ padding: "8px" }}
                                                        multiple
                                                        labelId="programName"
                                                        id="programName"
                                                        label="Program Name"
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            let selectedPrograms = [];
                                                            if (!Array.isArray(value)) {
                                                                selectedPrograms = [value];
                                                            } else {
                                                                selectedPrograms = value;
                                                            }
                                                            if (mode === "create") {
                                                                setNewContact({
                                                                    ...newContact,
                                                                    program: selectedPrograms,
                                                                });
                                                                setError({ programError: "" });
                                                            } else {
                                                                setSelectedUser({
                                                                    ...selectedUser,
                                                                    program: selectedPrograms,
                                                                });
                                                                setError({ ...error, programError: "" });
                                                            }
                                                        }}
                                                        error={Boolean(error.programError)}
                                                        renderValue={(selected) => selected.join(",  ")} // This will display the selected programs in the dropdown
                                                    >
                                                        {allPrograms &&
                                                            allPrograms.length > 0 &&
                                                            allPrograms.map((program) => (
                                                                <MenuItem key={program.uid} value={program.name}>
                                                                    <Checkbox
                                                                        checked={
                                                                            mode === "create"
                                                                                ? newContact.program.includes(program.name)
                                                                                : selectedUser.program.includes(
                                                                                    program.name
                                                                                )
                                                                        }
                                                                    />
                                                                    <ListItemText
                                                                        style={{ textAlign: "left" }}
                                                                        primary={program.name}
                                                                    />
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>

                                                <FormControl
                                                    sx={{ width: "100%", mt: 2, textAlign: "left" }}
                                                >
                                                    <InputLabel id="groupName-label">Group Name</InputLabel>
                                                    <Select
                                                        value={
                                                            mode === "create"
                                                                ? newContact.group || []
                                                                : Array.isArray(selectedUser.group)
                                                                    ? selectedUser.group
                                                                    : [selectedUser.group]
                                                        }
                                                        multiple
                                                        labelId="groupName-label"
                                                        id="groupName"
                                                        style={{ padding: "8px" }}
                                                        label="Group Name"
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            let selectedGroups = [];
                                                            if (!Array.isArray(value)) {
                                                                selectedGroups = [value];
                                                            } else {
                                                                selectedGroups = value;
                                                            }
                                                            if (mode === "create") {
                                                                setNewContact({
                                                                    ...newContact,
                                                                    group: selectedGroups,
                                                                });
                                                                setError({ groupError: "" });
                                                            } else {
                                                                setSelectedUser({
                                                                    ...selectedUser,
                                                                    group: selectedGroups,
                                                                });
                                                                setError({ ...error, groupError: "" });
                                                            }
                                                        }}
                                                        error={Boolean(error.groupError)}
                                                        renderValue={(selected) => selected.join(",  ")} // This will display the selected groups in the dropdown
                                                    >
                                                        {allGroups &&
                                                            allGroups.length > 0 &&
                                                            allGroups.map((group) => (
                                                                <MenuItem key={group.uid} value={group.name}>
                                                                    <Checkbox
                                                                        checked={
                                                                            mode === "create"
                                                                                ? newContact.group.includes(group.name)
                                                                                : selectedUser.group.includes(group.name)
                                                                        }
                                                                    />
                                                                    <ListItemText
                                                                        style={{ textAlign: "left" }}
                                                                        primary={group.name}
                                                                    />
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl
                                                    sx={{ width: "100%", mt: 2, textAlign: "left" }}
                                                >
                                                    <InputLabel id="role">User Role</InputLabel>
                                                    <Select
                                                        value={
                                                            mode === "create"
                                                                ? newContact.role || ""
                                                                : selectedUser.role || ""
                                                        }
                                                        labelId="role"
                                                        id="role"
                                                        label="User Role"
                                                        style={{ padding: "8px" }}
                                                        onChange={(e) => {
                                                            if (mode === "create") {
                                                                setNewContact({
                                                                    ...newContact,
                                                                    role: e.target.value,
                                                                });
                                                                setError({ roleError: "" });
                                                            } else {
                                                                setSelectedUser({
                                                                    ...selectedUser,
                                                                    role: e.target.value,
                                                                });
                                                                setError({ ...error, roleError: "" });
                                                            }
                                                        }}
                                                        error={Boolean(error.roleError)}
                                                    >
                                                        <MenuItem value="user">Student</MenuItem>
                                                        <MenuItem value="teacher">Teacher</MenuItem>
                                                        <MenuItem value="admin">Admin</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                <div style={{ marginTop: "10px" }}>
                                                    {mode === "create" ? (
                                                        <button type="submit">Add User</button>
                                                    ) : (
                                                        <button type="submit">Update User</button>
                                                    )}
                                                    <button
                                                        className="greyButton"
                                                        type="button"
                                                        onClick={handleClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div >
                                                    {message && <p>{message}</p>}
                                                    {error.emailError && <p>{error.emailError}</p>}
                                                    {error.firstNameError && <p>{error.firstNameError}</p>}
                                                    {error.lastNameError && <p>{error.lastNameError}</p>}
                                                </div>
                                            </form>
                                        </Box>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    )}
                    {pageActiveTab === 1 && (
                        <div className="admin-container programs" id="programsSection">
                            {/* <div className="element-title-yellow">
                                <h6>Manage Programs</h6>
                            </div> */}
                            <div className="admin-content element-body programs">
                                <div
                                    style={{ justifyContent: "flex-start", gap: "20px" }}
                                    className="admin-btns"
                                >
                                    <button
                                        // disabled={isCK}
                                        style={{ margin: "0px" }}
                                        onClick={openCreateProgramModal}
                                    >
                                        Add New Program
                                    </button>
                                    {/* {allPrograms && allPrograms.length === 1 && (
             <div >
                 <p>You can only add 1 program. Contact us to add more.</p>
             </div>
         )} */}
                                </div>
                                <div className="admin-table-container">
                                    <table className="admin-table">
                                        <thead>
                                            <tr>
                                                <th>Program Name</th>
                                                {/* <th>Program Description (internal)</th> */}
                                                {/* <th>Type</th> */}
                                                <th>Edit program info</th>
                                                <th>Edit program videos</th>
                                                <th>Edit questionnaires / Canva links</th>
                                                <th>Edit general page</th>
                                                <th>Edit exercises page</th>
                                                <th>Delete program</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allPrograms &&
                                                allPrograms.map((program) => (
                                                    <tr key={program.uid}>
                                                        <td>{program.name}</td>
                                                        {/* <td style={{ maxWidth: '600px' }}>{program.description}</td> */}
                                                        {/* <td >{program.type}</td> */}
                                                        <td>
                                                            <button
                                                                className="transparentBtn"
                                                                onClick={() => openUpdateProgramModal(program)}
                                                            >
                                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="transparentBtn"
                                                                onClick={() => openUpdateVideosPageModal(program)}
                                                            >
                                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="transparentBtn"
                                                                onClick={() =>
                                                                    openUpdateQuestionnairesPageModal(program)
                                                                }
                                                            >
                                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="transparentBtn"
                                                                onClick={() => openUpdateGeneralPageModal(program)}
                                                            >
                                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="transparentBtn"
                                                                onClick={() => openUpdateExtraInfoModal(program)}
                                                            >
                                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                                            </button>
                                                        </td>

                                                        <td>
                                                            <button
                                                                className="transparentBtn delete"
                                                                onClick={(e) => deleteProgramInDb(e, program)}
                                                            >
                                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="programs-modal-container">
                                    {/* edit videos */}
                                    <Modal
                                        open={openVideosPageModal}
                                        onClose={handleVideosPageClose}
                                        aria-labelledby="videos-page-modal"
                                        aria-describedby="videos-page-modal"
                                    >
                                        <Box sx={largeModalStyle}>
                                            <div>
                                                <h5>
                                                    Add videos for program: "
                                                    {(selectedProgram && selectedProgram?.name) || ""}"
                                                </h5>
                                                <p>You can add videos from YouTube or Vimeo</p>
                                            </div>
                                            <form onSubmit={updateProgramInDb}>
                                                <div
                                                    style={{
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    <button
                                                        className="yellow-btn"
                                                        onClick={(e) => addVideoBlock(e)}
                                                    >
                                                        Add Video Block
                                                    </button>
                                                </div>
                                                <div className="video-modal-section-container">
                                                    <div className="video-page-modal">
                                                        {selectedProgram?.videos &&
                                                            selectedProgram?.videos.map((video, index) => (
                                                                <div className="video-block" key={video.id}>
                                                                    <TextField
                                                                        label="Video Title"
                                                                        variant="outlined"
                                                                        sx={{ width: "100%" }}
                                                                        value={video?.title || ""}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        placeholder="Example: Video 1"
                                                                        onChange={(e) =>
                                                                            updateVideoBlock(
                                                                                index,
                                                                                "title",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                    <TextField
                                                                        label="Video URL"
                                                                        variant="outlined"
                                                                        sx={{
                                                                            width: "100%",
                                                                        }}
                                                                        value={video?.url || ""}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        placeholder="Example: https://www.youtube.com/watch?v=videoId"
                                                                        onChange={(e) =>
                                                                            updateVideoBlock(
                                                                                index,
                                                                                "url",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />

                                                                    <button
                                                                        style={{
                                                                            width: "400px",
                                                                        }}
                                                                        className="transparentBtn delete"
                                                                        onClick={() => deleteVideoBlock(index)}
                                                                    >
                                                                        <i
                                                                            className="fa fa-trash"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        Delete video
                                                                    </button>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>

                                                <div>
                                                    <button type="submit">Save Videos</button>
                                                    <button
                                                        className="greyButton"
                                                        type="button"
                                                        onClick={handleVideosPageClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div className="successMessage">
                                                    {message && <p>{message}</p>}
                                                </div>
                                            </form>
                                        </Box>
                                    </Modal>

                                    {/* edit questionnaires */}
                                    <Modal
                                        open={openQuestionnairesPageModal}
                                        onClose={handleQuestionnairesPageClose}
                                        aria-labelledby="questionnaires-page-modal"
                                        aria-describedby="questionnaires-page-modal"
                                    >
                                        <Box sx={largeModalStyle}>
                                            <div>
                                                <h5>
                                                    Add questionnaires / Canva links for program: "
                                                    {(selectedProgram && selectedProgram?.name) || ""}"
                                                </h5>
                                                <p>
                                                    Later for each group you can select which questionnaire to
                                                    use
                                                </p>
                                            </div>
                                            <form onSubmit={updateProgramInDb}>
                                                <div
                                                    style={{
                                                        marginTop: "15px",
                                                        display: "flex",
                                                        justifyContent: "space-around",
                                                    }}
                                                >
                                                    <button onClick={(e) => addQuestionnaireBlock(e)}>
                                                        Add Questionnaire Block
                                                    </button>
                                                </div>
                                                <div className="general-modal-section-container">
                                                    <div className="video-page-modal">
                                                        {selectedProgram &&
                                                            selectedProgram.questionnaires?.length > 0 &&
                                                            selectedProgram.questionnaires?.map(
                                                                (block, index) => (
                                                                    <div className="video-block" key={index}>
                                                                        <div className="videoModalSection">
                                                                            <TextField
                                                                                label="Title"
                                                                                variant="outlined"
                                                                                sx={{
                                                                                    width: "100%",
                                                                                    "& .MuiOutlinedInput-input": {
                                                                                        padding: "3px 25px 3px 3px",
                                                                                        fontSize: "14px",
                                                                                    },
                                                                                }}
                                                                                value={block.title || ""}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                placeholder="Example: Voorbereiding gesprek"
                                                                                onChange={(e) =>
                                                                                    updateQuestionnaireBlock(
                                                                                        index,
                                                                                        "title",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                            <TextField
                                                                                label="URL"
                                                                                variant="outlined"
                                                                                sx={{
                                                                                    width: "100%",
                                                                                    "& .MuiOutlinedInput-input": {
                                                                                        padding: "3px 25px 3px 3px",
                                                                                        fontSize: "14px",
                                                                                    },
                                                                                }}
                                                                                value={block.url || ""}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                placeholder="Example: 'https://zfrmz.eu/Z5BJ3R0Yo4CWFSL9Dh93'"
                                                                                onChange={(e) =>
                                                                                    updateQuestionnaireBlock(
                                                                                        index,
                                                                                        "url",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <button
                                                                            className="yellow-btn"
                                                                            onClick={() =>
                                                                                deleteQuestionnaireBlock(index)
                                                                            }
                                                                        >
                                                                            <i
                                                                                className="fa fa-trash"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                            Delete
                                                                        </button>
                                                                    </div>
                                                                )
                                                            )}
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: "25px" }}>
                                                    <button type="submit">Save Questionnaires</button>
                                                    <button
                                                        className="greyButton"
                                                        type="button"
                                                        onClick={handleQuestionnairesPageClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div className=" successMessage">
                                                    {message && <p>{message}</p>}
                                                </div>
                                            </form>
                                        </Box>
                                    </Modal>

                                    {/* edit general page */}
                                    <Modal
                                        open={openGeneralPageModal}
                                        onClose={handleGeneralPageClose}
                                        aria-labelledby="general-page-modal"
                                        aria-describedby="general-page-modal"
                                    >
                                        <Box sx={largeModalStyle}>
                                            <h5>
                                                Add content for the General Page of program: "
                                                {(selectedProgram && selectedProgram?.name) || ""}"
                                            </h5>
                                            <p>You can add text blocks, videos and Q&As</p>

                                            <Tabs value={activeTab} onChange={handleTabChange} aria-label="general page tabs">
                                                <Tab label="General Info" />
                                                <Tab label="Q&A" />
                                                <Tab label="Videos" />
                                            </Tabs>

                                            <form onSubmit={updateProgramInDb}>
                                                <Box sx={{ p: 3 }}>
                                                    {activeTab === 0 && (
                                                        <div className="general-modal-section-container">
                                                            <button className="yellow-btn" onClick={(e) => addContentBlock(e)}>
                                                                Add Content Block
                                                            </button>
                                                            <div className="generalModalSection">
                                                                {selectedProgram &&
                                                                    selectedProgram.generalPage?.content &&
                                                                    selectedProgram.generalPage?.content.map((block, index) => (
                                                                        <div className="general-page-block" key={index}>
                                                                            <TextField
                                                                                label="Title"
                                                                                variant="outlined"
                                                                                sx={{ width: "100%", mt: 2 }}
                                                                                value={block.title || ""}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                placeholder="Example: background about our program"
                                                                                onChange={(e) =>
                                                                                    updateContentBlock(
                                                                                        index,
                                                                                        "title",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                            <TextField
                                                                                label="Text"
                                                                                variant="outlined"
                                                                                sx={{ width: "100%", mt: 2 }}
                                                                                value={block.text || ""}
                                                                                multiline
                                                                                rows={3}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                placeholder="Example: Our program is designed to help students..."
                                                                                onChange={(e) =>
                                                                                    updateContentBlock(
                                                                                        index,
                                                                                        "text",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                            <button
                                                                                className="yellow-btn"
                                                                                onClick={() => deleteContentBlock(index)}
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {activeTab === 1 && (
                                                        <div className="general-modal-section-container">
                                                            <button className="yellow-btn" onClick={(e) => addQA(e)}>
                                                                Add Q&A Block
                                                            </button>
                                                            <div className="generalModalSection">
                                                                {selectedProgram &&
                                                                    selectedProgram.generalPage?.questions &&
                                                                    selectedProgram.generalPage?.questions.map((qa, index) => (
                                                                        <div className="general-page-block" key={index}>
                                                                            <TextField
                                                                                label="Question"
                                                                                variant="outlined"
                                                                                sx={{ width: "100%", mt: 2 }}
                                                                                value={qa.question || ""}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                placeholder="Example: How long is the program?"
                                                                                onChange={(e) =>
                                                                                    updateQA(index, "question", e.target.value)
                                                                                }
                                                                            />
                                                                            <TextField
                                                                                label="Answer"
                                                                                variant="outlined"
                                                                                sx={{ width: "100%", mt: 2 }}
                                                                                value={qa.answer || ""}
                                                                                multiline
                                                                                // rows={3}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                placeholder="Example: The program is 10 weeks long"
                                                                                onChange={(e) =>
                                                                                    updateQA(index, "answer", e.target.value)
                                                                                }
                                                                            />
                                                                            <button
                                                                                className="yellow-btn"
                                                                                onClick={() => deleteQA(index)}
                                                                            >
                                                                                Delete Question
                                                                            </button>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {activeTab === 2 && (
                                                        <div className="general-modal-section-container">
                                                            <button className="yellow-btn" onClick={(e) => addGeneralVideoBlock(e)}>
                                                                Add Video Block
                                                            </button>
                                                            <div className="generalModalSection">
                                                                {selectedProgram &&
                                                                    selectedProgram.generalPage?.videos &&
                                                                    selectedProgram.generalPage?.videos.map((video, index) => (
                                                                        <div className="general-page-block" key={index}>
                                                                            <TextField
                                                                                label="Video Title"
                                                                                variant="outlined"
                                                                                sx={{ width: "100%", mt: 2 }}
                                                                                value={video.title || ""}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                placeholder="Example: Video 1"
                                                                                onChange={(e) =>
                                                                                    updateGeneralVideoBlock(
                                                                                        index,
                                                                                        "title",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                            <TextField
                                                                                label="Video URL"
                                                                                variant="outlined"
                                                                                sx={{ width: "100%", mt: 2 }}
                                                                                value={video.url || ""}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                placeholder="Example: https://www.youtube.com/watch?v=videoId"
                                                                                onChange={(e) =>
                                                                                    updateGeneralVideoBlock(
                                                                                        index,
                                                                                        "url",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                            <button
                                                                                className="yellow-btn"
                                                                                onClick={() => deleteGeneralVideoBlock(index)}
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </Box>

                                                <div style={{ marginTop: "25px" }}>
                                                    <button type="submit">Save Content</button>
                                                    <button
                                                        className="greyButton"
                                                        type="button"
                                                        onClick={handleGeneralPageClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div className=" successMessage">
                                                    {message && <p>{message}</p>}
                                                </div>
                                            </form>
                                        </Box>
                                    </Modal>

                                    {/* edit/add programs */}
                                    <Modal
                                        open={openProgramModal}
                                        onClose={handleProgramClose}
                                        aria-labelledby="program-modal"
                                        aria-describedby="program-modal"
                                    >
                                        <Box sx={programModalStyle}>
                                            {programModalMode === "create" && (
                                                <div>
                                                    <h4>Add new program</h4>
                                                    <p>Please fill in the following fields:</p>
                                                </div>
                                            )}
                                            {programModalMode === "update" && selectedProgram && (
                                                <div>
                                                    <h4>Update program: {selectedProgram.name} </h4>
                                                    <p>Please update the following fields</p>
                                                </div>
                                            )}
                                            <form
                                                onSubmit={
                                                    programModalMode === "create"
                                                        ? createProgramInDb
                                                        : updateProgramInDb
                                                }
                                            >
                                                <TextField
                                                    label="Name"
                                                    variant="outlined"
                                                    sx={{ width: "100%", mt: 2 }}
                                                    value={
                                                        programModalMode === "create"
                                                            ? newProgram.name
                                                            : selectedProgram.name
                                                    }
                                                    onChange={(e) => {
                                                        if (programModalMode === "create") {
                                                            setNewProgram({
                                                                ...newProgram,
                                                                name: e.target.value,
                                                            });
                                                            setProgramError({ nameError: "" });
                                                        } else {
                                                            setSelectedProgram({
                                                                ...selectedProgram,
                                                                name: e.target.value,
                                                            });
                                                            setProgramError({ ...programError, nameError: "" });
                                                        }
                                                    }}
                                                    error={Boolean(programError.nameError)}
                                                />
                                                <TextField
                                                    label="Description"
                                                    variant="outlined"
                                                    sx={{ width: "100%", mt: 2 }}
                                                    multiline
                                                    rows={5}
                                                    value={
                                                        programModalMode === "create"
                                                            ? newProgram.description
                                                            : selectedProgram.description
                                                    }
                                                    onChange={(e) => {
                                                        if (programModalMode === "create") {
                                                            setNewProgram({
                                                                ...newProgram,
                                                                description: e.target.value,
                                                            });
                                                            setProgramError({ lastNameError: "" });
                                                        } else {
                                                            setSelectedProgram({
                                                                ...selectedProgram,
                                                                description: e.target.value,
                                                            });
                                                            setProgramError({
                                                                ...programError,
                                                                descriptionError: "",
                                                            });
                                                        }
                                                    }}
                                                    error={Boolean(programError.descriptionError)}
                                                />
                                                <div style={{ textAlign: "left", marginTop: "20px" }}>
                                                    <div>
                                                        <p style={{ fontWeight: "500" }}>Program Type</p>
                                                    </div>
                                                    <FormControl sx={{ width: "100%", mt: 2 }}>
                                                        <Select
                                                            value={
                                                                programModalMode === "create"
                                                                    ? newProgram.type || ""
                                                                    : selectedProgram.type || ""
                                                            }
                                                            onChange={(e) => {
                                                                const { value } = e.target;
                                                                if (mode === "create") {
                                                                    setNewProgram({ ...newProgram, type: value });
                                                                    setProgramError({ typeError: "" });
                                                                } else {
                                                                    setSelectedProgram({
                                                                        ...selectedProgram,
                                                                        type: value,
                                                                    });
                                                                    setProgramError({
                                                                        ...programError,
                                                                        typeError: "",
                                                                    });
                                                                }
                                                            }}
                                                            error={Boolean(programError.typeError)}
                                                            renderValue={(selected) => {
                                                                if (!selected) {
                                                                    return "Select program type";
                                                                }
                                                                return selected;
                                                            }}
                                                            displayEmpty
                                                        >
                                                            {/* {programTypesDropdown} */}
                                                            {/* <MenuItem value=''>Select program type</MenuItem> */}
                                                            {programTypes.map((type, index) => (
                                                                <MenuItem key={index} value={type}>
                                                                    {type}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>

                                                        <TextField
                                                            label="Or add a new program type"
                                                            variant="outlined"
                                                            sx={{ width: "100%", mt: 1 }}
                                                            value={
                                                                mode === "create"
                                                                    ? newProgram.type
                                                                    : selectedProgram.type
                                                            }
                                                            onChange={(e) => {
                                                                if (mode === "create") {
                                                                    setNewProgram({
                                                                        ...newProgram,
                                                                        type: e.target.value,
                                                                    });
                                                                    setProgramError({ typeError: "" });
                                                                } else {
                                                                    setSelectedProgram({
                                                                        ...selectedProgram,
                                                                        type: e.target.value,
                                                                    });
                                                                    setProgramError({
                                                                        ...programError,
                                                                        typeError: "",
                                                                    });
                                                                }
                                                            }}
                                                            error={Boolean(programError.typeError)}
                                                        />
                                                        <FormHelperText>
                                                            {programError.typeError}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </div>
                                                <div className=" successMessage">
                                                    {message && <p>{message}</p>}
                                                </div>
                                                <div style={{ marginTop: "20px" }}>
                                                    {programModalMode === "create" ? (
                                                        <button type="submit">Create Program</button>
                                                    ) : (
                                                        <button type="submit">Update Program</button>
                                                    )}
                                                    <button
                                                        className="greyButton"
                                                        type="button"
                                                        onClick={handleProgramClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </form>
                                        </Box>
                                    </Modal>

                                    {/* update extra page */}
                                    <Modal open={openExtraInfoModal} onClose={handleExtraInfoClose}>
                                        <Box sx={largeModalStyle}>
                                            <div>
                                                <h5>
                                                    Add PDFs to the e-learning page of program "
                                                    {(selectedProgram && selectedProgram?.name) || ""}"
                                                </h5>
                                            </div>
                                            <div className="extra-modal-section-container">
                                                <div className="generalModalSection extra">
                                                    <div className="modal-section-title">
                                                        <h6 style={{ fontWeight: "500" }}>Upload PDFs</h6>
                                                    </div>
                                                    {/* let admin select which group: */}
                                                    <Select
                                                        value={selectedGroup || ""}
                                                        onChange={(e) => {
                                                            setSelectedGroup(e.target.value);
                                                        }
                                                        }
                                                        sx={{ width: "20%", mb: 2 }}
                                                        displayEmpty
                                                        renderValue={(selected) => {
                                                            if (!selected) {
                                                                return "Select group";
                                                            }
                                                            return selected;
                                                        }}
                                                    >

                                                        {allGroups &&
                                                            allGroups.map((group) => (
                                                                <MenuItem key={group.uid} value={group.name}>
                                                                    {group.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>

                                                    <input
                                                        type="file"
                                                        id="fileUpload"
                                                        name="filename"
                                                        accept=".pdf"
                                                        multiple
                                                        disabled={selectedGroup === ""}
                                                        onChange={handlePDFSelection}
                                                        style={{ display: "none" }}
                                                    />
                                                    <label htmlFor="fileUpload" className="file-upload-label">
                                                        <CloudUploadIcon style={{ margin: "20px", color: selectedGroup === "" ? "lightgrey" : "" }} />
                                                        <span style={{ color: selectedGroup === "" ? "lightgrey" : "" }}>
                                                            Select PDF from your computer
                                                        </span>
                                                    </label>

                                                    <div className="file-list">
                                                        {files && files.map((fileWithId, index) => (
                                                            <div key={fileWithId.id} className="file-item">
                                                                <CloudUploadIcon style={{ margin: "20px" }} />
                                                                {fileWithId.file.name}
                                                                <span className="file-size">
                                                                    {(fileWithId.file.size / 1024 / 1024).toFixed(2)} MB
                                                                </span>
                                                                <i className="fa-solid fa-spinner fa-spin"></i>
                                                                <button
                                                                    onClick={() => handleRemovePDFFromState(index)}
                                                                >
                                                                    <DeleteIcon />
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="generalModalSection extra">
                                                <div className="modal-section-title">
                                                    <h6 style={{ fontWeight: "500" }}>Uploaded PDFs</h6>
                                                </div>

                                                {uploadedFiles && uploadedFiles.length > 0 ?
                                                    <div className="file-grid">
                                                        {uploadedFiles.map((uploadedFile) => (

                                                            <div key={uploadedFile.id} className="file-card">
                                                                <div className="file-details">

                                                                    <span className="file-thumbnail">
                                                                        {uploadedFile.thumbnail ? uploadedFile.thumbnail : ""}
                                                                    </span>
                                                                    <Divider />
                                                                    <span className="file-name">
                                                                        <strong>{uploadedFile.name}</strong>
                                                                    </span>
                                                                    <span className="file-date">Uploaded{" "}
                                                                        {new Date(uploadedFile.timeCreated).toLocaleDateString("en-GB")}
                                                                    </span>
                                                                    <span className="file-size">Size{" "}
                                                                        {(uploadedFile.size / 1024 / 1024).toFixed(2)} MB
                                                                    </span>

                                                                    <button
                                                                        onClick={() => handleEraseUploadedPDF(uploadedFile.name)}
                                                                        className="delete-button"
                                                                    >
                                                                        <DeleteIcon sx={{ width: '20px' }} />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    : (
                                                        <div style={{ width: '100%' }}>
                                                            <p>{selectedGroup ? 'No PDFs uploaded for this group' : 'Please select a group to see its PDFs'}</p>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <div style={{ marginTop: "25px" }}>
                                                <button
                                                    type="button"
                                                    onClick={handleExtraInfoClose}
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </Box>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    )}
                    {pageActiveTab === 2 && (
                        <div className="admin-container programs quizzes" id="quizzesSection">
                            {/* <div className="element-title-yellow">
                                <h6>Manage Quizzes</h6>
                            </div> */}
                            <div className="admin-content element-body quizzes">
                                <div
                                    style={{ justifyContent: "flex-start", gap: "20px" }}
                                    className="admin-btns"
                                >
                                    <select
                                        style={{ margin: "8px 0px" }}
                                        value={selectedProgram.name || ""}
                                        disabled={allPrograms.length === 0}
                                        onChange={(e) => {
                                            handleProgramSelect(e);
                                        }}
                                    >
                                        <option value="">Select Program</option>
                                        {allPrograms &&
                                            allPrograms.map((program) => (
                                                <option key={program.uid} value={program.name}>
                                                    {program.name}
                                                </option>
                                            ))}
                                    </select>
                                    <button
                                        style={{ margin: "8px 0px" }}
                                        disabled={!selectedProgram || selectedProgram.name === ""}
                                        onClick={() => handleCreateNewQuiz()}
                                    >
                                        Add New Quiz
                                    </button>
                                </div>
                                <div className="admin-table-container">
                                    <table className="admin-table">
                                        <thead>
                                            <tr>
                                                <th>Quiz Title</th>
                                                {/* <th>Quiz Description</th> */}
                                                <th>Edit</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedProgram.quizzes &&
                                                selectedProgram.quizzes.map((quiz) => (
                                                    <tr key={quiz.id}>
                                                        <td>{quiz.title}</td>
                                                        {/* <td style={{ maxWidth: '600px', textAlign: 'left' }}>{quiz.description}</td> */}
                                                        <td>
                                                            <button
                                                                className="transparentBtn"
                                                                onClick={() => openUpdateQuizzesPageModal(quiz)}
                                                            >
                                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="transparentBtn"
                                                                onClick={() => deleteQuizFromDB(quiz)}
                                                            >
                                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="programs-modal-container">
                                    <Modal
                                        open={openQuizzesPageModal}
                                        onClose={handleQuizzesPageClose}
                                        aria-labelledby="videos-page-modal"
                                        aria-describedby="videos-page-modal"
                                    >
                                        <Box sx={largeModalStyle}>
                                            <div>
                                                {selectedProgram?.name && (
                                                    <h5>
                                                        Add and edit quizzes for your program: "
                                                        {selectedProgram?.name}"
                                                    </h5>
                                                )}
                                                {selectedQuiz ? (
                                                    <div>You are editing quiz {selectedQuiz.title}</div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            {surveyCreator && (
                                                <SurveyCreatorComponent creator={surveyCreator} />
                                            )}
                                            <button onClick={handleQuizzesPageClose}>Close</button>
                                        </Box>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    )}
                    {pageActiveTab === 3 && (
                        <div className="admin-container groups" id="groupsSection">
                            {/* <div className="element-title-yellow">
                                <h6>Manage Groups</h6>
                            </div> */}
                            <div className="admin-content element-body groups">
                                <div className="admin-btns">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "20px",
                                        }}
                                    >
                                        <button
                                            disabled={!allPrograms || allPrograms.length === 0}
                                            style={{ margin: "8px 0px" }}
                                            onClick={openCreateGroupModal}
                                        >
                                            Add New Group
                                        </button>
                                        {!allPrograms && allPrograms.length === 0 && (
                                            <div style={{ color: "red" }}>
                                                <p>You need to add a program before you can add a group.</p>
                                            </div>
                                        )}
                                    </div>

                                    <TextField
                                        value={groupSearchQuery || ""}
                                        onChange={handleGroupSearchChange}
                                        placeholder="Search groups"
                                        variant="outlined"
                                        size="small"
                                        name="groupSearchQuery"
                                        sx={{ backgroundColor: "#fff", padding: "0px" }}
                                    />
                                </div>
                                <div className="admin-table-container">
                                    <table className="admin-table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th onClick={() => requestGroupSort('name')} style={{ cursor: 'pointer' }}>
                                                    Name <span>
                                                        {sortGroupConfig.key === 'name'
                                                            ? (sortGroupConfig.direction === 'ascending' ? ' ↑' : ' ↓')
                                                            : ' ↕'}
                                                    </span>
                                                </th>
                                                <th onClick={() => requestGroupSort('startDate')} style={{ cursor: 'pointer' }}>
                                                    Start Date <span>
                                                        {sortGroupConfig.key === 'startDate'
                                                            ? (sortGroupConfig.direction === 'ascending' ? ' ↑' : ' ↓')
                                                            : ' ↕'} {/* Show neutral arrow when not sorting this column */}
                                                    </span>
                                                </th>
                                                <th onClick={() => requestGroupSort('endDate')} style={{ cursor: 'pointer' }}>
                                                    End Date <span>
                                                        {sortGroupConfig.key === 'endDate'
                                                            ? (sortGroupConfig.direction === 'ascending' ? ' ↑' : ' ↓')
                                                            : ' ↕'} {/* Show neutral arrow when not sorting this column */}
                                                    </span>
                                                </th>
                                                <th>Program</th>
                                                <th>MDT Contact</th>
                                                <th>School Contact</th>
                                                <th>Sessions</th>
                                                <th>Number of Students</th>
                                                <th>Add students (no emails)</th>
                                                <th>Update</th>
                                                <th>Delete</th>
                                                <th>Export Group Data</th>
                                                <th>Notes From Teacher</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortGroups(filteredGroups, sortGroupConfig).map((group, index) => {

                                                return (
                                                    <tr key={group.uid}>
                                                        <td>{index + 1}</td>
                                                        <td>{group.name || ''}</td>
                                                        <td style={{ minWidth: "100px" }}>{group.startDate || ''}</td>
                                                        <td style={{ minWidth: "100px" }}>{group.endDate || ''}</td>
                                                        <td>{group.program === "MDT Cultuur & Kunst" ? "MDT C&K" : group.program || ''}</td>
                                                        <td>{group.contactName ?
                                                            group.contactName.split(' ').map((part, index, array) => {
                                                                if (index === 0) {
                                                                    // Return the first name
                                                                    return part + ' ';
                                                                } else if (index === array.length - 1) {
                                                                    // Return the first letter of the last name
                                                                    return part.charAt(0) + '.';
                                                                }
                                                            }).join('')
                                                            : ''
                                                        }
                                                        </td>

                                                        <td>{group.schoolContactName ?
                                                            group.schoolContactName.split(' ').map((part, index, array) => {
                                                                if (index === 0) {
                                                                    // Return the first name
                                                                    return part + ' ';
                                                                } else if (index === array.length - 1) {
                                                                    // Return the first letter of the last name
                                                                    return part.charAt(0) + '.';
                                                                }
                                                            }).join('')
                                                            : ''
                                                        }
                                                        </td>

                                                        <td>
                                                            {group.schedule?.length > 0 ?
                                                                group.schedule.length
                                                                : "No schedule"}
                                                        </td>
                                                        <td>
                                                            {calculateNumberOfStudents(group) || "No students"}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="transparentBtn"
                                                                onClick={() => handleAddStudentsModal(group)}
                                                            >
                                                                <i className="fa fa-upload" aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <button
                                                                className="transparentBtn"
                                                                onClick={() => openUpdateGroupModal(group)}
                                                            >
                                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <button
                                                                className="transparentBtn"
                                                                onClick={(e) => deleteGroupInDB(e, group)}
                                                            >
                                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                        <td style={{
                                                            textAlign: "center",
                                                            display: "flex",
                                                            gap: "10px",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            marginTop: "15px",
                                                        }}>
                                                            <button
                                                                className="transparentBtn"
                                                                onClick={(e) => generateGroupCSV(e, group)}
                                                                data-tooltip-id="exportTooltip"
                                                                data-tooltip-content="Export as CSV"
                                                            >
                                                                <i className="fa fa-file-csv"></i>
                                                            </button>
                                                            <button
                                                                className="transparentBtn"
                                                                onClick={(e) => generateGroupExcel(e, group)}
                                                                data-tooltip-id="exportTooltip"
                                                                data-tooltip-content="Export as Excel"
                                                            >
                                                                <i className="fa fa-file-excel"></i>
                                                            </button>
                                                            <Tooltip id="exportTooltip" />
                                                        </td>
                                                        <td>
                                                            {group.notes ?
                                                                <i
                                                                    disabled={group.notes === ""}
                                                                    data-tooltip-id="notesFromTeacher"
                                                                    data-tooltip-variant="info"
                                                                    data-tooltip-content={group.notes || "No notes"}
                                                                    className="fa fa-info-circle"
                                                                ></i> : "-"}
                                                            <Tooltip className="tooltip" id="notesFromTeacher" />

                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="groups-modal-container">
                                    <Modal
                                        open={openGroupModal}
                                        onClose={handleGroupClose}
                                        aria-labelledby="group-modal"
                                        aria-describedby="group-modal"
                                        className="group-modal"
                                    >
                                        <Box sx={groupModalStyle}>
                                            {groupModalMode === "create" && (
                                                <div>
                                                    <h4>Add new group</h4>
                                                </div>
                                            )}
                                            {groupModalMode === "update" && selectedGroup && (
                                                <div>
                                                    <h5>Update group "{selectedGroup.name}"</h5>
                                                </div>
                                            )}
                                            <form onSubmit={groupModalMode === "create" ? createGroupInDB : updateGroupInDB}>
                                                <div className="admin-form-row">
                                                    <TextField
                                                        label="Group name"
                                                        variant="outlined"
                                                        sx={{
                                                            width: "30%",
                                                            mt: 2,
                                                            // add padding to .MuiSeedInput-root
                                                            "& .MuiOutlinedInput-root": {
                                                                padding: "3px 25px 3px 3px",
                                                                fontSize: "15px",
                                                            },
                                                        }}
                                                        value={groupModalMode === "create" ? newGroup.name || "" : selectedGroup.name || ""}
                                                        onChange={(e) => {
                                                            const error = validateName(e.target.value);
                                                            if (groupModalMode === "create") {
                                                                setNewGroup({ ...newGroup, name: e.target.value });
                                                                setGroupError({ ...groupError, nameError: error });
                                                            } else {
                                                                setSelectedGroup({ ...selectedGroup, name: e.target.value, });
                                                                setGroupError({ ...groupError, nameError: error });
                                                            }
                                                        }}
                                                        error={Boolean(groupError.nameError)}
                                                        helperText={groupError.nameError}
                                                    />
                                                    <TextField
                                                        label="Program Name"
                                                        variant="outlined"
                                                        sx={{
                                                            width: "30%", mt: 2,// add padding to .MuiSeedInput-root"& .MuiOutlinedInput-root": {    padding: "3px 25px 3px 3px",    fontSize: "15px",},
                                                        }}
                                                        value={groupModalMode === "create" ? newGroup.program || "" : selectedGroup.program || ""}
                                                        select
                                                        onChange={(e) => {
                                                            if (groupModalMode === "create") {
                                                                setNewGroup({ ...newGroup, program: e.target.value, });
                                                                const program = allPrograms.find((program) => program.name === e.target.value);
                                                                setSelectedProgram(program);
                                                                setGroupError({ programError: "" });
                                                            } else {
                                                                setSelectedGroup({ ...selectedGroup, program: e.target.value, });
                                                                const program = allPrograms.find((program) => program.name === e.target.value);
                                                                setSelectedProgram(program);
                                                                setGroupError({ ...groupError, programError: "" });
                                                            }
                                                        }}
                                                        error={Boolean(groupError.programError)}
                                                    >
                                                        {allProgramsDropdown}
                                                    </TextField>

                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            format="DD-MM-YYYY"
                                                            variant="outlined"
                                                            sx={{
                                                                width: "30%",
                                                                mt: 2,
                                                                // add padding to .MuiSeedInput-root
                                                                "& .MuiOutlinedInput-root": {
                                                                    padding: "3px 25px 3px 3px",
                                                                    fontSize: "15px",
                                                                },
                                                            }}
                                                            label="Start Date"
                                                            value={
                                                                groupModalMode === "create"
                                                                    ? dayjs(newGroup.startDate, "DD-MM-YYYY") || null
                                                                    : dayjs(selectedGroup.startDate, "DD-MM-YYYY") ||
                                                                    null
                                                            }
                                                            onChange={(newDate) => {
                                                                const formattedDate =
                                                                    dayjs(newDate).format("DD-MM-YYYY");
                                                                if (groupModalMode === "create") {
                                                                    setNewGroup({
                                                                        ...newGroup,
                                                                        startDate: formattedDate,
                                                                    });
                                                                } else {
                                                                    setSelectedGroup({
                                                                        ...selectedGroup,
                                                                        startDate: formattedDate,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            format="DD-MM-YYYY"
                                                            label="End Date"
                                                            variant="outlined"
                                                            sx={{
                                                                width: "30%",
                                                                mt: 2,
                                                                // add padding to .MuiSeedInput-root
                                                                "& .MuiOutlinedInput-root": {
                                                                    padding: "3px 25px 3px 3px",
                                                                    fontSize: "15px",
                                                                },
                                                            }}
                                                            value={
                                                                groupModalMode === "create"
                                                                    ? dayjs(newGroup.endDate, "DD-MM-YYYY") || null
                                                                    : dayjs(selectedGroup.endDate, "DD-MM-YYYY") ||
                                                                    null
                                                            }
                                                            onChange={(newDate) => {
                                                                const formattedDate =
                                                                    dayjs(newDate).format("DD-MM-YYYY");
                                                                if (groupModalMode === "create") {
                                                                    setNewGroup({
                                                                        ...newGroup,
                                                                        endDate: formattedDate,
                                                                    });
                                                                } else {
                                                                    setSelectedGroup({
                                                                        ...selectedGroup,
                                                                        endDate: formattedDate,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                                <div className="admin-form-row">
                                                    <TextField
                                                        label="MDT Contact Name"
                                                        variant="outlined"
                                                        sx={{
                                                            width: "30%", mt: 2,// add padding to .MuiSeedInput-root"& .MuiOutlinedInput-root": {    padding: "3px 25px 3px 3px",    fontSize: "15px",},
                                                        }}
                                                        value={groupModalMode === "create" ? newGroup.contactName || "" : selectedGroup.contactName || ""}
                                                        onChange={(e) => {
                                                            const error = validateName(e.target.value);
                                                            if (groupModalMode === "create") {
                                                                setNewGroup({ ...newGroup, contactName: e.target.value, });
                                                                setGroupError({ contactNameError: error });
                                                            } else {
                                                                setSelectedGroup({ ...selectedGroup, contactName: e.target.value, });
                                                                setGroupError({ ...groupError, contactNameError: error, });
                                                            }
                                                        }}
                                                        error={Boolean(groupError.contactNameError)}
                                                        helperText={groupError.contactNameError}
                                                    />
                                                    <TextField
                                                        label="MDT Contact Number"
                                                        variant="outlined"
                                                        sx={{
                                                            width: "30%", mt: 2,// add padding to .MuiSeedInput-root"& .MuiOutlinedInput-root": {    padding: "3px 25px 3px 3px",    fontSize: "15px",},
                                                        }}
                                                        type="number"
                                                        value={
                                                            groupModalMode === "create"
                                                                ? newGroup.contactNum || ""
                                                                : selectedGroup.contactNum || ""
                                                        }
                                                        onChange={(e) => {
                                                            const error = validatePhone(e.target.value);
                                                            if (groupModalMode === "create") {
                                                                setNewGroup({ ...newGroup, contactNum: e.target.value, });
                                                                setGroupError({ contactNumError: error });
                                                            } else {
                                                                setSelectedGroup({ ...selectedGroup, contactNum: e.target.value, });
                                                                setGroupError({ ...groupError, contactNumError: error });
                                                            }
                                                        }}
                                                        error={Boolean(groupError.contactNumError)}
                                                        helperText={groupError.contactNumError}
                                                    />
                                                    <TextField
                                                        label="School Contact Name"
                                                        variant="outlined"
                                                        sx={{
                                                            width: "30%",
                                                            mt: 2,
                                                            // add padding to .MuiSeedInput-root
                                                            "& .MuiOutlinedInput-root": {
                                                                padding: "3px 25px 3px 3px",
                                                                fontSize: "15px",
                                                            },
                                                        }}
                                                        value={groupModalMode === "create" ? newGroup.schoolContactName || "" : selectedGroup.schoolContactName || ""
                                                        }
                                                        onChange={(e) => {
                                                            const error = validateName(e.target.value);
                                                            if (groupModalMode === "create") {
                                                                setNewGroup({ ...newGroup, schoolContactName: e.target.value, });
                                                                setGroupError({ schoolContactNameError: error });
                                                            } else {
                                                                setSelectedGroup({ ...selectedGroup, schoolContactName: e.target.value, });
                                                                setGroupError({ ...groupError, schoolContactNameError: error, });
                                                            }
                                                        }}
                                                        error={Boolean(groupError.schoolContactNameError)}
                                                        helperText={groupError.schoolContactNameError}
                                                    />
                                                    <TextField
                                                        label="School Contact Number Or Email"
                                                        variant="outlined"
                                                        sx={{
                                                            width: "30%",
                                                            mt: 2,
                                                            // add padding to .MuiSeedInput-root
                                                            "& .MuiOutlinedInput-root": {
                                                                padding: "3px 25px 3px 3px",
                                                                fontSize: "15px",
                                                            },
                                                        }}
                                                        // type for both email and number:
                                                        type="text"
                                                        value={
                                                            groupModalMode === "create"
                                                                ? newGroup.schoolContactNum || ""
                                                                : selectedGroup.schoolContactNum || ""
                                                        }
                                                        onChange={(e) => {
                                                            let error = "";
                                                            if (e.target.value.includes('@')) {
                                                                error = validateEmail(e.target.value);
                                                            } else {
                                                                error = validatePhone(e.target.value);
                                                            }
                                                            if (groupModalMode === "create") {
                                                                setNewGroup({
                                                                    ...newGroup,
                                                                    schoolContactNum: e.target.value,
                                                                });
                                                                setGroupError({
                                                                    ...groupError,
                                                                    schoolContactNumError: error,
                                                                });
                                                            } else {
                                                                setSelectedGroup({
                                                                    ...selectedGroup,
                                                                    schoolContactNum: e.target.value,
                                                                });
                                                                setGroupError({
                                                                    ...groupError,
                                                                    schoolContactNumError: error,
                                                                });
                                                            }
                                                        }}
                                                        error={Boolean(groupError.schoolContactNumError)}
                                                        helperText={groupError.schoolContactNumError}
                                                    />
                                                </div>

                                                <div className="admin-form-row">
                                                    <div className="scheduleTable-container">
                                                        <table className="scheduleTable">
                                                            <thead>
                                                                <tr>
                                                                    <th >Session</th>
                                                                    <th >Topic</th>
                                                                    <th style={{ width: "155px" }}>Date</th>
                                                                    <th style={{ width: "120px" }}>Start Time</th>
                                                                    <th style={{ width: "120px" }}>End Time</th>
                                                                    <th >Location</th>
                                                                    <th>Notes</th>
                                                                    <th style={{ width: "160px" }}>
                                                                        Questionnaire / Canva link
                                                                    </th>
                                                                    <th style={{ width: "120px" }}>Videos</th>
                                                                    <th style={{ width: '60px' }}>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {sessions && sessions.map((session, index) => (
                                                                    <tr key={index}>
                                                                        <td >{index + 1 || ""}</td>
                                                                        <td
                                                                            contentEditable
                                                                            suppressContentEditableWarning
                                                                            onBlur={(e) => {
                                                                                const newValue = e.target.innerText;
                                                                                const updatedSessions = [...sessions];
                                                                                updatedSessions[index].topic = newValue;
                                                                                setSessions(updatedSessions);
                                                                            }}
                                                                        >
                                                                            {session?.topic || ""}
                                                                        </td>
                                                                        <td>
                                                                            <LocalizationProvider
                                                                                dateAdapter={AdapterDayjs}
                                                                            >
                                                                                <DatePicker
                                                                                    format="DD-MM-YYYY"
                                                                                    variant="outlined"
                                                                                    sx={{
                                                                                        width: "100%",
                                                                                        "& .MuiOutlinedInput-root": {
                                                                                            fontSize: "14px",
                                                                                        },
                                                                                    }}
                                                                                    value={
                                                                                        session.date
                                                                                            ? dayjs(session.date, "DD-MM-YYYY")
                                                                                            : ""
                                                                                    }
                                                                                    onChange={(newDate) => {
                                                                                        const formattedDate =
                                                                                            dayjs(newDate).format("DD-MM-YYYY");
                                                                                        const updatedSessions = [...sessions];
                                                                                        updatedSessions[index].date =
                                                                                            formattedDate;
                                                                                        setSessions(updatedSessions);
                                                                                    }}
                                                                                />
                                                                            </LocalizationProvider>
                                                                        </td>
                                                                        <td>
                                                                            <LocalizationProvider
                                                                                dateAdapter={AdapterDayjs}
                                                                            >
                                                                                <TimePicker
                                                                                    ampm={false} // 24hr format
                                                                                    openTo="hours"
                                                                                    views={["hours", "minutes"]}
                                                                                    format="HH:mm"
                                                                                    sx={{
                                                                                        width: "100%",
                                                                                        "& .MuiOutlinedInput-root": {
                                                                                            fontSize: "14px",
                                                                                        },
                                                                                    }}
                                                                                    value={
                                                                                        session.time
                                                                                            ? dayjs(session.time, "HH:mm")
                                                                                            : dayjs("00:00", "HH:mm")
                                                                                    }
                                                                                    onChange={(newTime) => {
                                                                                        const formattedTime =
                                                                                            dayjs(newTime).format("HH:mm");
                                                                                        const updatedSessions = [...sessions];
                                                                                        updatedSessions[index].time =
                                                                                            formattedTime;
                                                                                        setSessions(updatedSessions);
                                                                                    }}
                                                                                    renderInput={(params) => (
                                                                                        <TextField {...params} />
                                                                                    )}
                                                                                />
                                                                            </LocalizationProvider>
                                                                        </td>
                                                                        <td>
                                                                            <LocalizationProvider
                                                                                dateAdapter={AdapterDayjs}
                                                                            >
                                                                                <TimePicker
                                                                                    ampm={false} // 24hr format
                                                                                    openTo="hours"
                                                                                    views={["hours", "minutes"]}
                                                                                    format="HH:mm"
                                                                                    sx={{
                                                                                        width: "100%",
                                                                                        "& .MuiOutlinedInput-root": {
                                                                                            fontSize: "14px",
                                                                                        },
                                                                                    }}
                                                                                    value={
                                                                                        session.endTime
                                                                                            ? dayjs(session.endTime, "HH:mm")
                                                                                            : dayjs("00:00", "HH:mm")
                                                                                    }
                                                                                    onChange={(newTime) => {
                                                                                        const formattedTime =
                                                                                            dayjs(newTime).format("HH:mm");
                                                                                        const updatedSessions = [...sessions];
                                                                                        updatedSessions[index].endTime =
                                                                                            formattedTime;
                                                                                        setSessions(updatedSessions);
                                                                                    }}
                                                                                    renderInput={(params) => (
                                                                                        <TextField {...params} />
                                                                                    )}
                                                                                />
                                                                            </LocalizationProvider>
                                                                        </td>
                                                                        <td
                                                                            contentEditable
                                                                            suppressContentEditableWarning
                                                                            onBlur={(e) => {
                                                                                const newValue = e.target.innerText;
                                                                                const updatedSessions = [...sessions];
                                                                                updatedSessions[index].location = newValue;
                                                                                setSessions(updatedSessions);
                                                                            }}
                                                                        >
                                                                            {session?.location || ""}
                                                                        </td>
                                                                        <td
                                                                            contentEditable
                                                                            suppressContentEditableWarning
                                                                            onBlur={(e) => {
                                                                                const newValue = e.target.innerText;
                                                                                const updatedSessions = [...sessions];
                                                                                updatedSessions[index].notes = newValue;
                                                                                setSessions(updatedSessions);
                                                                            }}
                                                                        >
                                                                            {session?.notes || ""}
                                                                        </td>
                                                                        <td style={{ width: "220px" }}>
                                                                            <Select
                                                                                sx={{
                                                                                    width: "100%",
                                                                                    "& .MuiSelect-select": {
                                                                                        fontSize: "14px",
                                                                                    },
                                                                                }}
                                                                                value={session.questionnaire || ""}
                                                                                displayEmpty
                                                                                onChange={(e) => {
                                                                                    const { value } = e.target;
                                                                                    const updatedSessions = [...sessions];
                                                                                    updatedSessions[index].questionnaire =
                                                                                        value;
                                                                                    setSessions(updatedSessions);
                                                                                }}
                                                                                renderValue={(selected) => {
                                                                                    if (!selected || selected.length === 0) {
                                                                                        return "Geen vragenlijst";
                                                                                    }
                                                                                    return selected;
                                                                                }}
                                                                            >
                                                                                <MenuItem value=""> Geen vragenlijst</MenuItem>
                                                                                {selectedProgram &&
                                                                                    selectedProgram.questionnaires &&
                                                                                    selectedProgram.questionnaires?.map(
                                                                                        (questionnaire, index) => (
                                                                                            <MenuItem
                                                                                                key={index}
                                                                                                value={questionnaire.title}
                                                                                            >
                                                                                                {questionnaire.title}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    )}
                                                                            </Select>
                                                                        </td>
                                                                        <td style={{ maxWidth: "100px" }}>
                                                                            <Select
                                                                                sx={{
                                                                                    width: "100%",
                                                                                    "& .MuiSelect-select": {
                                                                                        fontSize: "14px",
                                                                                    },
                                                                                }}
                                                                                value={session.videos || []}
                                                                                multiple
                                                                                whitespace="nowrap"
                                                                                displayEmpty
                                                                                onChange={(e) => {
                                                                                    const { value } = e.target;
                                                                                    const updatedSessions = [...sessions];
                                                                                    updatedSessions[index].videos = value;
                                                                                    setSessions(updatedSessions);
                                                                                }}
                                                                                renderValue={(selected) => {
                                                                                    if (selected.length === 0) {
                                                                                        return "Geen video";
                                                                                    }
                                                                                    return selected.join(", ");
                                                                                }}
                                                                            >
                                                                                <MenuItem value="">Geen video</MenuItem>
                                                                                {selectedProgram &&
                                                                                    selectedProgram.videos &&
                                                                                    selectedProgram.videos?.map(
                                                                                        (video, index) => (
                                                                                            <MenuItem
                                                                                                key={index}
                                                                                                value={video.title}
                                                                                            >
                                                                                                {video.title}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    )}
                                                                            </Select>
                                                                        </td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            <button
                                                                                className="transparentBtn"
                                                                                onClick={(e) => deleteRow(e, index)}
                                                                            >
                                                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="admin-form-row">
                                                    <button
                                                        style={{ marginTop: "10px" }}
                                                        onClick={(e) => addRow(e)}
                                                    >
                                                        Add Table Row
                                                    </button>
                                                </div>
                                                <div className=" successMessage">
                                                    {message && message}
                                                </div>
                                                <div style={{ marginTop: "10px" }}>
                                                    {groupModalMode === "create" ? (
                                                        <button type="submit">Create Group</button>
                                                    ) : (
                                                        <button type="submit">Update Group</button>
                                                    )}
                                                    <button
                                                        className="greyButton"
                                                        type="button"
                                                        onClick={handleGroupClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </form>
                                        </Box>
                                    </Modal>
                                </div>

                                <div className="groups-modal-container add-students-modal">
                                    <Modal
                                        open={openAddStudentsModal}
                                        onClose={handleAddStudentsClose}
                                        aria-labelledby="add-students-modal"
                                        aria-describedby="add-students-modal"
                                    >
                                        <Box sx={addStudentsCSVModalStyle}>
                                            <div>
                                                <h5>Add students to group "{selectedGroup?.name || ""}"</h5>
                                                <p>Please upload a .csv file with the following columns:</p>
                                                <p><strong>firstName, lastName</strong></p>
                                            </div>
                                            <form onSubmit={addStudentsToGroup}>
                                                <div
                                                    className="admin-form-row"
                                                    style={{ margin: "20px" }}
                                                >
                                                    <input
                                                        type="file"
                                                        id="fileUpload"
                                                        name="filename"
                                                        accept=".csv"
                                                        onChange={handleStudentsCSVSelection}
                                                        style={{ display: "none" }}
                                                    />
                                                    <label htmlFor="fileUpload" className="file-upload-label">
                                                        <CloudUploadIcon style={{ margin: "20px" }} />
                                                        Select .csv file from your computer
                                                    </label>
                                                </div>
                                                <div className=" successMessage">
                                                    {message && <p>{message}</p>}
                                                </div>
                                                <div style={{ marginTop: "10px" }}>
                                                    <button
                                                        disabled={!studentsCSV || studentsCSV.length === 0}
                                                        type="submit"
                                                    >
                                                        Add Students
                                                    </button>
                                                    <button
                                                        className="greyButton"
                                                        type="button"
                                                        onClick={handleAddStudentsClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </form>
                                        </Box>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    )}
                    {pageActiveTab === 4 && (
                        // add analytics for the admin page about users, groups, programs, quizzes:
                        <div className="admin-container analytics" id="analyticsSection">
                            {/* <div className="element-title-yellow">
                                <h6>Analytics</h6>
                            </div> */}
                            <div className="admin-content element-body analytics">

                                <div className="admin-table-container analytics" >
                                    <div >
                                        <h6>Programs Overview</h6>
                                    </div>
                                    <table className="admin-table">
                                        <thead>
                                            <tr>
                                                <th>Program</th>
                                                <th>Students</th>
                                                <th>Admins</th>
                                                <th>Teachers</th>
                                                <th>Groups</th>
                                                <th>Quizzes</th>
                                                <th>Videos</th>
                                                <th>Questionnaires</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allPrograms && allGroups && users &&
                                                allPrograms.map((program) => {
                                                    // Filter users based on the program name
                                                    const studentsInProgram = users.filter(user => user.program.includes(program.name) && user.role === "user");
                                                    const adminsInProgram = users.filter(user => user.program.includes(program.name) && user.role === "admin");
                                                    const teachersInProgram = users.filter(user => user.program.includes(program.name) && user.role === "teacher");
                                                    // Filter groups based on the program name
                                                    const groupsInProgram = allGroups.filter(group => group.program === program.name);

                                                    return (
                                                        <tr key={program.uid}>
                                                            <td>{program.name}</td>
                                                            <td>{studentsInProgram.length}</td>
                                                            <td>{adminsInProgram.length}</td>
                                                            <td>{teachersInProgram.length}</td>
                                                            <td>{groupsInProgram.length}</td>
                                                            <td>{program.quizzes?.length || 0}</td>
                                                            <td>{program.videos?.length || 0}</td>
                                                            <td>{program.questionnaires?.length || 0}</td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="charts-row">
                                    <div className="chart-container">
                                        <div >
                                            <h6>Videos watched per group</h6>
                                        </div>
                                        <Bar data={videoWatchChartData}
                                            options={{
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        position: 'bottom',
                                                    },
                                                    title: {
                                                        display: false,
                                                        text: 'Videos watched per group',
                                                    },
                                                },
                                            }} />
                                    </div>
                                </div>

                                <div className="charts-row">
                                    <div className="chart-container">
                                        <div >
                                            <h6>Questionnaires: how many students completed</h6>
                                        </div>
                                        <Bar data={questionnairesCompletionDataForChart}
                                            options={{
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        position: 'bottom',
                                                    },
                                                    title: {
                                                        display: false,
                                                        text: 'Questionnaires: how many students completed',
                                                    },
                                                },
                                            }} />
                                    </div>
                                </div>

                                <div className="charts-row">
                                    <div className="chart-container">
                                        <div >
                                            <h6> Quizzes: how many students completed</h6>
                                        </div>
                                        <Bar data={quizCompletionChartData}
                                            options={{
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        position: 'bottom',
                                                    },
                                                    title: {
                                                        display: false,
                                                    },
                                                },
                                            }} />
                                    </div>
                                </div>




                                <div className="charts-row">
                                    <div className="chart-container">
                                        <div >
                                            <h6>Student Distribution by group</h6>
                                        </div>
                                        <Bar
                                            data={studentDistributionChartData}
                                            options={{
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        position: 'bottom',
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Number of Students in Each Group',
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="charts-row">
                                    <div className="chart-container">
                                        <div >
                                            <h6>Attendance of students per group</h6>
                                        </div>
                                        <Bar data={attendanceChartData}
                                            options={{
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        position: 'bottom',
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Attendance of students per group',
                                                    },
                                                    scales: {
                                                        y: {
                                                            beginAtZero: true,

                                                        },
                                                    },
                                                },
                                            }} />


                                    </div>
                                </div>

                                <div className="charts-row">
                                    <div className="chart-container">
                                        <div>
                                            <h6>Groups opened per month</h6>
                                        </div>
                                        <Bar data={groupOpenedChartData}
                                            options={{
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        position: 'bottom',
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Groups opened per month',
                                                    },
                                                    scales: {
                                                        y: {
                                                            beginAtZero: true,

                                                        },
                                                    },
                                                },
                                            }} />
                                    </div>
                                </div>

                            </div>
                        </div>

                    )}
                </Box>

            </div >

        </div >
    );
}

export default withAuthentication(Admin, true);

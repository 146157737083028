import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, query, setDoc, where, doc, getDoc } from "firebase/firestore";
import { Context } from '../../Context';
import Loading from "../../components/Loading";
import Spinner from "../../components/Spinner";
import withAuthentication from "../../withAuthentication";
import { Tooltip } from 'react-tooltip'
import { MenuItem, Select } from "@mui/material"
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import ckBGDesktop from '../../images/ckBGDesktop.png';
import ckBGTablet from '../../images/ckBGTablet.png';
import ckBGMobile from '../../images/ckBGMobile.png';
import useScreenSize from '../../components/useScreenSize';
import ExcelJS from 'exceljs';

function Attendance() {
    const { userState, loading } = useContext(Context);
    const { group, tenantId, role, tenantName, program } = userState;

    const [selectedProgram, setSelectedProgram] = useState('')
    const [programsDropdown, setProgramsDropdown] = useState([])
    const [selectedSessionDate, setSelectedSessionDate] = useState('');
    const userGroups = Array.isArray(userState.group) ? userState.group : [userState.group];
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('')
    const [students, setStudents] = useState([]);
    const [attendance, setAttendance] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [groupsDropdown, setGroupsDropdown] = useState('')
    const [sessionsDropdown, setSessionsDropdown] = useState('')
    const [userChangedAttendance, setUserChangedAttendance] = useState(false)
    const attendanceOptions = ['aanwezig', 'afwezig', 'zelfstandig']

    const [showSpinner, setShowSpinner] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    // check if tenant is C&K 
    const isCK = tenantName === "CULTUUR & KUNST" || (program && program[0] === 'MDT Cultuur & Kunst')
    const screenSize = useScreenSize(); // 'mobile', 'tablet', or 'desktop'

    const getBackgroundImage = () => {
        switch (screenSize) {
            case 'mobile':
                return ckBGMobile;
            case 'tablet':
                return ckBGTablet;
            default:
                return ckBGDesktop;
        }
    };

    const ckBackgroundStyle = {
        backgroundImage: `url(${getBackgroundImage()})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }

    // fetch program data for admins that have more than 1 program:
    useEffect(() => {
        if (loading || !program || program.length === 0) return
        // check if program is an array or a string:
        const programIsArray = Array.isArray(program)
        if (!programIsArray) {
            setSelectedProgram(program)
        } else {
            setSelectedProgram(program[0])
            // create a dropdown menu with all the programs:
            const programsDropdown = program.map((program, index) => {
                return <MenuItem key={index} value={program}>
                    {program}
                </MenuItem>
            })
            setProgramsDropdown(programsDropdown)
        }
    }, [program, loading]);

    // fetch groups for the selected program:
    useEffect(() => {
        if (loading || !selectedProgram) return;

        const fetchGroupsForProgram = async () => {
            try {
                // Fetch groups where 'program' field equals selectedProgram
                const groupsRef = collection(db, 'tenants', tenantId, 'groups');
                const q = query(groupsRef, where('program', '==', selectedProgram));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    console.log('No matching groups.');
                    setGroups([]);
                    setGroupsDropdown([]);
                    setSelectedGroup('');
                    return;
                }

                // Map fetched groups to an array of group IDs
                const groupsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                const allGroupsInProgram = groupsData.map(group => group.name);
                // Get the user's groups from context and ensure it's an array
                const userGroups = Array.isArray(userState.group) ? userState.group : [userState.group];
                // Filter the groups to only include those the user has access to
                const groupsList = allGroupsInProgram.filter(groupName => userGroups.includes(groupName));

                if (groupsList.length === 0) {
                    setGroups([]);
                    setGroupsDropdown([]);
                    setSelectedGroup('');
                    return;
                }
                setGroups(groupsList);
                setSelectedGroup(groupsList[0]);

                // Create the groups dropdown
                const groupsDropdownItems = groupsList.map((groupName, index) => (
                    <MenuItem key={index} value={groupName}>
                        {groupName}
                    </MenuItem>
                ));

                setGroupsDropdown(groupsDropdownItems);

            } catch (error) {
                console.log('Error fetching groups:', error);
                setMessage('Er is iets misgegaan, probeer het opnieuw');
            }
        };

        fetchGroupsForProgram();
    }, [selectedProgram, loading, userState.group]);


    // fetch students in the group: 
    useEffect(() => {
        if (loading || !selectedGroup) return
        const fetchStudents = async () => {
            try {
                const groupQuery = query(collection(db, 'tenants', tenantId, 'users'),
                    where("group", "array-contains", selectedGroup));
                const querySnapshot = await getDocs(groupQuery);
                if (querySnapshot.empty) {
                    return;
                }
                // only return students in the same group:
                const students = querySnapshot.docs.map((doc) => doc.data());
                const studentsInGroupFiltered = students.filter(student => student.role === 'user')

                setStudents(studentsInGroupFiltered)
            } catch (error) {
                setMessage('Er is iets misgegaan, probeer het opnieuw', error)
                console.log('error', error)
            }
        }
        fetchStudents()
    }, [loading, tenantId, selectedGroup]);

    // fetch all groupData + sessions for the group:
    useEffect(() => {
        if (loading || !selectedGroup) return
        const fetchSessions = async () => {
            try {
                if (role === 'teacher' || role === 'admin') {
                    const groupQuery = query(collection(db, 'tenants', tenantId, 'groups'),
                        where("name", "==", selectedGroup));

                    const groupSnapshot = await getDocs(groupQuery);
                    if (groupSnapshot.empty) {
                        console.log('No matching documents.');
                        return;
                    }
                    const groupData = groupSnapshot.docs.map((doc) => doc.data())[0]
                    const schedule = groupData.schedule
                    if (!schedule) return
                    setSessions(schedule)
                }
            } catch (error) {
                setMessage('Er is iets misgegaan, probeer het opnieuw', error)
                console.log('error', error)
            }
        }
        fetchSessions()
    }, [loading, tenantId, selectedGroup, role]);

    // set all sessions in a dropdown menu:
    useEffect(() => {
        if (loading || !sessions || sessions.length === 0 || !selectedGroup) {
            setSessionsDropdown([])
            return
        }

        const sessionsDropdown = sessions.map((session, index) => {
            return <MenuItem key={index} value={session.date}>
                Session #{index + 1} - {session.date}
            </MenuItem>
        })
        setSessionsDropdown(sessionsDropdown)
    }, [sessions, loading, selectedGroup]);

    // loop through students and create attendance object for each student, with empty attendance:
    const createAttendanceArray = (existingAttendance = []) => {
        const existingStudentNames = existingAttendance.map((student) => student.studentName);
        // Create new attendance records only for students who don't already have one
        const newStudentsAttendance = students
            .filter((student) => !existingStudentNames.includes(student.displayName))
            .map((student) => ({
                studentName: student.displayName,
                attendanceStatus: ''
            }));

        // Merge existing attendance with new students
        return [...existingAttendance, ...newStudentsAttendance];
    }

    const handleProgramChange = async (program) => {
        setSelectedProgram(program)
        setSelectedProgram(program);
        setSelectedGroup('');
        setGroups([]);
        setGroupsDropdown([]);
        setSelectedSessionDate('');
        setAttendance([]);
        setSessions([]);
        setSessionsDropdown([]);
        setStudents([]);
    }


    // when user selects session date, check if attendance exists for the session, create new attendance array / update attendance array:
    const handleSessionChange = async (e) => {
        const sessionDate = e.target.value
        setSelectedSessionDate(sessionDate)

        if (!sessions || sessions.length === 0) return

        // loop over group's sessions array and find the session that matches the selected session:
        const currSession = sessions.find((session) => session.date === sessionDate)
        if (!currSession) return

        const attendanceExists = currSession.attendance && currSession.attendance.length > 0
        //  if attendance exists for session, update the attendance object:
        let attendanceArray = []

        if (attendanceExists) {
            // If attendance exists, merge it with any new students
            attendanceArray = createAttendanceArray(currSession.attendance);
        } else {
            attendanceArray = createAttendanceArray()
        }
        setAttendance(attendanceArray)
    }

    const handleGroupChange = async (e) => {
        const group = e.target.value
        setSelectedGroup(group)
        setSelectedSessionDate('')
        setAttendance([])
        setSessions([])
    }

    function getSelectClassName(attendanceStatus) {
        if (attendanceStatus === '') {
            return 'attendance-select-empty'
        } else {
            if (attendanceStatus === 'aanwezig') return 'attendance-select-present'
            if (attendanceStatus === 'afwezig') return 'attendance-select-absent'
            if (attendanceStatus === 'zelfstandig') return 'attendance-select-excused'
        }
    }

    // Add this state near your other states
    const [sortAttendanceConfig, setSortAttendanceConfig] = useState({
        key: 'studentName',
        direction: 'ascending'
    });

    // Update the sort function to handle both studentName and attendanceStatus
    const sortAttendance = (attendanceData, sortConfig) => {
        if (!attendanceData || !sortConfig.key) return attendanceData;

        return [...attendanceData].sort((a, b) => {
            // Handle missing attendance status (empty strings should be last)
            if (sortConfig.key === 'attendanceStatus') {
                const statusA = a[sortConfig.key] || '';
                const statusB = b[sortConfig.key] || '';

                if (statusA === '' && statusB !== '') return 1;
                if (statusA !== '' && statusB === '') return -1;

                if (statusA < statusB) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (statusA > statusB) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
            } else {
                // Original name sorting logic
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
            }
            return 0;
        });
    };

    // Add this handler function
    const requestAttendanceSort = (key) => {
        let direction = 'ascending';
        if (sortAttendanceConfig.key === key && sortAttendanceConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortAttendanceConfig({ key, direction });
    };

    // handle student attendance:
    const handleStudentAttendance = async (e, studentName) => {
        const attendanceValue = e.target.value

        const updatedAttendance = attendance.map((student) => {
            if (student.studentName === studentName) {
                return {
                    ...student,
                    attendanceStatus: attendanceValue
                }
            }
            return student
        }
        )
        setAttendance(updatedAttendance)
        setUserChangedAttendance(true)

    }

    const markAllPresent = () => {
        const updatedAttendance = attendance.map((student) => ({
            ...student,
            attendanceStatus: 'aanwezig'
        }));
        setAttendance(updatedAttendance);
        setUserChangedAttendance(true);
    }

    const saveAttendance = async () => {
        // check if all students have attendance:
        const attendanceFiltered = attendance.filter(student => student.attendanceStatus === '')
        if (attendanceFiltered.length > 0) {
            alert('Please fill in attendance for all students before saving')
            return
        }

        setShowSpinner(true);
        try {
            const groupRef = collection(db, "tenants", tenantId, "groups");
            const groupQuery = query(groupRef, where("name", "==", selectedGroup));
            const groupSnap = await getDocs(groupQuery);
            if (groupSnap.empty) {
                console.log('No matching documents.');
                return;
            }
            const groupData = groupSnap.docs.map((doc) => doc.data())[0]
            const schedule = groupData.schedule
            if (!schedule) return

            // Update the attendance in the current session
            const updatedSessions = schedule.map(session => {
                if (session.date === selectedSessionDate) {
                    return { ...session, attendance: attendance };
                }
                return session;
            });

            // Update local state first
            setSessions(updatedSessions);

            // Then save to database
            const updatedGroupRef = doc(db, "tenants", tenantId, "groups", groupSnap.docs[0].id);
            await setDoc(updatedGroupRef, { ...groupData, schedule: updatedSessions });

            setUserChangedAttendance(false)
            setShowSpinner(false);
            setMessage('Aanwezigheid opgeslagen! ✅')

        } catch (error) {
            console.log('error', error)
            alert('Er is iets misgegaan, probeer het opnieuw', error)
        } finally {
            setTimeout(() => {
                setMessage('')
            }, 4000);
        }
    }

    const handlePDF = (type) => {
        // Create a new instance of jsPDF
        var pdf = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true,
            floatPrecision: 16, // or "smart", default is 16
            compress: true, // Compress the output PDF
            precision: 2, // rounding precision if precision > 0, default is 4
            lineHeight: 1.1, // Line height multiplier for paragraphs, default is 1.15
        });

        // content and formatting of the PDF document
        const title = `Attendance for MDT Group: ${group}`
        const date = `Session Date: ${selectedSessionDate}`

        // table:
        const tableRows = attendance.map((student) => {
            const studentAttendance = student.attendanceStatus
            return [student.studentName, studentAttendance]
        })

        // table styling:
        const tableStyle = {
            fillColor: '#f5f5f5',
            textColor: '#333333',
            fontStyle: 'normal',
            halign: 'center',
            valign: 'middle',
            fontSize: 10,
            cellPadding: 1.5,
            lineWidth: 0.1,
            lineColor: '#333333',
        }

        // create the PDF document:
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'bold');
        pdf.text(title, 105, 20, { align: 'center' });
        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'normal');
        pdf.text(date, 105, 30, { align: 'center' });

        autoTable(pdf, {
            head: [['Student Name', 'Attendance Status']], // table headers
            body: tableRows, // table rows
            startY: 40,
            margin: 30,
            styles: tableStyle,
            theme: 'grid',
            headStyles: {
                fillColor: '#f5f5f5',
                textColor: '#333333',
                fontStyle: 'bold',
                halign: 'center',
                valign: 'middle',
                fontSize: 10,
                cellPadding: 2,
                lineWidth: 0.1,
                lineColor: '#222222',
            },
            columnStyles: {
                0: { halign: 'center', fillColor: '#FFFFFF', },
                1: { halign: 'center', fillColor: '#FFFFFF', },
            },

        })

        if (type === 'download') {
            pdf.save(`Attendance for MDT Group ${group}-session-${selectedSessionDate}.pdf`);
            return
        } else {
            pdf.autoPrint();
            window.open(pdf.output('bloburl'), '_blank');
            return
        }

    }

    const handleExcel = async () => {
        try {
            // Create workbook and worksheet
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Aanwezigheid');

            // Add title and date in Dutch
            worksheet.addRow([`Aanwezigheid voor MDT Groep: ${selectedGroup}`]);
            worksheet.addRow([`Sessiedatum: ${selectedSessionDate}`]);
            worksheet.addRow([]); // Empty row for spacing

            // Add headers in Dutch
            worksheet.addRow(['#', 'Naam student', 'Aanwezigheidsstatus']);

            // Add student data
            attendance.forEach((student, index) => {
                const row = worksheet.addRow([
                    index + 1,
                    student.studentName,
                    student.attendanceStatus || ''
                ]);

                // Color the attendance status cell
                const statusCell = row.getCell(3);
                switch (student.attendanceStatus) {
                    case 'aanwezig':
                        statusCell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FF92D050' } // Green
                        };
                        break;
                    case 'afwezig':
                        statusCell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFF0000' } // Red
                        };
                        break;
                    case 'zelfstandig':
                        statusCell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFC000' } // Orange
                        };
                        break;
                }
            });

            // Style the headers
            worksheet.getRow(1).font = { bold: true, size: 14 };
            worksheet.getRow(4).font = { bold: true };

            // Auto-size columns
            worksheet.columns.forEach(column => {
                column.width = 20;
            });

            // Generate and download file with Dutch filename
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Aanwezigheid-${selectedGroup}-sessie-${selectedSessionDate}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.log("error", error);
            alert("Er is een fout opgetreden bij het genereren van het Excel-bestand. Probeer het opnieuw.");
        }
    };

    if (loading) return <Loading />

    return (
        <div className={`page-container ${isCK ? 'bg-overlay' : ''}`}
            style={isCK ? ckBackgroundStyle : {}}>
            <div className='page-title'>
                <h3 style={{ color: isCK ? 'white' : 'var(--global-black)' }}>Aanwezigheid</h3>
                <h5 style={{ color: isCK ? 'white' : '' }}>Vul de aanwezigheid van uw studenten in voor elke sessie van het traject
                    <i
                        data-tooltip-id="attendance"
                        data-tooltip-content="Selecteer voor elke student de aanwezigheidsstatus voor de sessie. Per sessie kunt u de aanwezigheidslijst downloaden of afdrukken."
                        aria-hidden="true"
                        style={{ marginLeft: '10px', color: isCK ? 'white' : 'grey' }}
                        className="fas fa-info-circle fa-lg">
                    </i>
                </h5>
                <Tooltip
                    className="tooltip"
                    id="attendance"
                    place="bottom"
                />
            </div>
            <div className='page-content attendance-page-container' >
                {showSpinner && <Spinner />}
                <div className="attendance-header">
                    {role && (role === 'admin' || role === 'teacher') && program && program.length > 1 &&
                        <div>
                            <h6 style={{ color: isCK ? 'white' : 'var(--global-black)' }}>Selecteer het programma</h6>
                            <Select
                                disabled={!Array.isArray(program) || program.length < 2}
                                value={selectedProgram ? selectedProgram : ''}
                                label="Selected Program"
                                style={{ width: '200px', marginTop: '5px', backgroundColor: isCK ? 'white' : '' }}
                                displayEmpty
                                renderValue={(value) => {
                                    if (value === '') {
                                        return <p>Selecteer programma</p>;
                                    }
                                    return value;
                                }}
                                onChange={(e) => handleProgramChange(e.target.value)}
                            >
                                {programsDropdown !== '' && programsDropdown}
                            </Select>
                        </div>


                    }
                    {role && (role === 'admin' || role === 'teacher') && group && group.length > 0 &&
                        <div>
                            <h6 style={{ color: isCK ? 'white' : 'var(--global-black)' }}>Selecteer de group
                                <i
                                    data-tooltip-id="attendance-group"
                                    data-tooltip-content="Als je meer dan 1 groep studenten hebt, selecteer dan welke je wilt gebruiken"
                                    aria-hidden="true"
                                    style={{ marginLeft: '10px', color: isCK ? 'white' : 'grey' }}
                                    className="fas fa-info-circle fa-lg">
                                </i>

                            </h6>
                            <Tooltip
                                className="tooltip"
                                id="attendance-group"
                                place="bottom"
                            />
                            <Select
                                disabled={!Array.isArray(group) || group.length < 2}
                                value={selectedGroup ? selectedGroup : ''}
                                label="Selected Group"
                                style={{ width: '240px', marginTop: '5px', backgroundColor: isCK ? 'white' : '' }}
                                displayEmpty
                                renderValue={(value) => {
                                    if (value === '') {
                                        return <p>Selecteer group</p>;
                                    }
                                    return value;
                                }}
                                onChange={(e) => handleGroupChange(e)}
                            >
                                {groupsDropdown !== '' && groupsDropdown}
                            </Select>
                        </div>
                    }

                    <div>
                        <h6 style={{ color: isCK ? 'white' : 'var(--global-black)' }}>Selecteer de sessiedatum</h6>
                        <Select
                            value={selectedSessionDate || ''}
                            disabled={!selectedGroup || !sessions || sessions.length === 0 || sessionsDropdown.length === 0}
                            label="Selected Session"
                            style={{ width: '230px', marginTop: '5px', backgroundColor: isCK ? 'white' : '' }}
                            displayEmpty
                            renderValue={(value) => {
                                if (sessionsDropdown.length === 0) {
                                    return <p>Geen sessies beschikbaar</p>;
                                }
                                if (value === '') {
                                    return <p>Selecteer sessie</p>;
                                }
                                return value;
                            }}
                            onChange={(e) => handleSessionChange(e)}
                        >
                            {sessionsDropdown}
                        </Select>
                    </div>
                </div>
                {/* mark all as present button: */}
                <div className='mark-all-present-button-container'>
                    {attendance && attendance.length > 0 && selectedSessionDate && (
                        <button
                            onClick={markAllPresent}
                            className='yellow-btn'
                            style={{ margin: '20px 0px 10px 0px' }}
                        >
                            Markeer alles als aanwezig
                            <i className="fas fa-check" style={{ marginLeft: '8px' }}></i>
                        </button>
                    )}
                </div>
                <div className='element-body attendance-table-container'>

                    <table className='attendance-table'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th onClick={() => requestAttendanceSort('studentName')} style={{ cursor: 'pointer' }}>
                                    Naam student <span>
                                        {sortAttendanceConfig.key === 'studentName'
                                            ? (sortAttendanceConfig.direction === 'ascending' ? ' ↑' : ' ↓')
                                            : ' ↕'}
                                    </span>
                                </th>
                                <th onClick={() => requestAttendanceSort('attendanceStatus')} style={{ cursor: 'pointer' }}>
                                    Aanwezigheidsstatus <span>
                                        {sortAttendanceConfig.key === 'attendanceStatus'
                                            ? (sortAttendanceConfig.direction === 'ascending' ? ' ↑' : ' ↓')
                                            : ' ↕'}
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {attendance && attendance.length > 0 && selectedSessionDate ?
                                sortAttendance(attendance, sortAttendanceConfig).map((student, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{student.studentName}</td>
                                        <td>
                                            <Select
                                                value={student.attendanceStatus || ''}
                                                onChange={(e) => handleStudentAttendance(e, student.studentName)}
                                                style={{ width: '120px' }}
                                                variant="standard"
                                                className={getSelectClassName(student.attendanceStatus)}
                                                disableUnderline
                                                displayEmpty
                                                renderValue={(value) => {
                                                    if (value === '') {
                                                        return <p>Selecteren</p>;
                                                    }
                                                    return value
                                                }}
                                            >
                                                {attendanceOptions && attendanceOptions.map((option, index) => {
                                                    return <MenuItem key={index} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                })}
                                            </Select>
                                        </td>
                                    </tr>
                                )) :
                                <tr style={{ height: '200px' }}>
                                    <td colSpan="3" rowSpan="0">Selecteer de sessiedatum om te beginnen</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="bottomNavigation" style={{ flexDirection: 'column' }}>
                    {message && <p style={{ color: isCK ? 'white' : '' }}>{message}</p>}
                    <div>
                        <button onClick={saveAttendance} style={{ width: '240px' }}>
                            Opslaan
                            <i className="fas fa-save"></i>
                        </button>
                    </div>
                    <div>
                        <button
                            disabled={!selectedSessionDate || userChangedAttendance}
                            onClick={(e) => handlePDF('download')}
                            style={{ width: '240px' }}>
                            Download als PDF
                            <i className="fas fa-download"></i>
                        </button>
                        <button
                            disabled={!selectedSessionDate || userChangedAttendance}
                            onClick={handleExcel}
                            style={{ width: '240px' }}>
                            Download als Excel
                            <i className="fas fa-file-excel"></i>
                        </button>
                        <button
                            disabled={!selectedSessionDate || userChangedAttendance}
                            onClick={(e) => handlePDF('print')}
                            style={{ width: '240px' }}>
                            Print aanwezigheidslijst
                            <i className="fas fa-print"></i>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default withAuthentication(Attendance, false, true);